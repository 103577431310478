.Form_Wrapper {
  .login_button {
    background: #15be53 !important;
    border-radius: 29px !important;
    box-shadow: none !important;
  }

  .resetPwd {
    a {
      font-weight: 500;
      font-size: 12px;
      line-height: 25px;
      text-decoration-line: underline;
      color: #0395ff;
    }
  }

  .Mui-error {
    fieldset {
      border: 1px solid #d32f2f !important;
    }
  }

  .MuiInputBase-root {
    background: #f6f9fc;
  }

  fieldset {
    border: 1px solid rgba(52, 75, 91, 0.1) !important;
  }

  label {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #4c524a;
  }
}

.login-box {
  background-color: #f6f9fc;
  padding-top: 40px;
}

.textField {
  // background: #f6f9fc;
  width: 50%;
}

.newAccountLink {
  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #4c524a;
  }

  a {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #0395ff !important;
  }
}

@media only screen and (max-width:991.98px) {
  .textField {
    width: 70%;
  }
}

@media only screen and (max-width:567.98px) {
  .textField {
    width: 80%;
  }
}