#settings-menu {
  .setting-menu-item {
    width: 120px;
  }

  .setting-menu-box {
    display: flex;
    justify-content: center;
    align-items: center;

    .setting-menu-text {
      margin-left: 10px;
    }
  }

  .setting-menu-box:hover {
    color: #0395ff;
    stroke: #0395ff;
    stroke-width: 0.55;
  }
}

.documents {
  button {
    flex-direction: row;
    align-items: flex-start !important;
    padding: 8px 12px !important;
    margin: 5px;
    font-weight: 600 !important;
    gap: 10px;
    border-radius: 29px !important;
    color: #ffffff !important;
    border: none !important;
    font-size: 12px !important;
    line-height: 17px !important;
    letter-spacing: 0.05em;
    text-transform: uppercase !important;
  }

  .orderDocs {
    background: #15be53 !important;
  }

  .uploadDocs {
    background: #0395ff !important;
  }
}

.MuiDialogContent-root {
  overflow: hidden;
}

.preview-file {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px !important;
  // width: 80%;

  .preview-url {
    color: blue;
  }

  span {
    cursor: pointer;
  }
}

.preview-url {
  color: blue;
}

.column-date-range {
  input {
    align-items: center;
    font-size: 16px;
    height: 52px;
    width: 225px;
  }
}

#pagination-container {
  display: flex;
  justify-content: center;

  .pagination-first-page,
  .pagination-last-page {
    min-width: 0px;
    width: 40px;
    border: 1px solid transparent;
    background-color: rgba(255, 255, 255, 1) !important;
    color: rgba(21, 190, 83, 1);
  }

  .pagination-first-page:disabled,
  .pagination-last-page:disabled {
    color: rgba(188, 197, 204, 1);
    border: 1px solid transparent;
  }
}

.custom-pagination {
  .Mui-selected {
    border: 1px solid rgba(21, 190, 83, 1);
    background-color: rgba(255, 255, 255, 1) !important;
    color: rgba(21, 190, 83, 1);
  }

  .MuiPaginationItem-previousNext {
    border: 1px solid transparent;
    background-color: rgba(255, 255, 255, 1) !important;
    color: rgba(21, 190, 83, 1);
  }

  .Mui-disabled {
    color: rgba(188, 197, 204, 1);
  }
}

.edit_popup_btn {
  color: #15be53 !important;
  border-color: #15be53 !important;
  width: 27%;
  margin: 0 10px !important;
  padding: 11px 0 !important;
  text-transform: capitalize !important;
}