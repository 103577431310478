.professional-content {
  font-family: IBM Plex Sans;
  font-style: normal;
  color: #0d120b;

  .for-professional-bg-img {
    background-image: url("../assets/for-professionals/for_professional_bg.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 10% 0 5%;
    z-index: 0;
    height: 90vh;
  }

  .service-page {
    text-transform: uppercase;
    color: #0395ff;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
  }

  .main-text {
    font-weight: 500;
    font-size: 40px;
    line-height: 52px;
    margin: 15px 0;
  }

  .professional_paragraph_texts {
    font-weight: 300;
    font-size: 24px;
    line-height: 36px;
  }

  .reviews_wrapper {
    .main-container {
      border: 1px solid #dee3ea;
      border-radius: 10px;
      max-width: 900px;
    }

    .box-container {
      padding: 50px 50px 0px 50px;
    }

    .request-access-box {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 27px;
    }
  }

  .request-access-btn {
    background-color: #15be53;
    border-radius: 29px;
    padding: 15px 40px;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #ffffff;
    // z-index: 999;
    margin-right: 10px;
  }

  .request-access-btn:hover {
    background: #15be53;
    outline: none;
  }

  .explination-section {
    margin: 150px 262px;
  }

  .grid-left-content {
    // max-width: 350px;

    .main-title {
      font-weight: 600;
      font-size: 30px;
      line-height: 39px;
    }

    .sub-content {
      font-weight: 300;
      font-size: 20px;
      line-height: 30px;
    }
  }

  .grid-right-content {
    font-size: 18px;
    line-height: 25px;

    img {
      width: 15.83px !important;
      height: 15.83px !important;
      max-width: 15.83px !important;
    }

    .points-heading {
      font-weight: 600;
    }

    .points-content {
      font-weight: 400;
    }

    .points {
      font-weight: 600;
    }

    .points-brac {
      font-weight: 400;
    }
  }

  .br-5 {
    border-radius: 5px;
  }

  .autocomplete-error {
    border: 1px solid #d32f2f !important;
  }
}

@media only screen and (max-width: 1237px) {
  .professional_paragraph_texts {
    font-size: 23px;
    line-height: 35px;
  }
}

@media only screen and (max-width: 1199.98px) {
  .professional-content {
    .explination-section {
      margin: 150px 50px !important;
    }
    .professional_paragraph_texts {
      font-size: 22px;
      line-height: 34px;
    }
  }
}

@media only screen and (max-width: 900.98px) {
  .professional-content {
    .explination-section {
      margin: 50px 20px !important;
    }
  }
}

@media only screen and (max-width: 768.98px) {
  .professional-content {
    .professional_paragraph_texts {
      font-size: 20px;
      line-height: 32px;
    }
    .reviews_wrapper {
      margin-top: -80px !important;

      .box-container {
        padding: 50px 0px 0px 0px !important;
      }
    }
  }
}

@media only screen and (max-width: 675.98px) {
  .professional-content {
    .service-page {
      font-size: 14px;
      line-height: 18px;
    }

    .main-text {
      font-size: 26px;
      line-height: 30px;
      margin: 10px 0;
    }
  }
}

@media only screen and (max-width: 575.98px) {
  .professional-content {
    .professional_paragraph_texts {
      font-size: 18px;
      line-height: 30px;
    }
    .reviews_wrapper {
      margin-top: -30px !important;
      box-shadow: 10px 10px 30px 12px rgba(52, 75, 91, 0.29);
    }
  }
}

@media only screen and (max-width: 424.98px) {
  .professional-content {
    .service-page {
      line-height: 14px;
    }

    .main-text {
      font-size: 22px;
      line-height: 24px;
      margin: 5px 0;
    }

    .professional_paragraph_texts {
      font-size: 18px;
      line-height: 24px;
    }
  }
}

@media only screen and (max-width: 368px) {
  .professional-content {
    .professional_paragraph_texts {
      font-size: 17px;
      line-height: 22px;
    }
    .request-access-btn {
      padding: 14px 30px !important;
      font-size: 14px !important;
    }
  }
}

@media only screen and (max-width: 325px) {
  .professional-content {
    .request-access-btn {
      padding: 14px 25px !important;
    }
  }
}

@media only screen and (min-height: 1060px) and (max-height: 1368px) {
  .for-professional-bg-img {
    height: 55vh !important;
  }
  .professional_paragraph_texts {
    font-size: 22px !important;
    line-height: 34px !important;
  }
  .service-page {
    margin: 1rem 0 !important;
  }
}
@media only screen and (min-height: 800px) and (max-height: 1060px) {
  .for-professional-bg-img {
    height: 75vh !important;
  }
  .professional_paragraph_texts {
    font-size: 22px !important;
    line-height: 34px !important;
  }
  .service-page {
    margin: 1.4rem 1rem 0 !important;
  }
}
