.property-risk-report {
  font-family: "IBM Plex Sans" !important;
  .main_section {
    background: white;
    padding: 150px 0 100px;
  }
  .admin-main-section {
    background: white;
    padding: 15px 0 30px;
  }
  .personal_info {
    font-size: 14px;
    font-weight: 500;
    line-height: 18.2px;
    text-align: left;
    color: #0395ff;
  }
  .property_add {
    font-size: 18px;
    font-weight: 600;
    line-height: 23.4px;
    text-align: left;
  }
  .proeprty_date {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #4c524a;
  }
  .border-left {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      height: 100%;
      width: 1px;
      background-color: #c9c9c9;
      top: 0;
      z-index: 8;
    }
  }
  .riskometer {
    height: 240px !important;
  }
  .alert_msg_spacing {
    padding: 10px 90px;
    @media (max-width: 991.98px) {
      padding: 10px 50px;
    }
    @media (max-width: 768.98px) {
      padding: 10px 30px;
    }
    @media (max-width: 425.98px) {
      padding: 10px;
    }
  }

  .warning-container {
    margin-top: -80px;
    .low {
      color: #0d120b !important;
      background: rgba(108, 206, 129, 1);
    }
    .medium-low {
      color: #0d120b !important;
      background: rgba(248, 194, 104, 1);
    }
    .medium {
      color: #0d120b !important;
      background: rgba(253, 153, 130, 1);
    }
    .medium-high {
      background: rgba(255, 92, 101, 1);
    }
    .high {
      background: rgba(188, 41, 112, 1);
    }
    .analytics_section {
      color: white;
      font-size: 18px;
      font-weight: 400;
      line-height: 25px;
      text-align: center;
      width: 50%;
      margin: 0 auto;
      @media (max-width: 1150.98px) {
        width: 70%;
      }
      @media (max-width: 830.98px) {
        width: 80%;
      }
      @media (max-width: 768.98px) {
        font-size: 16px;
        line-height: 23px;
      }
      @media (max-width: 650.98px) {
        width: 90%;
        font-size: 16px;
        line-height: 23px;
      }
      .analytics_div {
        padding: 30px 20px;
        @media (max-width: 650.98px) {
          padding: 20px 15px;
        }
      }
    }
  }
  .admin-warning-container {
    margin-top: 30px;
    .analytics_section {
      width: 90%;
    }
  }
  .high-risk-reason-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 50px;
    .hrr_title {
      margin: 0 auto;
      width: 65%;
      font-size: 30px;
      font-weight: 600;
      line-height: 39px;
      text-align: center;
      @media (max-width: 876px) {
        width: 90%;
        font-size: 28px;
        line-height: 37px;
      }
      @media (max-width: 640px) {
        width: 95%;
        font-size: 26px;
        line-height: 35px;
      }
      @media (max-width: 460px) {
        width: 100%;
        padding: 0 10px;
        font-size: 24px;
        line-height: 32px;
      }
    }
    .pp_caption {
      font-size: 20px;
      font-weight: 300;
      line-height: 30px;
      text-align: center;
      @media (max-width: 600px) {
        font-size: 18px;
        line-height: 28px;
      }
    }
    .risk_points {
      padding: 25px 0px 50px 75px;
      @media (max-width: 1050px) {
        padding: 25px 50px 50px;
      }
      @media (max-width: 600px) {
        padding: 25px 30px 50px;
      }
      @media (max-width: 575.98px) {
        text-align: center !important;
      }
      @media (max-width: 350px) {
        padding: 25px 20px 50px;
      }
    }
    .hrr_points {
      padding: 25px 100px;
      @media (max-width: 600px) {
        padding: 25px 50px;
      }
      @media (max-width: 575.98px) {
        text-align: center !important;
      }
      @media (max-width: 350px) {
        padding: 25px 20px;
      }
    }
    .reasons_title {
      font-size: 18px;
      font-weight: 600;
      line-height: 23.4px;
      text-align: left;

      @media (max-width: 575.98px) {
        font-size: 16px;
        line-height: 21px;
        text-align: center !important;
      }
    }
    .reasons_desc {
      font-size: 18px;
      font-weight: 400;
      line-height: 25px;
      text-align: left;
      @media (max-width: 1050px) {
        margin-bottom: 45px;
      }
      @media (max-width: 575.98px) {
        text-align: center !important;
      }
      @media (max-width: 600px) {
        font-size: 16px;
        line-height: 23px;
      }
    }
  }

  .serach_property_section {
    padding: 30px 0;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    .add_property_title {
      margin-bottom: 30px;
      font-size: 30px;
      font-weight: 600;
      line-height: 39px;
      text-align: center;
      @media (max-width: 876px) {
        font-size: 28px;
        line-height: 37px;
      }
      @media (max-width: 640px) {
        font-size: 26px;
        line-height: 35px;
      }
      @media (max-width: 460px) {
        padding: 0 10px;
        font-size: 24px;
        line-height: 32px;
      }
    }
    .search_property_form {
      width: 95%;
      margin: auto;
      @media (max-width: 960px) {
        width: 100%;
      }
    }
  }

  .protect_comparison_section {
    padding: 30px 0;
    background: white;
    .protect_comparison_container {
      max-width: 1700px;
      padding: 1.5rem 30px;
    }
    .protect_comp_title {
      margin: 0 auto;
      width: 39%;
      font-size: 30px;
      font-weight: 600;
      line-height: 39px;
      text-align: center;
      @media (max-width: 1506.98px) {
        width: 44%;
      }
      @media (max-width: 1052.98px) {
        width: 60%;
      }
      @media (max-width: 876px) {
        font-size: 28px;
        line-height: 37px;
      }
      @media (max-width: 641.98px) {
        width: 80%;
        font-size: 26px;
        line-height: 35px;
      }
      @media (max-width: 460px) {
        width: 100%;
        padding: 0 10px;
        font-size: 24px;
        line-height: 32px;
      }
    }
    .comp_points {
      padding: 25px 0px;
      display: flex;
      justify-content: center;
      @media (max-width: 575.98px) {
        text-align: center !important;
      }
      @media (max-width: 350px) {
        padding: 25px 0px;
      }
    }
    .comp_title {
      font-size: 20px;
      font-weight: 600;
      line-height: 26px;
      text-align: left;

      @media (max-width: 575.98px) {
        font-size: 18px;
        line-height: 2px;
        padding: 10px 0;
      }
    }
    .comp_desc {
      font-size: 18px;
      font-weight: 400;
      line-height: 25px;
      text-align: left;
      .ep_texts {
        font-weight: 600;
        color: rgba(3, 149, 255, 1);
      }
      .other_texts {
        font-weight: 600;
        color: rgba(76, 82, 74, 1);
      }
      @media (max-width: 1050px) {
        margin-bottom: 45px;
      }
      @media (max-width: 600px) {
        font-size: 16px;
        line-height: 23px;
      }
    }
    .border1 {
      border: 1px solid rgba(222, 227, 234, 1);
      border-radius: 10px;
      margin-right: 10px;
      @media (max-width: 991.98px) {
        margin-right: 0px;
      }
    }
    .border2 {
      margin-left: 10px;
      border: 1px solid rgba(21, 190, 83, 1);
      border-radius: 10px;
      box-shadow: 0px 30px 70px -10px rgba(52, 75, 91, 0.29);
      @media (max-width: 991.98px) {
        margin-left: 0px;
        margin-top: 20px;
      }
    }
    .comp_content {
      height: 300px;
      @media (max-width: 1536.98px) {
        height: 325px;
      }
      @media (max-width: 1365.98px) {
        height: 375px;
      }
      @media (max-width: 1198.98px) {
        height: 425px;
      }
      @media (max-width: 1100.98px) {
        height: 475px;
      }
      @media (max-width: 996.98px) {
        height: auto;
      }
    }
    button {
      width: 85%;
      padding: 15px 20px;
    }
    .get_protected {
      background: rgba(21, 190, 83, 1);
    }
    .start_monitoring_button {
      background: rgba(1, 85, 183, 1);
      color: white;
      &:not(.disable):hover {
        background: rgba(1, 85, 183, 1);
      }
    }
    .comp_content_spacing {
      text-align: center;
      padding: 20px 50px;
      @media (max-width: 600px) {
        padding: 25px 30px;
      }
      @media (max-width: 575.98px) {
        text-align: center !important;
      }
      @media (max-width: 350px) {
        padding: 25px 10px;
      }
    }
  }

  .value_text {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
  }
  .values {
    font-size: 40px;
    font-weight: 600;
    line-height: 52px;
    letter-spacing: -0.05em;
    text-align: center;
  }
  .ep_glance_section {
    padding: 100px 0 100px;
  }
  .card_top {
    background: white !important ;
    width: 75% !important;
  }
  .box_shadow {
    box-shadow: 0px 30px 70px -10px rgba(52, 75, 91, 0.29);
  }
  .card_content {
    padding: 65px 0px 20px 20px;
  }
  .markgroup_img {
    display: flex;
    align-items: center;
    margin: 0px;
  }
  .report_btn-div {
    width: 50%;
    @media (max-width: 899.98px) {
      width: 100%;
    }
  }
  .map_section {
    width: 85%;
    height: 75%;
  }
  .monitoring_button {
    // width: 75%;
    padding: 15px 40px;
    border-radius: 29px;
    gap: 10px;
    background: #15be53;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.05em;
    text-align: center;
    color: #0d120b;
    &:not(.disable):hover {
      background: #15be53;
    }
  }
  .admin_monitoring_button {
    padding: 10px 20px;
    border-radius: 29px;
    gap: 10px;
    background: #15be53;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.05em;
    text-align: center;
    &:not(.disable):hover {
      background: #15be53;
    }
  }
  .button1 {
    padding: 15px 40px 15px 40px;
    border-radius: 29px;
    gap: 10px;
    background: #15be53;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.05em;
    text-align: center;
    color: #0d120b;
    &:not(.disable):hover {
      background: #15be53;
    }
    @media (max-width: 899.98px) {
      width: 80%;
      margin: 1.5rem 0;
    }
  }
  .subscribe-button {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.05em;
    text-align: center;
    padding: 15px 40px 15px 40px;
    width: 100%;
    border-radius: 29px;
    gap: 10px;
    background: #0155b7;
  }
  .social_section {
    .socialTitle {
      font-size: 30px;
      font-weight: 600;
      line-height: 39px;
      margin-top: 10px;
    }
    .card {
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
    .social_icons {
      margin-top: 10px;
    }
  }
  .ep_glance_headers {
    padding: 20px 20px 40px;
  }
  .ep_glance_title {
    font-size: 30px;
    font-weight: 600;
    line-height: 39px;
    text-align: center;
  }
  .ep_glance_caption {
    font-size: 20px;
    font-weight: 300;
    line-height: 30px;
    text-align: center;
  }
  .stay_loop_section {
    padding: 0 100px;
    background: white;
    position: relative;
    box-shadow: 0px 1px 0px 0px #dce4ec, 0px -1px 0px 0px #dce4ec;
  }
  .stay_loop_img {
    display: flex;
    align-items: center;
    margin: 0px;
    padding: 75px 0px 0px 20px;
  }
  .stay_loop_content {
    padding: 65px 15px 0px;
  }
  .stay_loop_title {
    font-size: 30px;
    font-weight: 600;
    line-height: 39px;
    letter-spacing: 0em;
  }
  .stay_loop_captions {
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    color: #4c524a;
  }

  @media only screen and (max-width: 1245px) {
    .monitoring_button {
      padding: 15px 20px;
    }
  }

  @media only screen and (max-width: 1199px) {
    .monitoring_button {
      width: 80%;
      padding: 15px 40px;
    }
  }
  @media only screen and (max-width: 1024px) {
    .card_top {
      width: 90% !important;
    }
    .personal_info,
    .property_add,
    .proeprty_date {
      text-align: center;
    }
    .subscribe_btn {
      text-align: center;
    }
  }

  @media only screen and (max-width: 820px) {
    @media only screen and (max-height: 1180px) {
      .section1 {
        position: relative;
        box-shadow: rgb(220, 228, 236) 0px 0px 0px 0px,
          rgb(220, 228, 236) 0px 1px 0px 0px;
      }
      .ep_glance_section {
        padding: 80px 0 100px;
      }
    }
  }

  @media only screen and (max-width: 800px) {
    .card_top {
      width: 75% !important;
    }
    .property-details {
      padding: 10px 0;
    }

    .section1 {
      position: relative;
      box-shadow: rgb(220, 228, 236) 0px 0px 0px 0px,
        rgb(220, 228, 236) 0px 1px 0px 0px;
    }
    .ep_glance_section {
      padding: 80px 0 100px;
    }
  }

  @media only screen and (max-width: 600px) {
    // .main_section {
    //   padding: 90px 0 100px;
    // }
    .card_top {
      width: 85% !important;
    }
    .stay_loop_section {
      padding: 0 30px;
    }
    .ep_glance_section {
      padding: 50px 0 70px;
    }
    .social_section {
      .socialTitle {
        font-size: 27px;
        line-height: 37px;
      }
    }
  }

  @media only screen and (max-width: 480px) {
    .ep_glance_caption {
      font-size: 18px;
      line-height: 28px;
    }
    .stay_loop_captions {
      font-size: 16px;
      line-height: 23px;
    }
    .button1 {
      font-size: 14px;
      line-height: 18px;
    }
    .card_content {
      padding: 65px 10px 20px 20px;
    }
    .social_section {
      .socialTitle {
        font-size: 25px;
        line-height: 33px;
      }
    }
  }

  @media only screen and (max-width: 414px) {
    .stay_loop_section {
      padding: 0 5px;
    }
    .ep_glance_title,
    .socialTitle {
      font-size: 28px;
      line-height: 35px;
    }
    .ep_glance_caption {
      font-size: 17px;
      line-height: 27px;
    }
    .stay_loop_captions {
      font-size: 15px;
      line-height: 22px;
    }
    .button1 {
      font-size: 13px;
      line-height: 15px;
    }
    .features_list li {
      font-size: 14px;
      line-height: 23px;
    }
  }

  @media only screen and (max-width: 375px) {
    .stay_loop_section {
      padding: 0 5px;
    }
    .button1,
    .subscribe-button {
      width: 100%;
      margin: 0.5rem 0;
      font-size: 12px;
      font-weight: 700;
    }
    .social_section {
      .socialTitle {
        font-size: 22px;
        line-height: 30px;
      }
    }
  }
}
.investor_table_section {
  display: flex;
  justify-content: center;
  align-items: center;
  .investor_table {
    width: 85%;
    padding: 80px 0px 20px;
  }
  // Define color variables
  $green: #7bc96f;
  $yellow: #ffdd57;
  $orange: #ffa500;
  $red: #ff6b6b;
  $purple: #a33b9e;

  // Shared styles for each color box
  .color-box {
    width: 15px;
    height: 15px;
    border-radius: 4px;
    display: inline-block;
    margin-right: 3px;
    box-sizing: border-box;
    background: #ebeef2;
  }

  // Individual color classes
  .color-low {
    background: #6cce81;
  }

  .color-medium-low {
    background: #f8c268;
  }

  .color-medium {
    background: #fd9982;
  }

  .color-medium-high {
    background: #ff5c65;
  }

  .color-high {
    background: #bc2970;
  }

  // Container to align color boxes
  .color-box-container {
    display: flex;
    align-items: center;
  }
}
