.private_guard_bg {
  background: linear-gradient(266.55deg, #8fda4f -45.17%, #0155b7 109.5%);
  box-shadow: 0px 30px 70px -10px rgba(52, 75, 91, 0.29);
  border-radius: 10px !important;
  border: none !important;
  outline: none !important;
  color: #ffffff;
  padding: 70px 0;
}
.card_bottom {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
}
.vector {
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pana {
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translate(-50%, -55%);
}

.pana2 {
  display: none;
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translate(-50%, -55%);
}
.vector1 {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -55%);
}
.vector2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -55%);
}
.vector3 {
  position: absolute;
  top: 54%;
  left: 50%;
  transform: translate(-50%, -55%);
}
.vector4 {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -55%);
}
.vector5 {
  display: none;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -55%);
}
.vector6 {
  display: none;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -55%);
}
.vector7 {
  display: none;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -55%);
}
.last_section {
  margin-bottom: 50px;
}

.private_guard_title {
  font-weight: 600;
  font-size: 30px;
  line-height: 39px;
  margin: 19px;
}

@media only screen and (max-width: 899px) {
  .last_section {
    margin-bottom: 100px;
  }
}
@media only screen and (max-width: 850px) {
  .vector4 {
    display: none;
  }
  .private_guard_title {
    margin: 15px;
  }
}
@media only screen and (max-width: 768px) {
  .private_guard_title {
    font-size: 20px;
  }
}
@media only screen and (max-width: 696px) {
  .vector3 {
    display: none;
  }
}
@media only screen and (max-width: 600px) {
  .button2 {
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    text-align: left;
    padding: 12px 35px;
  }
  .private_guard_title {
    margin: 10px 2px;
    line-height: 30px;
  }
  .pana,
  .vector {
    display: none;
  }
  .pana2 {
    display: block;
  }
}

@media only screen and (max-width: 520px) {
  .private_guard_bg {
    padding: 40px 0;
  }
}
