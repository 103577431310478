.profile-wrapper {
  .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: #0d120b;
  }

  .signoutBtn {
    background: #ff006b;
    border-radius: 20px;
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
    text-transform: uppercase;
    color: #ffffff;
    padding: 6px 20px;
    box-shadow: none;
  }

  .card-title {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #0395ff;
  }

  img {
    &.profilePic {
      width: 55px;
      height: 55px;
      border-radius: 50%;
    }
  }

  .profile-name {
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    color: #0d120b;
  }

  .form {
    .MuiInputBase-root {
      background: #f6f9fc;

      .Mui-disabled {
        -webkit-text-fill-color: rgb(0, 0, 0, 0.5);
      }
    }

    fieldset {
      border: 1px solid rgba(52, 75, 91, 0.1) !important;
    }

    .textfield {
      label {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #0d120b;
        opacity: 0.5;
      }

      &.Mui-disabled {
        color: red;
      }
    }

    input {
      &::placeholder {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #0d120b;
        opacity: 0.5;
      }
    }
  }

  .trusted-contacts {
    .caption {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #4c524a;
    }

    .contacts {
      .person-name {
        font-weight: 400;
        font-size: 14px;
        line-height: 28px;
        color: #0d120b;
      }

      .person-relation {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #4c524a;
      }

      a {
        font-weight: 400;
        font-size: 14px;
        line-height: 28px;
        color: #15be53;
        text-decoration: none;
      }
    }
  }

  .help-contact {
    .supportContact {
      li {
        .label {
          flex: 1 1 auto;
          width: 10%;

          span {
            font-weight: 400;
            font-size: 14px;
            line-height: 28px;
            color: #0d120b;
          }
        }

        .content {
          flex: 1 1 auto;
          width: 50%;

          a {
            font-weight: 400;
            font-size: 14px;
            line-height: 28px;
            color: #15be53;
            text-decoration: none;
          }

          p {
            font-weight: 400;
            font-size: 12px;
            line-height: 28px;
            color: #4c524a;
          }

          .MuiTypography-root {
            font-size: 14px;
          }
        }
      }
    }
  }

  .mobile-property-listing {
    .supportContact {
      li {
        .label {
          flex: 1 1 auto;
          width: 30%;
        }

        .content {
          .MuiTypography-root {
            font-size: 14px;
            line-height: 28px;
          }
        }
      }
    }
  }

  .payment-info {
    .paymentCards {
      box-shadow: none;
      border: none;
      margin: 0;

      &:before {
        display: none;
      }
    }

    .visa-num {
      font-weight: 400;
      font-size: 14px;
      line-height: 28px;
      color: #0d120b;
    }
  }

  .personal-info {
    .profile-photo-box {
      position: relative;

      .profile-photo-edit-btn {
        display: none;
        position: absolute;
        top: 0.5px;
        left: 2px;
        color: #15be53;
        background-color: #fefffe83;
      }
    }

    .profile-photo-box:hover {
      .profile-photo-edit-btn {
        display: block;
      }
    }
  }
}

.stannup-mail-content-img {
  height: 300px;
}

.stannup-mail-img {
  border-radius: 10px;
  box-shadow: 0px 30px 70px -10px rgba(52, 75, 91, 0.29);
}

.stannup-mail-qr-img {
  height: 300px;
}
.stannup-mail-qr-img3 {
  height: 90%;
}

@media only screen and (max-width: 500px) {
  .stannup-mail-content-img {
    height: 165px;
  }
}

@media only screen and (max-width: 400px) {
  .stannup-mail-qr-img {
    height: 200px;
  }
}

@media only screen and (min-width: 400px) {
  .contacts {
    .referal-link {
      width: 90% !important;
    }
  }
}
