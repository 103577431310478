.lennar-subscription {
  p,
  span {
    font-family: "Mabry Pro" !important;
  }
  .property_img {
    background-image: url("../assets/lennar/Mask-group.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: 0;
    height: auto;
    width: 100%;
    // overflow: hidden;
  }

  .lennar-subscription-features-box {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: flex-start;
    height: 80%;
    padding: 20px;

    .feature-heading {
      font-family: Mabry Pro;
      font-size: 25px;
      font-weight: 400;
      line-height: 39px;
      letter-spacing: 0em;
      text-align: left;
      color: #fff;
    }

    .lennar-features_list {
      li {
        font-family: Mabry Pro;
        font-size: 17px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: #fff;

        &::before {
          content: "";
          width: 20px;
          height: 20px;
          border-radius: 50px;
          background: url("../assets/icons/check.svg") rgba(21, 190, 83, 0.5);
          background-position: center;
          background-repeat: no-repeat;
          background-size: 12px;
          position: absolute;
          left: -30px;
        }
      }
    }
  }

  .right-grid {
    height: auto;
    overflow: hidden;
    overflow-y: auto;
  }

  .lennar-subscription-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 50px 100px;

    .lennar-subscription-heading {
      font-family: Reckless Neue !important;
      font-size: 38px;
      font-weight: 400;
      line-height: 46px;
      letter-spacing: -0.05em;
      text-align: left;
      color: #2d2d2d;
    }

    .subscription-plan-text {
      font-family: Mabry Pro;
      font-size: 22px;
      font-weight: 400;
      letter-spacing: 0em;
      text-align: left;
    }

    .subscription-steps {
      margin: 40px 0px;

      img {
        pointer-events: none;
      }
    }

    .subscription-details {
      padding: 30px 50px 50px;
      border: 1px solid #dee3ea;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;

      .subscription-main-text {
        font-family: Mabry Pro;
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: left;
        color: #2d2d2d;
      }

      .subscription-sub-text {
        font-family: Mabry Pro;
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
        color: #767676;
      }

      .lennar-card {
      }
    }

    .subscription-summery {
      padding: 50px;
      background-color: #f6f9fc;
      border: 1px solid #dee3ea;
    }

    .total-subscription-text {
      font-family: Mabry Pro;
      font-size: 20px;
      font-weight: 500;
      line-height: 30px;
      letter-spacing: 0em;
      text-align: left;
      color: #767676;
    }

    .total-subscription-amount {
      font-weight: 700;
      color: #2d2d2d;
    }

    .lennar-subscription-submit-section {
      width: 100%;
      padding: 50px;
      border: 1px solid #dee3ea;

      .lennar_popup_btn {
        background: #2d2d2d !important;
      }
      .lennar_back_btn {
        background: #fff !important;
        color: black !important;
        font-weight: 700 !important;
      }

      .cancel-text {
        font-family: Mabry Pro;
        font-size: 15px;
        font-weight: 400;
        line-height: 15px;
        letter-spacing: 0em;
        text-align: center;
        color: #767676;
      }
    }

    .terms-and-conditions {
      font-family: Mabry Pro;
      font-size: 12px;
      font-weight: 400;
      line-height: 12px;
      letter-spacing: 0em;
      text-align: left;
      color: #2d2d2d;
      margin-top: 40px;
    }
  }
  .StripeElement {
    font-size: 18px; /* Adjust to your desired font size */
  }
}

.display-icon {
  display: none !important;
}

@media only screen and (max-width: 1375px) {
  .lennar-subscription-box {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

@media only screen and (min-width: 899px) and (max-width: 1069px) {
  .display-icon {
    display: contents !important;
  }
  .display-chip {
    display: none !important;
  }
}

@media only screen and (max-width: 900px) {
  .lennar-subscription {
    .right-grid {
      height: 100%;
      overflow: auto;
    }
    .lennar-subscription-features-box {
      justify-content: center;
    }
    // .property_img {
    //   height: 70vh;
    // }
  }
}

@media only screen and (min-width: 575px) and (max-width: 645px) {
  .display-icon {
    display: contents !important;
  }
  .display-chip {
    display: none !important;
  }
}

@media only screen and (max-width: 665px) {
  .lennar-subscription {
    .lennar-subscription-heading {
      font-size: 33px !important;
    }
    .feature-heading {
      font-size: 23px !important;
    }
    .lennar-features_list {
      li {
        font-size: 16px;
      }
    }
  }
}

@media only screen and (max-width: 565px) {
  .lennar-subscription {
    .feature-heading {
      font-size: 19px !important;
    }
    .lennar-subscription-heading {
      font-size: 30px !important;
    }
    .lennar-features_list {
      li {
        font-size: 14px;
      }
    }
  }
}

@media only screen and (max-width: 365px) {
  .lennar-subscription {
    .lennar-subscription-heading {
      font-size: 25px !important;
    }
    .feature-heading {
      font-size: 17px !important;
    }
    .lennar-features_list {
      li {
        font-size: 12px;
      }
    }
  }
}

// @media only screen and (max-width: 425px) {
//   .lennar-subscription {
//     .property_img {
//       height: 80vh;
//     }
//   }
// }
