.attorney-program-page {
  .natioal_host_bg_img {
    margin-bottom: 70px;
    .custom-mt-120 {
      margin-top: 70px !important;
    }
  }
  .custom_logo {
    a {
      text-decoration: none !important;
    }
    .custom_logo_img {
      .nn {
        font-family: IBM Plex Sans;
        font-weight: 700;
        font-size: 16px;
        line-height: 150%;
        color: #0d120b;
      }
    }
  }
  .agent_logo {
    max-height: 75px;
  }
  .section-2 {
    margin-top: 150px;
    @media (max-width: 1440px) {
      margin-top: 70px !important;
    }
    // height: 100vh;
    .container-fluid {
      padding: 0 100px;
      @media (max-width: 1299.99px) {
        padding: 0 40px;
      }
    }
    .title-monitoring-section {
      background: #ebf3fc;
      border-radius: 10px;
      padding: 90px 60px;
      @media (max-width: 991px) {
        padding: 60px 30px;
      }
    }
    .grid1 {
      z-index: 1;
      .monitoring_essential {
        font-size: 30px;
        font-weight: 600;
        line-height: 39px;
        text-align: left;
        color: #0d120b;
        @media (max-width: 1199.98px) {
          text-align: center;
        }
        @media (max-width: 599.99px) {
          font-size: 28px;
          line-height: 35px;
        }
      }
    }
    .title-monitoring-cards {
      background-color: #fff;
      color: #0d120b;
      border-radius: 5px;
      height: 100%;
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
      text-align: left;
      @media (max-width: 991px) {
        font-size: 16px;
        line-height: 26px;
      }
      @media (max-width: 599.98px) {
        font-size: 14px;
        line-height: 24px;
      }
    }
    .title-card-text {
      font-family: IBM Plex Sans;
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      text-align: left;
      color: #4c524a;
      padding: 10px 0;

      @media (max-width: 1199.98px) {
        text-align: center;
      }
      @media (max-width: 991px) {
        font-size: 16px;
        line-height: 26px;
      }
    }
  }
  .section-3 {
    .bold-text {
      @media (max-width: 991.98px) {
        font-size: 18px !important;
      }
    }
    .container-fluid {
      padding: 0 150px;
      @media (max-width: 1299.99px) {
        padding: 0 60px;
      }
      @media (max-width: 768.99px) {
        padding: 0 40px;
      }
    }
  }
  .section-4 {
    background-color: #ffffff;
    padding-top: 100px;
    padding-bottom: 100px;
    @media (max-width: 991.98px) {
      padding-top: 80px;
      padding-bottom: 80px;
    }
    @media (max-width: 599.98px) {
      padding-top: 50px;
      padding-bottom: 50px;
    }
    .TTP-content {
      p,
      h3 {
        text-align: left !important;
      }
    }
    .home-img-wrapper {
      border-radius: 10px;
    }
    .content___speacing {
      padding-right: 175px !important;
      padding-left: 150px !important;
      @media (max-width: 1470.9px) {
        padding-right: 130px !important;
        padding-left: 100px !important;
      }
      @media (max-width: 1350.9px) {
        padding-right: 70px !important;
        padding-left: 100px !important;
      }
      @media (max-width: 1199.9px) {
        padding-right: 30px !important;
        padding-left: 30px !important;
      }
      @media (max-width: 991.9px) {
        padding-right: 20px !important;
        padding-left: 20px !important;
      }
    }

    .img-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media (max-width: 991.98px) {
        justify-content: center !important;
      }
      img {
        position: relative;
        border-radius: 10px;
        width: 520px;
        height: 575px;
        box-shadow: 0px 30px 70px -10px #344b5b4a;
        @media (max-width: 1270px) {
          width: auto;
          height: 525px;
        }
        @media (max-width: 991.98px) {
          width: 425px;
          height: 475px;
        }
        @media (max-width: 545.98px) {
          width: 390px;
          height: 425px;
        }
        @media (max-width: 475.98px) {
          width: 315px;
          height: 375px;
        }
      }
    }
    .img-end {
      justify-content: center !important;
      @media (max-width: 991.98px) {
        justify-content: center !important;
      }
    }

    .padding-header {
      margin-top: 20px;
    }
    .competitors-content-space {
      @media (max-width: 991.98px) {
        padding: 30px 80px;
      }
      @media (max-width: 599.98px) {
        padding: 30px 40px;
      }
    }
  }

  .protection_title {
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: #0d120b;
    margin-bottom: 20px;
  }
  .protection_desc {
    color: #4c524a;
  }
  .font-weight-bold {
    color: #0d120b;
  }

  .how-we-stand-out {
    background: #ebf3fc !important;
  }
  .fw-600-v2 {
    font-weight: 600;
  }
  .spacing-right {
    padding-right: 1rem !important;
  }
  .button-width-mobile {
    @media (max-width: 599.9px) {
      width: 80vw !important;
    }
  }
  .container_spacing {
    padding: 40px;
  }
  .button_clr {
    background: #15be53 !important;
  }
  .pt-120 {
    padding-top: 90px !important;
  }
  .signin_button_v2 {
    flex-direction: row;
    align-items: flex-start;
    padding: 10px 40px !important;
    font-weight: 600;
    border-radius: 29px;
    color: #0d120b;
    border: none;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    @media (max-width: 599.9px) {
      font-size: 14px;
    }
  }

  .risk_assesment_button {
    background: #0155b7 !important;
    color: #ffffff !important;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    padding: 15px 40px !important;
    letter-spacing: 0.05em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    text-transform: uppercase;
    @media (max-width: 1321.99px) {
      padding: 15px 20px !important;
    }
    @media (max-width: 875.99px) {
      padding: 15px 40px !important;
    }
  }
  .risk_btn_bg {
    background: #22839e !important;
  }

  .sub_header {
    font-size: 30px;
    line-height: 39px;
    font-weight: 600;
    color: #0d120b;
    @media (max-width: 1199.98px) {
      text-align: center;
    }
    @media (max-width: 599.99px) {
      font-size: 28px;
      line-height: 35px;
    }
  }
  .heading-first {
    font-family: IBM Plex Sans;
    font-size: 48px;
    font-weight: 600;
    line-height: 57.6px;
    letter-spacing: -0.03em;
    text-align: left;
    margin-top: 100px;
    color: #0d120b;
  }
  .section-height {
    height: 628px;
    //   min-height: 100vh;
  }
  .ep__bg-logo {
    width: 750px;
    height: 700px;
    position: absolute;
    right: 0;
    top: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .ep__bg-logo-mobile {
    display: none;
    width: 100vw;
    height: auto;
    img {
      border-radius: 10px;
      width: 460px;
      height: 480px;
      object-fit: cover;
      margin-top: 30px !important;
    }
  }
  .para-text {
    font-family: IBM Plex Sans;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    color: #0d120b;
  }
  .para-text-bold {
    font-family: IBM Plex Sans;
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    text-align: left;
    color: #0d120b;
  }
  .header_text {
    text-transform: uppercase;
  }

  .notify_header {
    //   color: #0395ff;
    color: #005b9e;
    font-size: 16px;
    line-height: 21px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    margin-bottom: 25px;
    @media (max-width: 1199.98px) {
      text-align: center;
    }
  }

  .check_img {
    max-width: 100%;
    height: 100%;
    margin-right: 10px;
    margin-top: 5px;
  }
  .text-description {
    font-size: 18px;
    line-height: 25px;
    font-weight: 400;
    color: #4c524a;

    @media (max-width: 599.99px) {
      font-size: 15px;
      line-height: 20px;
    }
  }

  .para_text {
    text-align: start;
    margin-left: 120px;
  }

  .as_seen_on {
    img {
      opacity: 50%;
    }
    span {
      opacity: 80%;
    }
  }
  * {
    box-sizing: border-box;
  }

  .text-line-fix {
    @media (max-width: 1440px) {
      font-size: 15px !important;
      padding: 10px 32px !important;
    }
    @media (max-width: 1310px) {
      font-size: 14px !important;
      padding: 10px 30px !important;
    }
    @media screen and (min-width: 1200px) and (max-width: 1240.98px) {
      font-size: 12px !important;
      padding: 10px 26px !important;
    }
    @media (max-width: 1140px) {
      font-size: 14px !important;
      padding: 8px 24px !important;
    }
    @media (max-width: 1084px) {
      font-size: 12px !important;
      padding: 8px 24px !important;
    }
    @media (max-width: 991.98px) {
      font-size: 15px !important;
      padding: 10px 40px !important;
    }
  }
  .homepage_logos_v2 {
    img {
      filter: invert(1) brightness(100);
    }
  }
  .homepage_logos_v2 {
    max-width: 100%;
    opacity: 25%;
    gap: 22px;
    -webkit-user-select: none !important;
    @media (max-width: 991px) {
      flex-wrap: wrap !important;
      justify-content: center;
      gap: 40px;
      padding-bottom: 2rem !important;
    }
  }
  .outer-div {
    max-width: 64%;
    padding-left: 100px;
    margin-top: 0rem;

    @media (max-width: 991px) {
      max-width: 100%;
      padding-left: 0px;
    }
  }
  .homepage_logos_v2 img {
    height: 22px;
    object-fit: contain;
  }

  @media only screen and (max-width: 1440px) {
    .ep__bg-logo {
      width: 705px;
      height: 655px;
    }
    .heading-first {
      margin-top: 40px;
      font-size: 38px;
      line-height: 42px;
    }
  }

  @media only screen and (max-width: 1340px) {
    .ep__bg-logo {
      width: 667px;
      height: 619px;
    }
    .heading-first {
      margin-top: 40px;
      font-size: 35px;
      line-height: 44px;
    }
  }
  @media only screen and (max-width: 1240px) {
    .ep__bg-logo {
      width: 600px;
      height: 560px;
    }
    .heading-first {
      margin-top: 40px;
      font-size: 30px;
      line-height: 38px;
    }
    .para-text {
      font-family: IBM Plex Sans;
      font-size: 18px;
      font-weight: 400;
      line-height: 25px;
      text-align: left;
    }
  }
  @media screen and (min-width: 991.98px) and (max-width: 1199.98px) {
    .mx-150 {
      padding-left: 50px !important;
      padding-right: 50px !important;
    }
  }
  @media only screen and (max-width: 1140px) {
    .ep__bg-logo {
      width: 540px;
      height: 500px;
    }
    .heading-first {
      margin-top: 40px;
      font-size: 30px;
      line-height: 39px;
    }
    .container-150 {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
  @media only screen and (max-width: 1040px) {
    .ep__bg-logo {
      width: 512px;
      height: 472px;
    }
  }
  @media only screen and (max-width: 991.98px) {
    .notify_header {
      font-size: 12px;
      line-height: 16px;
    }

    .content-spacing-header {
      padding-left: 20px !important;
      padding-right: 20px !important;
      margin-top: 40px;
      width: 80vw;
    }
    .as_seen_on {
      justify-content: center;
    }
    .ep__bg-logo {
      display: none;
      position: unset;
      width: 100%;
      height: auto;
    }
    .ep__bg-logo-mobile {
      display: flex;
      justify-content: center;
    }
    .section-height {
      height: auto;
    }
    .heading-first {
      margin-top: 40px;
      font-family: IBM Plex Sans;
      font-size: 30px;
      font-weight: 600;
      line-height: 39px;
      text-align: center;
    }
    .para-text {
      font-family: IBM Plex Sans;
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
      text-align: center;
    }
    .para-text-bold {
      font-family: IBM Plex Sans;
      font-size: 18px;
      font-weight: 600;
      line-height: 27px;
      text-align: center;
    }
  }
  @media only screen and (max-width: 800px) {
    .content-spacing-header {
      width: 90vw;
    }
  }
  @media only screen and (max-width: 600px) {
    .stats {
      .amount {
        font-size: 13px;
      }
    }
    .content-spacing-header {
      width: 100vw;
      margin-top: 40px;
    }
  }
}
