#settings-menu {
  .setting-menu-item {
    width: 120px;
  }

  .setting-menu-box {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 20px;
      color: #15be53
    }

    .setting-menu-text {
      margin-left: 10px;
    }
  }

  .setting-menu-box:hover {
    color: #0395ff;
    stroke: #0395ff;
    stroke-width: 0.55;
  }
}

.addLeads {
  button {
    flex-direction: row;
    align-items: flex-start !important;
    padding: 5px 30px !important;
    margin: 5px;
    font-weight: 600 !important;
    font-size: 0.875rem;
    line-height: 1.75;
    gap: 10px;
    border-radius: 29px !important;
    color: #ffffff !important;
    border: none !important;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.05em;
    text-transform: uppercase !important;
  }

  .addLeadsBtn {
    background: #1565c0 !important;
  }
}

.MuiDialogContent-root {
  overflow: hidden;
}

.textField_spacing {
  padding-left: 8px !important;
  padding-right: 8px !important;
  padding-bottom: 24px !important;
}

.preview-file {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px !important;
  // width: 80%;

  .preview-url {
    color: blue;
  }

  span {
    cursor: pointer;
  }
}

.preview-url {
  color: blue;
}

.column-date-range {
  input {
    align-items: center;
    font-size: 16px;
    height: 52px;
    width: 225px;
  }
}

#pagination-container {
  display: flex;
  justify-content: center;

  .pagination-first-page,
  .pagination-last-page {
    min-width: 0px;
    width: 40px;
    border: 1px solid transparent;
    background-color: rgba(255, 255, 255, 1) !important;
    color: rgba(21, 190, 83, 1);
  }

  .pagination-first-page:disabled,
  .pagination-last-page:disabled {
    color: rgba(188, 197, 204, 1);
    border: 1px solid transparent;
  }
}

.custom-pagination {
  .Mui-selected {
    border: 1px solid rgba(21, 190, 83, 1);
    background-color: rgba(255, 255, 255, 1) !important;
    color: rgba(21, 190, 83, 1);
  }

  .MuiPaginationItem-previousNext {
    border: 1px solid transparent;
    background-color: rgba(255, 255, 255, 1) !important;
    color: rgba(21, 190, 83, 1);
  }

  .Mui-disabled {
    color: rgba(188, 197, 204, 1);
  }
}


@media only screen and (max-width: 445px) {
  .createAcBtn {
    .popup_Btn {
      padding: 8px 16px !important
    }
  }
}

@media only screen and (max-width: 350px) {
  .createAcBtn {
    .popup_Btn {
      padding: 8px 8px !important
    }
  }

}