.howItWorks_background_img {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100vh;
}

.image_wrapper {
    .iphoneImg {
        margin-bottom: -240px;

        img {
            box-shadow: 0px 35.5749px 83.0081px -11.8583px rgba(52, 75, 91, 0.29);
            filter: drop-shadow(30px 30px 40px rgba(0, 0, 0, 0.3));
        }
    }
}

.sectionTitle {
    font-weight: 300;
    font-size: 24px;
    line-height: 36px;
    color: #0D120B;
    margin-bottom: 100px;
}

.feature_img {
    position: relative;
    padding: 25px;

    img {
        box-shadow: 0px 17.0825px 39.8592px -5.69417px rgb(0 0 0 / 50%);
    }

    &:after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 6%;
        z-index: -1;
        background: #0395FF;
        opacity: 0.1;
        border-radius: 10px 10px 10px 100px;
    }
}

.featurExplained_wrapper {
    h5 {
        &.title {
            font-weight: 600;
            font-size: 30px;
            line-height: 39px;
            color: #0D120B;
            margin-bottom: 30px;
        }
    }

    p {
        &.description {
            font-weight: 300;
            font-size: 20px;
            line-height: 30px;
            color: #4C524A;
        }
    }

    .fetured-explain {
        padding: 0 50px 0 160px;
    }
}

.how-it-works-content {

    font-family: "IBM Plex Sans", sans-serif;
    text-align: center;

    .stand_out_wrapper {
        &:after {
            display: none;
        }
    }

    .how-it-work-section {
        padding: 100px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .timeline {
            padding-top: 60px;
            width: 880px;
            display: block;
        }

        .mobile-view {
            display: none;
        }

        .timeline-dot {
            background-color: #FFF;
            box-shadow: 0px 10px 20px -10px rgba(52, 75, 91, 0.2);
            padding: 15px;

            svg {
                width: 38.89px;
                height: 38.89px;
            }
        }

        .content-heading {
            font-weight: 600;
            font-size: 18px;
            line-height: 25px;
            padding: 10px 0;
        }

        .content-description {
            font-weight: 400;
            font-size: 18px;
            line-height: 25px;
            color: #4C524A;
            // width: 260px;
        }

        .step-icon {
            padding: 8px;
            border-radius: 50%;
            box-shadow: 0px 10px 20px -10px rgba(52, 75, 91, 0.2);
        }

        .icon-style {
            width: 41.83px;
            height: 31.61px;
        }

        .timeline-start-end-content {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: transparent;
            box-shadow: none;
            font-weight: 600;
            font-size: 14px;
            line-height: 18.2px;
            text-transform: uppercase;
            color: #0395FF;
            width: 150px;
        }

        .MuiTimelineConnector-root {
            background-color: transparent;
            border: 1.5px dashed #C5CFD9;
        }
    }

    .how-it-work-text {
        font-size: 30px;
        line-height: 39px;
    }
}

@media only screen and (max-width:1400.98px) {

    .featurExplained_wrapper {
        .fetured-explain {
            padding: 0 20px 0 160px;
        }
    }
}

@media only screen and (max-width:1299.98px) {
    .feature_img {
        &:after {
            left: 6%;
        }
    }

    .featurExplained_wrapper {
        .fetured-explain {
            padding: 0 0 0 130px;
        }
    }
}

@media only screen and (max-width:1199.98px) {
    .featurExplained_wrapper {
        .fetured-explain {
            padding: 0 0 0 100px;
        }
    }
}

@media only screen and (max-width:991.98px) {
    .feature_img {
        &:after {
            left: 0;
        }
    }

    .howitworks_bg {
        height: 100%;
        padding: 30% 0;
    }


    .howItWorks_background_img {
        height: 100%;
        padding: 25% 0;
    }

    .image_wrapper {
        .iphoneImg {
            margin-bottom: -240px;
        }
    }

    .sectionTitle {
        margin-bottom: 50px;
        font-size: 20px;
        line-height: 28px;
    }

    .sub_header {
        font-size: 20px;
        line-height: 26px;
        margin-bottom: 15px;
    }

    .featurExplained_wrapper {
        h5 {
            &.title {
                font-size: 20px;
                line-height: 26px;
                margin-bottom: 15px;
            }
        }

        p {
            &.description {
                font-size: 16px;
                line-height: 24px;
            }
        }

        .fetured-explain {
            padding: 0 50px 0 60px !important;
        }
    }

    .how-it-work-section {
        .timeline {
            width: 100% !important;
        }

        .mobile-view {
            flex-direction: column;
            margin: 2rem 1rem 0 1.5rem;

            .mobile-icon-style {
                width: 40px !important;
                height: 40px !important;
            }

            .content-heading {
                padding: 10px 0 0 0 !important;
            }

            .content-description {
                text-align: left;
                font-size: 16px !important;
                line-height: 24px !important;
                width: 100%;
            }

            .start-end-message {
                font-size: 14px;
                line-height: 18px;
                font-weight: 600;
                color: #0395FF;
                text-transform: uppercase;
                letter-spacing: 0.1rem;
            }

            .Mui-expanded {
                margin: 0px !important;
            }

            .MuiDivider-vertical {
                width: 1px;
                border: 1px dashed #C5CFD9;
            }
        }
    }
}

@media only screen and (max-width:768.98px) {
    .featurExplained_wrapper {
        .fetured-explain {
            padding: 0 12px !important;
        }
    }
}

@media only screen and (max-width:600.98px) {
    .how-it-work-section {
        .timeline {
            display: none !important;
        }

        .mobile-view {
            display: block !important;
        }
    }
}

@media only screen and (max-width:575.98px) {

    .how-it-works-content {

        .how-it-work-section {
            padding-top: 30px;
        }

        .how-it-work-text {
            font-size: 20px;
            line-height: 26px;
        }
    }
}