.lennar-logo {
  height: 36px;
  margin: 20px 10px !important;
  object-fit: contain;
}
.property_bg_img {
  background-image: url("../assets/lennar/property_bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 50vh;
  width: 100%;
}
.lennar-wrapper {
  p {
    font-family: "Mabry Pro";
    font-size: 20px;
    color: #2d2d2d;
  }
}

.property_heading {
  font-family: "Reckless Neue" !important;
  font-size: 64px !important;
  line-height: 76.78px;
}

.paragraph_texts {
  font-size: 20px;
  line-height: 30px;
  padding: 0px 10px;
  margin: 0px 15px;
  max-width: 690px;
  color: #ffffff !important;
}

.properties_list {
  margin: "35px 20px 15px";
  color: #767676 !important;
}
.poperty_Btn {
  flex-direction: row;
  padding: 4px 5px !important;
  font-weight: 600 !important;
  gap: 10px;
  border-radius: 29px !important;
  color: #ffffff !important;
  border: none !important;
  font-size: 12px !important;
  line-height: 20px !important;
  text-transform: uppercase !important;
}

.protected_btn {
  background: #15be53 !important;
}

.unprotected_btn {
  background-color: #ff006b !important;
}

.property_list_heading {
  font-size: 26px !important;
  line-height: 39px;
  font-weight: 400;
}

.body_text_1 {
  font-size: 20px !important;
  line-height: 22px;
  font-weight: 400;
}

.body_text_2 {
  font-size: 16px !important;
  line-height: 22px;
  font-weight: 400;
}
.px-50 {
  padding: 0px 50px;
}
.px-150 {
  padding: 35px 150px;
}
.choose_us_header {
  font-family: "Reckless Neue" !important;
  font-size: 38px !important;
  line-height: 46px;
  padding: 35px 150px 10px;
  margin-bottom: 0;
}
.choose_us_text {
  font-size: 22px !important;
  line-height: 36px;
  font-weight: 400;
}
.description_text {
  font-size: 20px;
  line-height: 30px;
  font-weight: 300;
}

.pricing_brerakdown {
  font-family: "Mabry Pro";
  font-weight: 500;
  font-size: 22px;
  line-height: 33px;
  text-transform: uppercase;
  margin: "35px 20px 15px";
}
.continue_payment_btn {
  font-family: "Mabry Pro" !important;
  font-weight: 400;
  // flex-direction: row;
  padding: 20px 100px !important;
  color: #ffffff !important;
  gap: 10px;
  border-radius: 29px !important;
  border: none !important;
  font-size: 20px !important;
  line-height: 20px !important;
  text-transform: none !important;
  background-color: #2d2d2d !important;
}
.provider_fonts {
  font-family: "Mabry Pro" !important;
}
.provider_plan_btn {
  font-family: "Mabry Pro" !important;
  font-weight: 400;
  // flex-direction: row;
  padding: 12px 35px !important;
  color: #ffffff !important;
  gap: 10px;
  border-radius: 29px !important;
  border: none !important;
  font-size: 20px !important;
  line-height: 20px !important;
  text-transform: none !important;
  background-color: #2d2d2d !important;
}
.footer_text {
  font-size: 12px !important;
  font-family: "IBM Plex Sans";
  line-height: 14px;
  margin-top: 30px !important;
}
.cancellation-policy {
  font-weight: 400;
  font-size: 18px !important;
  line-height: 12px;
  letter-spacing: 0em;
  color: #2d2d2d;
  margin-top: 20px;
  text-decoration: inherit !important;
  text-underline-position: from-font;
}

@media only screen and (min-width: 768px) {
  .property_bg_img {
    height: 60vh;
  }
}

@media only screen and (max-width: 768px) {
  .property_heading {
    font-family: "Reckless Neue" !important;
    font-size: 40px !important;
    line-height: 40px;
  }
  .paragraph_texts {
    font-size: 16px !important;
    line-height: 20px;
  }
  .choose_us_header {
    text-align: center;
  }
}

@media only screen and (max-width: 700px) {
  .choose_us_header {
    padding: 35px 150px 10px;
    font-size: 36px !important;
  }
  .choose_us_text {
    font-size: 22px !important;
  }
}

@media only screen and (max-width: 582px) {
  .lennar-wrapper {
    .pricing {
      font-size: 18px !important;
    }
  }
  .choose_us_header {
    padding: 35px 100px 10px;
    font-size: 36px !important;
  }
  .choose_us_text {
    font-size: 22px !important;
    line-height: 34px !important;
  }
  .description_text {
    line-height: 28px !important;
    font-size: 18px !important;
  }
  .property_list_heading {
    font-size: 24 !important;
  }
  .body_text_1 {
    font-size: 18px !important;
  }
  .body_text_2 {
    font-size: 14px !important;
  }
  .pricing_brerakdown {
    font-size: 20px !important;
  }
  .cancellation-policy {
    font-size: 16px !important;
  }
}

@media only screen and (max-width: 482px) {
  .lennar-logo {
    // width: "auto";
    height: 30px;
    margin: 20px 10px !important;
    // aspect-ratio: 8 / 2;
    object-fit: contain;
  }
  .property_heading {
    font-family: "Reckless Neue" !important;
    font-size: 38px !important;
    line-height: 38px;
    max-width: 100%;
  }
  .paragraph_texts {
    font-size: 16px !important;
    line-height: 19px !important;
    max-width: 100%;
  }
  .choose_us_header {
    padding: 35px 50px 10px;
    font-size: 32px !important;
  }
  .choose_us_text {
    font-size: 20px !important;
    line-height: 32px;
  }
  .description_text {
    line-height: 26px;
    font-size: 17px !important;
  }
  .pricing_brerakdown {
    font-size: 19px !important;
    line-height: 30px;
  }
  .continue_payment_btn {
    font-size: 16px !important;
    line-height: 36px;
    padding: 20px 50px !important;
  }
  .property_list_heading {
    font-size: 23 !important;
  }
  .body_text_1 {
    font-size: 17px !important;
  }
  .body_text_2 {
    font-size: 13px !important;
  }
}
