.property-risk-page-content {
  font-family: "IBM Plex Sans" !important;
  .first_section {
    min-height: 100vh;
  }
  .prm_bg_img {
    background-image: url("../../assets/property_risk.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 10% 0 5%;
    z-index: 0;
    height: 90vh !important;
  }
  .header_texts {
    padding: 0 30px;
  }

  .first-section {
    padding: 0px !important;
    padding-top: 7rem !important;
    // min-height: 80vh;
    // max-height: 80vh;
    // max-height: min-content !important;

    .main-text {
      font-size: 30px;
      line-height: 35px;
    }
  }

  .paragraph_text {
    font-size: 20px;
    line-height: 25px;
    text-align: left;
  }

  .linear_bg {
    background: linear-gradient(266.55deg, #8fda4f -45.17%, #0155b7 109.5%);

    box-shadow: 0px 30px 70px -10px rgba(52, 75, 91, 0.29);
    border-radius: 10px !important;
    border: none !important;
    outline: none !important;
    color: #ffffff;
    padding: 70px 0;
  }
  .check_img {
    width: 20px !important;
    height: 20px !important;
    margin: 5px;
  }
  .ml-0 {
    margin-left: 0px !important;
  }
  .p-top-down {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .sec1_text {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    padding: 5px;
    text-transform: uppercase;
  }

  .property-protected-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .pp_title {
      font-size: 30px;
      font-weight: 600;
      line-height: 39px;
      text-align: center;
    }
    .pp_caption {
      font-size: 20px;
      font-weight: 300;
      line-height: 30px;
      text-align: center;
    }
    .pp_points {
      padding: 25px 100px 50px;
    }
    .protection_title {
      font-size: 18px;
      font-weight: 600;
      line-height: 23.4px;
      text-align: left;
    }
    .protection_desc {
      font-size: 18px;
      font-weight: 400;
      line-height: 25px;
      text-align: left;
    }
  }

  .ep_section {
    margin-top: 80px;
    padding: 0 100px;
    .ep_header {
      font-size: 30px;
      font-weight: 600;
      line-height: 39px;
      text-align: left;
    }
    .ep_sub_header {
      font-size: 18px;
      font-weight: 700;
      line-height: 25px;
      text-align: left;
    }
    .ep_text {
      font-size: 18px;
      font-weight: 400;
      line-height: 25px;
      text-align: left;
    }
    // .hp_img_section {
    //   padding: 0 70px;
    // }
    .hp_img {
      padding: 0 80px 40px;
    }
  }

  .textbox-icons {
    width: 20px;
    height: 20px;
  }

  .pt-150 {
    padding-top: 150px;
  }

  .pb-150 {
    padding-bottom: 150px;
  }

  .steps_wrapper {
    .steps {
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      color: #0d120b;
      background: rgba(21, 190, 83, 0.3);
      width: 46px;
      height: 46px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .info_blocks {
      background-color: #f6f9fc !important;
      padding: 30px;
      border-radius: 10px;
      border: 1px solid #dee3ea;
      height: 260px;
    }
    .header_text {
      font-size: 18px;
    }
    .paragraph_text {
      font-size: 18px;
    }
  }

  .alert_section {
    padding-bottom: 300px;
  }
  .it_works {
    padding-top: 210px;
  }

  .card_top {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white !important ;
    width: 75% !important;
  }

  .box_shadow {
    box-shadow: 0px 30px 70px -10px rgba(52, 75, 91, 0.29);
  }
  .property_form {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(0%, 23.5%);
  }

  .card_section {
    box-shadow: 0px 30px 70px -10px rgba(52, 75, 91, 0.29);
    // padding: 20px;
  }
  .card_content {
    padding: 65px 0px 20px 20px;
  }
  .markgroup_img {
    display: flex;
    align-items: center;
    // width: 100% !important;
    margin: 0px;
  }
  .button_width {
    width: 100%;
    @media (max-width: 425px) {
      font-size: 14px !important;
    }
  }
  .button1 {
    padding: 15px 40px 15px 40px;
    border-radius: 29px;
    gap: 10px;
    background: #15be53;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.05em;
    text-align: center;
    color: #0d120b;
    &:not(.disable):hover {
      background: #15be53;
    }
  }
  .button2 {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.05em;
    text-align: center;
    padding: 15px 40px 15px 40px;
    border-radius: 29px;
    gap: 10px;
    color: #15be53;
    background: #ffffff;
    &:not(.disable):hover {
      background: #ffffff;
    }
  }
  .error_border {
    border: 1px solid red !important;
  }
  .terms_error {
    color: #d32f2f;
    font-size: 12px;
    text-align: start !important;
  }
  .prm-form {
    width: 75% !important;
  }
  .cnt-prm-form {
    width: 85% !important;
  }
  .lead-prm-form {
    width: 100% !important;
  }
  .request-form {
    padding: 3rem !important;
  }
  .heading_msg {
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
  }
  .caption_texts {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
  .msg_blocks {
    background-color: #f6f9fc !important;
    padding: 40px;
    border-radius: 10px;
    border: 1px solid #dee3ea;
  }
  .phone_text {
    font-weight: 600;
    font-size: 30px;
    line-height: 39px;
  }
  .logo_columned {
    display: none !important;
  }
  .logo_row {
    display: flex !important;
  }

  @media only screen and (min-height: 900px) {
    .prm_bg_img {
      height: 70vh !important;
    }
    @media only screen and (max-width: 431px) {
      .prm_bg_img {
        height: 75vh !important;
      }
    }
  }

  @media only screen and (min-height: 815px) {
    .it_works {
      padding-top: 10px;
    }
  }

  @media only screen and (min-width: 2285px) and (max-width: 2657px) {
    .card_top {
      width: 43% !important;
    }
    .property_form {
      transform: translate(0%, 50.5%);
    }
    @media only screen and (max-height: 1440px) {
      .first_section {
        min-height: 77vh;
      }
    }
  }

  @media only screen and (max-width: 2285px) {
    .property_form {
      transform: translate(0%, 27%);
    }
    .ep_section {
      .hp_img_section {
        text-align: center;
      }
    }
  }

  @media only screen and (min-width: 2090px) and (max-width: 2285px) {
    .card_top {
      width: 50% !important;
    }
  }

  @media only screen and (min-width: 1920px) and (max-width: 2090px) {
    .card_top {
      width: 55% !important;
    }
    @media only screen and (max-height: 1020px) {
      .it_works {
        padding-top: 215px;
      }
    }
  }

  @media only screen and (max-width: 1860px) {
    .property_form {
      transform: translate(0%, 22.5%);
    }
  }
  @media only screen and (min-width: 1520px) and (max-width: 1920px) {
    .card_top {
      width: 60% !important;
    }
    @media only screen and (max-height: 1200px) {
      .first_section {
        min-height: 80vh;
      }
    }
    @media only screen and (max-height: 1080px) {
      .first_section {
        min-height: 95vh;
      }
    }
    @media only screen and (max-height: 1051px) {
      .first_section {
        min-height: 85vh;
      }
    }
  }

  @media only screen and (max-width: 1520px) {
    .property_form {
      transform: translate(0%, 25.5%);
    }
    .ep_section {
      padding: 0 60px;
      .hp_img {
        padding: 0 0 20px 60px;
      }
    }
  }
  @media only screen and (max-width: 1440px) {
    @media only screen and (min-height: 900px) {
      .it_works {
        padding-top: 0px;
      }
    }
  }
  @media only screen and (max-width: 1400px) {
    .logos-width {
      width: 9%;
    }
  }
  @media only screen and (max-width: 1366px) {
    @media only screen and (min-height: 768px) {
      .it_works {
        padding-top: 110px;
      }
    }
  }
  @media only screen and (max-width: 1299px) {
    .prm-form {
      width: 90% !important;
    }
    // .markgroup_img {
    //   width: 45% !important;
    // }
    // .card_content {
    //   padding-top: 6%;
    //   padding-left: 8%;
    // }
    .property_form {
      transform: translate(0%, 25.5%);
    }
    .logos-width {
      width: 8%;
    }
  }
  @media only screen and (max-width: 1246px) {
    .first_section {
      min-height: 70vh;
    }
    .property_form {
      transform: translate(0%, 16%);
    }
    .ep_section {
      padding: 0 50px;
      .hp_img {
        padding: 0 0 20px 40px;
      }
    }
    .logos-width {
      width: 100%;
    }
    .logo_columned {
      display: flex !important;
      justify-content: flex-start;
    }
    .logo_row {
      display: none !important;
    }
    .alignments {
      justify-content: flex-start;
    }
    @media only screen and (min-height: 1281px) {
      .prm_bg_img {
        height: 54.5vh !important;
      }
    }
    @media only screen and (min-height: 1440px) {
      .first_section {
        min-height: 60vh;
      }
      .prm_bg_img {
        height: 52vh !important;
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    .main_class {
      margin: 0 auto;
      max-width: 1120px;
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
      margin-right: auto;
      margin-left: auto;
    }
  }
  @media only screen and (max-width: 1025px) {
    @media only screen and (min-height: 1366px) {
      .property_form {
        transform: translate(0%, 40%);
      }
    }
  }
  @media only screen and (max-width: 993px) {
    .main_container {
      position: relative;
      top: -95px;
      margin-bottom: 10px;
    }
    .header_texts {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center !important;
    }
    .property_form {
      position: relative;
      top: -180px;
      left: auto;
      transform: none;
    }
    .it_works {
      padding-top: 0px;
    }
    .sec1 {
      position: relative;
      top: -130px;
      text-align: left !important;
    }
    .paragraph_text {
      text-align: center;
    }
    .ep_section {
      .ep_header {
        font-size: 28px;
        line-height: 39px;
      }
      .ep_sub_header {
        font-size: 18px;
        line-height: 23px;
      }
      .ep_text {
        font-size: 18px;
        line-height: 23px;
      }
      .hp_img {
        padding: 50px 0 50px 40px;
      }
    }
    .logos-width {
      width: 100%;
    }
    .logo_columned {
      display: none !important;
    }
    .logo_row {
      display: flex !important;
      justify-content: center;
      max-width: none;
    }
    .alignments {
      justify-content: center;
    }
  }

  @media only screen and (min-width: 900px) and (max-width: 1152px) {
    .card_top {
      width: 95% !important;
    }
    @media only screen and (max-height: 1367px) {
      .it_works {
        padding-top: 0px;
      }
    }
    @media only screen and (max-height: 768px) {
      .it_works {
        padding-top: 150px;
      }
    }
    @media only screen and (max-height: 600px) {
      .it_works {
        padding-top: 250px;
      }
    }
  }

  @media only screen and (max-width: 899px) {
    // p {
    //   font-size: 16px !important;
    // }
    .middle-container {
      padding-top: 450px;
    }
    .card_top {
      margin-top: 150px;
      width: 70% !important;
      // transform: translate(-50%, -68%);
    }
    .card_content {
      padding: 40px 30px;
    }
    .button1 {
      padding: 12px 35px;
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
    }
    .d-sm-pt-0 {
      padding-top: 0px !important;
    }
  }
  @media only screen and (max-width: 850px) {
    .ep_section {
      .hp_img {
        padding: 120px 0 120px 40px;
      }
    }
    @media only screen and (min-height: 1180px) {
      .first_section {
        min-height: 70vh;
      }
      .prm_bg_img {
        height: 48vh !important;
      }
      .ep_section {
        .hp_img {
          padding: 90px 0 90px 75px;
        }
      }
      .property_form {
        top: -140px;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    // .prm_bg_img {
    //   height: 100vh !important;
    // }
    .prm-form {
      width: 90% !important;
    }
    .request-form {
      padding: 2rem !important;
    }
    // .card_content {
    //   padding-top: 50px;
    // }
    .ep_section {
      .hp_img_section {
        text-align: center;
      }
      .hp_img {
        padding: 20px 0px;
        // width: 85% !important;
        // height: 85% !important;
      }
    }
    .logo_row {
      display: none !important;
    }
    .logo_columned {
      display: flex !important;
      justify-content: center;
      max-width: none;
    }
    @media only screen and (max-height: 1366.98px) {
      .prm_bg_img {
        height: 55vh !important;
      }
    }
    @media only screen and (max-height: 1024.98px) {
      .prm_bg_img {
        height: 65vh !important;
      }
    }
    @media only screen and (max-height: 800.98px) {
      .prm_bg_img {
        height: 95vh !important;
      }
    }
  }

  @media only screen and (max-width: 696px) {
    .vector3 {
      display: none;
    }
  }

  @media only screen and (max-width: 600px) {
    .button2 {
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
      text-align: left;
      padding: 12px 35px;
    }
    .property-protected-container {
      .pp_title {
        font-size: 28px;
        line-height: 37px;
      }
      .pp_caption {
        font-size: 18px;
        line-height: 28px;
      }
      .pp_points {
        padding: 25px 50px 50px;
      }
      .protection_title {
        font-size: 16px;
        line-height: 21px;
      }
      .protection_desc {
        font-size: 16px;
        line-height: 23px;
      }
    }
    .ep_section {
      .ep_header {
        font-size: 20px;
        line-height: 37px;
      }
      .ep_sub_header {
        font-size: 14px;
        line-height: 23px;
      }
      .ep_text {
        font-size: 14px;
        line-height: 23px;
      }
      .hp_img {
        padding: 20px 0px;
        width: 100% !important;
        height: 100% !important;
      }
    }
  }

  @media only screen and (max-width: 520px) {
    .card_top {
      // margin-top: 65px;
      width: 90% !important;
    }
    .linear_bg {
      padding: 40px 0;
    }
  }

  @media only screen and (max-width: 430.98px) {
    @media only screen and (max-height: 945px) {
      .prm_bg_img {
        height: 77vh !important;
      }
    }
  }

  @media only screen and (max-width: 425px) {
    .main-text {
      font-size: 27px !important;
      line-height: 32px !important;
      margin: 5px 0;
    }
    .paragraph_text {
      font-size: 18px !important;
      line-height: 23px !important;
    }
    .request-form {
      padding: 1.5rem !important;
    }
    .header_texts {
      padding: 0 20px !important;
    }

    .vector2 {
      top: 45%;
    }
    .it_works {
      padding-top: 0px;
    }
    .property_form {
      top: -160px;
    }
  }

  @media only screen and (max-width: 376px) {
    .prm_bg_img {
      height: 100vh !important;
    }
  }
  @media only screen and (max-width: 350px) {
    .main-text {
      font-size: 25px !important;
      line-height: 30px !important;
      margin: 5px 0;
    }
    .paragraph_text {
      font-size: 17px !important;
      line-height: 21px !important;
    }
    .property-protected-container {
      .pp_points {
        padding: 25px 20px 50px;
      }
    }
  }
}
