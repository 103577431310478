.pricing_background_img {
  // background-image: url("../assets/home/image1.png");
  background-color: #3c4c58;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 5% 0 5%;
  z-index: 0;
  height: 90vh;
}

.reviews_wrapper {
  position: relative;
  margin-top: -200px;

  .card {
    padding: 40px;
    border: none;

    .ratings_wrapper {
      margin-bottom: 20px;
    }

    .comments_title {
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
      color: #0d120b;
      margin-bottom: 20px;
    }

    .comments {
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;
      color: #4c524a;
      margin-bottom: 30px;
    }

    .comments_author {
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      color: #0d120b;
    }
  }
}

.planCard {
  background-color: white;
  border-radius: 10px;
  padding: 50px !important;
  .card__1 {
    @media (max-width: 768.99px) {
      padding: 50px 40px !important;
    }
  }

  &.planCardTitle {
    background-color: transparent;

    .planFeature {
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      color: #0d120b;
    }
  }

  .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    text-transform: capitalize;
    color: #000000;
  }

  .subTitle {
    font-weight: 600;
    font-size: 30px;
    line-height: 39px;
    color: #0d120b;
    text-align: left;
  }

  .plan_description {
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
    color: #4c524a;
  }

  .seperator {
    border-color: #e8ecf0;
    margin: 40px 0;
    height: 1.5px;
  }

  .pricing_Wrapper {
    h4 {
      font-weight: 500;
      font-size: 40px;
      line-height: 52px;
      color: #0d120b;
    }
    .flex-container {
      display: flex;
      flex-wrap: wrap; /* Allows content to wrap */
      justify-content: center; /* Centers the content horizontally */
      align-items: center; /* Centers the content vertically */
      text-align: center; /* Centers the text */
    }
    .text_strike {
      // text-decoration: line-through red 5px;
      position: relative;
      display: inline-block;
    }
    .text_strike::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      border-top: 4px solid red;
      transform: translateY(-50%);
      width: 90%;
    }
    h5 {
      font-weight: 400;
      font-size: 30px;
      line-height: 39px;
      color: #0d120b;
    }

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 25px;
      color: #4c524a;
    }
  }

  .policy_text {
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: #4c524a;
    padding-left: 32px;
    position: relative;

    &::before {
      content: "";
      width: 24px;
      height: 24px;
      border-radius: 50px;
      background: url("../assets/home/currency_exchange.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 24px;
      position: absolute;
      left: 0;
    }
  }
}

.county_width {
  width: 355px;
}

.features_list {
  li {
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #4c524a;

    &::before {
      content: "";
      width: 20px;
      height: 20px;
      border-radius: 50px;
      background: url("../assets/icons/check.svg") rgba(21, 190, 83, 0.5);
      background-position: center;
      background-repeat: no-repeat;
      background-size: 12px;
      position: absolute;
      left: -30px;
    }
  }
}

.plans_wrapper .subTitle,
.theft_protection .subTitle,
.plans_wrapper .subTitle,
.faq_wrapper .subTitle {
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #4c524a;
}

.stand_out_wrapper1 {
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 50%;
    // background: linear-gradient(0deg, #0395ff, #0395ff),
    //   linear-gradient(266.55deg, #8fda4f -45.17%, #0395ff 109.5%);
    bottom: 0;
    z-index: -1;
  }

  .theft_protection {
    box-shadow: 0px 30px 70px -10px rgba(52, 75, 91, 0.29);
    border-radius: 10px;
    background-image: url("../assets/home/theft_pro_vector.svg");
    background-position: top center;
    background-repeat: no-repeat;
    padding: 100px;
  }
}

.theft_protection {
  .protection_title {
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: #0d120b;
    margin-bottom: 20px;
  }

  .protection_desc {
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: #4c524a;
  }
}

.resolution_wrapper {
  // background: linear-gradient(0deg, #0395ff, #0395ff),
  //   linear-gradient(266.55deg, #8fda4f -45.17%, #0395ff 109.5%);

  .subHeader {
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #ffffff;
    margin-bottom: 25px;
  }

  .H2 {
    font-weight: 600;
    font-size: 30px;
    line-height: 39px;
    color: #ffffff;
    margin-bottom: 50px;
  }

  .fraud_resolution {
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: #ffffff;
    margin-bottom: 35px;
  }
}

.faq_wrapper {
  background-color: #f6f9fc !important;
  .faqs_row {
    @media (max-width: 991.98px) {
      padding-top: 30px;
    }
  }
  .sub_header {
    font-size: 30px;
    font-weight: 600;
    line-height: 39px;
    text-align: center;
    @media (max-width: 991.98px) {
      font-size: 28px;
      line-height: 37px;
    }
    @media (max-width: 768.98px) {
      font-size: 26px;
      line-height: 35px;
    }
  }
  #supportFAQ_Wrapper {
    .accordion-item {
      border: 1px solid rgba(193, 204, 215, 1);
      background-color: transparent;
      border-width: 1px 0;
      border-color: #e8ecf0;
      padding: 20px 0;

      h2 {
        button {
          font-weight: 600;
          font-size: 18px;
          line-height: 23px;
          // text-transform: capitalize;
          color: #000000;
          background-color: transparent;
          border: none;
          box-shadow: none;
          outline: none;
          padding: 0 40px 0 0;
          position: relative;
        }
      }

      .accordion-body {
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        color: #4c524a;
        padding: 20px 0 0;
      }
      .formatted-text {
        white-space: pre-wrap; /* Preserves the formatting, including spaces and line breaks */
      }
    }
  }
}

button {
  &.accordion-button {
    &::after {
      position: absolute;
      right: 0;
      top: 50%;
      height: 20px;
      width: 20px;
      background-position: center;
      background-image: url("../assets/documents/add_circle.svg") !important;
      transform: translateY(-50%) rotate(45deg); // Initially rotated
      transition: transform 0.3s ease-in-out; // Smooth transition
    }
    // When accordion is expanded (not collapsed)
    &.collapsed::after {
      transform: translateY(-50%) rotate(0deg); // Rotate back to 0 degrees
    }

    &:not(.collapsed)::after {
      transform: translateY(-50%) rotate(45deg); // Rotate 45 degrees when collapsed
    }
  }
}

.highlighted {
  border-color: #15be53 !important;
  border: 2px solid #15be53 !important;
  box-shadow: 0px 30px 70px -10px rgba(52, 75, 91, 0.29);
}

.switch_toggle {
  .MuiSwitch-root .MuiButtonBase-root {
    color: #15be53;
  }
}

.css-1xvpzln-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked
  + .MuiSwitch-track {
  border-style: groove;
}

.activation_text {
  color: #0395ff !important;
  font-weight: 600 !important;
}
.spacing_pp {
  box-shadow: 0px 30px 70px -10px rgba(52, 75, 91, 0.29);
  border: 1px solid #dee3ea;
  margin: 0 220px;
}
.spacing_full_pp {
  box-shadow: 0px 30px 70px -10px rgba(52, 75, 91, 0.29);
  //border: 1px solid #dee3ea;
  margin: 30px 0px;
}
.plan_title {
  font-size: 1.25rem !important;
  line-height: 1.6 !important;
}
.spacing_icons {
  margin: 15px 200px;
}
@media only screen and (min-width: 320px) and (max-width: 399.98px) {
  .H1 {
    font-size: 29px;
    line-height: 35px;
    margin-top: 23px;
  }
  .reviews_wrapper {
    margin-top: -100px;
  }
  .pricing_paragraph_texts {
    font-size: 16px !important;
    line-height: 22px !important;
    text-align: center;
  }
}

@media only screen and (min-width: 1199.98px) and (max-width: 1499.98px) {
  .reviews_wrapper {
    margin-top: -190px;
  }
  .pricing_paragraph_texts {
    font-size: 18px !important;
    line-height: 24px !important;
    text-align: center;
  }
}
@media only screen and (min-width: 1199.98px) {
  .background_img11 {
    height: 100vh;
  }
  .reviews_wrapper {
    margin-top: -100px;
  }
  .pricing_paragraph_texts {
    font-size: 18px;
    line-height: 24px;
    text-align: center;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  .background_img11 {
    height: 100vh;
  }
  .reviews_wrapper {
    margin-top: -120px;
  }
  .planCard {
    padding: 30px !important;
  }
}

@media only screen and (max-width: 991.98px) {
  .background_img11 {
    padding: 35% 0;
  }

  .reviews_wrapper {
    margin-top: -120px;
  }

  .equal-height-JlocK {
    overflow: visible !important;
  }

  .planCompareWrapper {
    .planCard {
      .planFeature {
        padding: 60px 30px;

        span {
          position: absolute;
          top: 0;
          left: 0;
          border-radius: 5px 0 5px 0;
          background-color: #121212;
          color: #ffffff;
          padding: 3px 10px;
          font-weight: 400;
          font-size: 16px;
          line-height: 25px;
          letter-spacing: 0.03em;
        }
      }

      &.highlighted {
        .planFeature {
          span {
            background-color: #15be53;
          }
        }
      }
    }
  }

  .faq_wrapper {
    #supportFAQ_Wrapper {
      .accordion-item {
        h2 {
          button {
            font-size: 16px;
            line-height: 21px;
          }
        }

        .accordion-body {
          font-size: 16px;
          line-height: 25px;
        }
      }
    }
  }
  .spacing_pp {
    box-shadow: 0px 30px 70px -10px rgba(52, 75, 91, 0.29);
    border: 1px solid #dee3ea;

    margin: 10px;
  }
  .spacing_icons {
    margin: 15px 10px;
  }
}
@media only screen and (max-width: 899.98px) {
  .county_width {
    width: auto;
  }
}
@media only screen and (max-width: 767.98px) {
  .background_img11 {
    padding: 25% 0 45%;
  }

  .reviews_wrapper {
    margin-top: -100px;
  }
  .pricing_paragraph_texts {
    font-size: 18px;
    line-height: 27px;
    text-align: center;
  }
  .stand_out_wrapper {
    .theft_protection {
      padding: 15px;
    }
  }

  .signin_button {
    padding: 10px 30px !important;
  }

  .planCard {
    padding: 25px !important;
  }

  .equal-height-JlocK {
    height: auto !important;
  }

  .plans_wrapper .subTitle,
  .theft_protection .subTitle,
  .plans_wrapper .subTitle,
  .faq_wrapper .subTitle {
    font-size: 18px;
    line-height: 28px;
  }
  .spacing_pp {
    box-shadow: 0px 30px 70px -10px rgba(52, 75, 91, 0.29);
    border: 1px solid #dee3ea;

    margin: 10px;
  }
  .spacing_icons {
    margin: 15px 10px;
  }

  .home-content {
    .container-fluid {
      //  margin-top: 30px;
    }
  }
}
@media only screen and (min-height: 1060px) and (max-height: 1368px) {
  .pricing_background_img {
    height: 55vh;
  }
}
@media only screen and (min-height: 800px) and (max-height: 1060px) {
  .pricing_background_img {
    height: 70vh;
  }
  @media only screen and (max-width: 550.98px) {
    .pricing_background_img {
      height: 80vh;
    }
  }
}

.investor_pricing {
  .pricing_background_img {
    background-image: url("../assets/investor/image.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 5% 0 5%;
    height: 105vh;
    z-index: 0;

    @media (min-width: 1400.98px) {
      @media (min-height: 899.99px) {
        height: 77vh;
      }
      @media (min-height: 1080.99px) {
        height: 60vh;
      }
    }

    @media (max-width: 1280.98px) {
      @media (max-height: 900.98px) {
        height: 77vh;
      }
    }

    @media (max-width: 1024.98px) {
      @media (min-height: 1400.98px) {
        height: 45vh;
      }
      @media (max-height: 1370.98px) {
        height: 50vh;
      }
      @media (max-height: 900.98px) {
        height: 105vh;
      }
      @media (max-height: 600.98px) {
        height: 110vh;
      }
    }

    @media (max-width: 820.98px) {
      @media (max-height: 1180.98px) {
        height: 60vh;
      }
      @media (max-height: 1024.98px) {
        height: 65vh;
      }
      @media (max-height: 767.98px) {
        height: 80vh;
      }
      @media (max-height: 600.98px) {
        height: 105vh;
      }
      @media (max-height: 361.98px) {
        height: 180vh;
      }
    }

    @media (max-width: 480.98px) {
      @media (max-height: 950.98px) {
        height: 85vh;
      }
    }

    @media (max-width: 430.98px) {
      @media (max-height: 950.98px) {
        height: 70vh;
      }
      @media (max-height: 750.98px) {
        height: 80vh;
      }
      @media (max-height: 667.98px) {
        height: 90vh;
      }
    }

    @media (max-width: 375.98px) {
      @media (max-height: 767.98px) {
        height: 85vh;
      }
      @media (max-height: 660.98px) {
        height: 110vh;
      }
    }
  }

  .top_row {
    padding: 100px 30px 0;

    @media (max-width: 960.98px) {
      padding: 60px 20px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    @media (max-width: 767.98px) {
      padding: 60px 0 0;
    }
    @media (max-width: 575.98px) {
      padding: 60px 10px 0;
    }
  }
  .title_texts {
    font-weight: 600;
    font-size: 48px;
    line-height: 48px;
    text-align: start;
    @media (max-width: 1200.98px) {
      font-size: 44px;
      line-height: 44px;
    }
    @media (max-width: 1070.98px) {
      font-size: 40px;
      line-height: 40px;
    }
    @media (max-width: 960.98px) {
      font-size: 44px;
      line-height: 46px;
    }
    @media (max-width: 575.98px) {
      font-size: 40px;
      line-height: 40px;
    }
    @media (max-width: 495.98px) {
      font-size: 34px;
    }
    @media (max-width: 425.98px) {
      font-size: 28px;
      line-height: 35px;
    }
  }
  .card__1 {
    @media (max-width: 854.99px) {
      padding: 50px 40px 20px !important;
      .seperator {
        margin: 55px 0 40px;
      }
    }
  }
  .card__2 {
    @media (max-width: 854.99px) {
      padding: 50px 30px 70px !important;
    }
  }
  .inv_title_yearly {
    .seperator {
      @media (max-width: 842.98px) {
        margin: 60px 0 40px;
      }
      @media (max-width: 575.98px) {
        margin: 40px 0;
      }
    }
  }
  .multi_properties {
    .div__1 {
      width: 95%;
      @media (max-width: 842.99px) {
        width: 80%;
      }
      @media (max-width: 575.98px) {
        width: 90%;
      }
    }

    .div__2 {
      @media (max-width: 842.99px) {
        position: relative;
        top: -20px;
      }
      @media (max-width: 575.98px) {
        top: 0px;
      }
      @media (max-width: 500.98px) {
        top: -20px;
      }
    }
    .seperator {
      @media (max-width: 842.98px) {
        margin: 20px 0 40px;
      }
      @media (max-width: 574.98px) {
        margin: 40px 0;
      }
    }
  }
  .title {
    @media (max-width: 991.98px) {
      font-size: 16px !important;
      line-height: 20px !important;
    }
  }
  .subTitle {
    @media (max-width: 991.98px) {
      font-size: 26px !important;
      line-height: 35px !important;
    }

    @media (max-width: 616.99px) {
      font-size: 22px !important;
      line-height: 30px !important;
    }
    @media (max-width: 575.98px) {
      font-size: 30px !important;
      line-height: 39px !important;
    }
    @media (max-width: 375.98px) {
      font-size: 26px !important;
      line-height: 34px !important;
    }
  }
  .subHeader_texts {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    text-align: start;
    padding-bottom: 20px;
    margin-bottom: 0;
    @media (max-width: 1200.98px) {
      font-size: 18px;
      line-height: 28px;
      padding-bottom: 20px;
    }
    @media (max-width: 1065.98px) {
      line-height: 26px;
    }
    @media (max-width: 575.98px) {
      font-size: 16px;
      line-height: 24px;
    }
    @media (max-width: 475.98px) {
      font-size: 15px;
      line-height: 20px;
    }
  }
  .header_sections {
    width: 580px;
    padding-right: 30px;
    @media (max-width: 985.98px) {
      padding-right: 10px;
    }
    @media (max-width: 960.98px) {
      padding-right: 0px;
      width: 85%;
      .p__1 {
        margin-top: 10px;
      }
    }
    @media (max-width: 575.98px) {
      width: 95%;
    }
  }
  .reviews_wrapper {
    .slick-list {
      .slick-track {
        @media (min-width: 1020px) {
          width: auto !important;
        }
        display: flex;
        justify-content: center;
      }
      .slick-slide {
        width: 580px !important;
        @media (max-width: 991.98px) {
          width: 420px !important;
        }
        @media (max-width: 800.98px) {
          width: 380px !important;
        }
      }
    }
    @media only screen and (min-width: 1023.98px) {
      margin-top: -120px;
      @media (min-height: 767.98px) {
        margin-top: -280px;
        @media only screen and (min-width: 1300.98px) {
          margin-top: -260px;
        }
      }
      @media (min-height: 799.98px) {
        margin-top: -120px;
        @media only screen and (min-width: 1500.98px) {
          margin-top: -260px;
        }
        @media only screen and (min-width: 1900.98px) {
          margin-top: -130px;
        }
      }
    }
    @media (max-width: 767.98px) {
      margin-top: -130px;
    }
    @media (max-width: 415.98px) {
      margin-top: -80px;
    }
  }
  .inv_yearly {
    font-family: IBM Plex Sans !important;

    h4 {
      font-weight: 600;
      font-size: 40px;
      line-height: 52px;
      text-align: center;
      color: #0155b7;
      @media (max-width: 650.98px) {
        font-size: 37px;
        line-height: 50px;
      }
      @media (max-width: 575.98px) {
        font-size: 40px;
        line-height: 52px;
      }
      @media (max-width: 412.98px) {
        font-size: 37px;
        line-height: 50px;
      }
    }
    h5 {
      font-weight: 500;
      font-size: 30px;
      line-height: 39px;
      text-align: center;
      @media (max-width: 650.98px) {
        font-size: 27px;
        line-height: 35px;
      }
      @media (max-width: 575.98px) {
        font-size: 30px;
        line-height: 39px;
      }
      @media (max-width: 412.98px) {
        font-size: 27px;
        line-height: 35px;
      }
    }
    .features_list {
      padding-left: 0;
      li {
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        display: flex;
        text-align: start;
        div {
          align-items: baseline !important;
        }
        span {
          padding-left: 10px;
        }
        img {
          position: relative;
          top: 5px;
          width: 20px;
          height: 20px;
        }
        @media (max-width: 854.98px) {
          font-size: 14px;
          line-height: 22px;
        }
        @media (max-width: 575.98px) {
          font-size: 18px;
          line-height: 25px;
        }
        @media (max-width: 412.98px) {
          font-size: 16px;
          line-height: 22px;
        }

        &::before {
          content: "";
          display: none;
        }
      }
    }
  }
  .back_to_properties {
    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      border-radius: 29px;
      border-width: 1px;
      padding: 15px 30px;
      gap: 10px;
      background: #ffffff;
      border: 1px solid #04152126;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #0155b7;
      text-transform: uppercase;
      img {
        color: #0155b7;
      }
      @media (max-width: 805.98px) {
        padding: 15px;
      }
      @media (max-width: 759.98px) {
        font-size: 12px;
        line-height: 16px;
        padding: 15px;
      }
      @media (max-width: 575.98px) {
        font-size: 16px;
        line-height: 20px;
        padding: 15px 30px;
      }
      @media (max-width: 412.98px) {
        font-size: 12px;
        line-height: 16px;
        padding: 15px;
      }
    }
  }
  .itlalic_text {
    font-style: italic;
  }
  .pricing_Wrapper {
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    p {
      @media (max-width: 650.98px) {
        font-size: 14px;
        line-height: 22px;
      }
    }
    h6 {
      font-weight: 500;
      font-size: 20px;
      line-height: 26px;
      text-align: center;
      margin-bottom: 40px;
      @media (max-width: 665.98px) {
        font-size: 18px;
        line-height: 22px;
      }
      @media (max-width: 575.98px) {
        font-size: 20px;
        line-height: 25px;
      }
      @media (max-width: 412.98px) {
        font-size: 18px;
        line-height: 22px;
      }
    }
    .properties_btn {
      min-width: 143px;
      background: #0395ff1a;
      border-radius: 40px;
      padding: 6px 15px;
      color: #0155b7;
      font-weight: 500;
      font-size: 16px;
      line-height: 25px;
      text-align: center;
      margin-bottom: 20px;
      @media (max-width: 650.98px) {
        font-size: 14px;
        line-height: 22px;
      }
      @media (max-width: 575.98px) {
        font-size: 16px;
        line-height: 25px;
      }
      @media (max-width: 412.98px) {
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
  .additional_discount {
    border-radius: 5px;
    border-width: 1px;
    background: #e6f4ff78;
    border: 1px solid #e6f4ff;
    padding: 35px 65px;
    @media (max-width: 983.98px) {
      padding: 35px;
    }
    @media (max-width: 854.98px) {
      padding: 27px;
    }
    @media (max-width: 767.98px) {
      margin-top: 50px;
    }
    @media (max-width: 735.98px) {
      padding: 25px;
    }
    @media (max-width: 599.98px) {
      margin-top: 0px;
    }
    @media (max-width: 575.98px) {
      padding: 35px 65px;
    }
    @media (max-width: 412.98px) {
      padding: 35px;
    }
    p {
      font-weight: 500;
      font-style: italic;
      font-size: 20px;
      line-height: 25px;
      text-align: center;
      color: #0155b7;
      padding-top: 10px;
      @media (max-width: 665.98px) {
        font-size: 18px;
        line-height: 22px;
      }
      @media (max-width: 575.98px) {
        font-size: 20px;
        line-height: 25px;
      }
      @media (max-width: 412.98px) {
        font-size: 18px;
        line-height: 22px;
      }
    }
  }
}
