.dialog-header {
  background-color: #ffffff;

  .closeBtn {
    position: absolute;
    top: 0;
    right: 0;

    button {
      min-width: auto;
    }
  }
}

.dialog-footer {
  background-color: #ffffff;

  button {
    &.addPartiesBtn {
      background: #0395ff;
      border-radius: 29px;
      box-shadow: none;
      padding: 15px 40px;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      text-transform: uppercase;
      color: #ffffff;
    }

    &.stepBtn {
      background: #15be53;
      border-radius: 29px;
      box-shadow: none;
      padding: 15px 40px;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #ffffff;
    }
  }
}

.docReport {
  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #0d120b;

    .docName {
      font-weight: 400;
    }
  }
}

.party_TextFields {
  width: 100%;

  .MuiInputBase-root {
    background: #f6f9fc;
  }

  fieldset {
    border: 1px solid rgba(52, 75, 91, 0.1) !important;
  }

  .Mui-error {
    fieldset {
      border: 1px solid #d32f2f !important;
    }
  }

  label {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #4c524a;
  }

  label {
    &.Mui-error {
      color: #d32f2f !important;
    }
  }
}

.MuiDialogContent-root {
  display: flex;
  flex-flow: column;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto !important;
  position: relative;
  background-color: #f6f9fc;
  // overflow-x: hidden;
  word-break: break-word;

  .step4 {
    .MuiDialogContent-root {
      border: none !important;
    }
  }

  .successStep {
    .title {
      font-weight: 600;
      font-size: 20px;
      line-height: 26px;
      text-align: center;
      color: #0d120b;
    }

    .description {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #4c524a;
    }

    .downloadDocumentLink {
      text-decoration: none;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #0395ff;
      text-align: center;
      display: block;

      &:hover {
        color: #0395ff;
      }
    }

    .downloadFileBtn {
      background: #15be53;
      border-radius: 29px;
      box-shadow: none;
      padding: 15px 40px;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #ffffff;
    }
  }

  .paymentStep {
    .paymentMode {
      width: 100%;

      p {
        flex: 1 0 auto;

        .mode {
          font-weight: 600;
          font-size: 18px;
          line-height: 23px;
          color: #0d120b;
        }

        .value {
          font-weight: 600;
          font-size: 18px;
          line-height: 23px;
          color: #0395ff;

          &::before {
            content: "";
            width: 1px;
            height: 46px;
            background: #dfe5ec;
            position: absolute;
            left: -15px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }

    .card-paymentMode {
      font-size: 10px !important;

      span {
        display: none !important;
      }
    }
  }
}

.dialogWrapper {
  &.step2 {
    .dialog-header {
      display: none !important;
    }

    .dialog-footer {
      display: none !important;
    }
  }
}

.addparty {
  text-decoration: none;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  color: #0395ff;

  &:hover {
    color: #0395ff;
  }
}

.MuiMobileStepper-dots {
  display: none !important;
}

.credit-billing-checkbox {
  &:hover {
    background-color: transparent !important;
  }
}

.card-parent {
  .MuiFormControlLabel-label {
    width: 100%;
  }
}

// media query for mobile screen < 768px
@media only screen and (max-width: 768px) {
  .dialog-header {
    .MuiDialogTitle-root {
      font-size: 18px !important;
    }

    p {
      font-size: 13px;
    }
  }

  .MuiDialogContent-root {
    padding: 20px !important;

    .successStep {
      .downloadFileBtn {
        padding: 15px 35px;
      }
    }

    .paymentStep {
      .paymentMode {
        p {
          .mode {
            font-size: 16px;
          }

          .value {
            font-size: 16px;
            position: absolute !important;
            right: 5%;

            &::before {
              left: -10px;
            }
          }

          img {
            position: absolute;
            right: 5%;
            height: 15%;
            width: 40%;
          }
        }
      }
    }

    .step2 {
      .MuiPaper-root {
        padding: 10px;
      }
    }

    .step3 {
      .MuiPaper-root {
        padding: 20px;
      }
    }
  }

  .dialog-footer {
    padding: 30px 20px !important;

    button {
      &.stepBtn {
        padding: 12px 20px;
      }
    }
  }
}

@media only screen and (max-width: 391px) {
  .dialog-footer {
    button {
      &.pd-13 {
        padding: 13px !important;
      }

      &.fs-13 {
        font-size: 13px !important;
      }
    }

    button.addPartiesBtn {
      font-size: 13px !important;
      padding: 13px 30px !important;
    }
  }
}
