.createAcc_button {
  background: #15be53 !important;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px 40px;
  font-weight: 600;
  gap: 10px;
  border-radius: 29px !important;
  color: white;
  border: none;
}

.agenttextField {
  background: #f6f9fc;
}
.uploadFiles {
  background-color: white;
  border: 1px dashed;
  display: flex;
  flex-direction: column;
  align-items: center;
}
