.county-recorder-content {
  font-family: "IBM Plex Sans" !important;
  padding-bottom: 200px;
  p {
    color: #4c524a;
  }
  .first_section_div {
    padding-bottom: 95px;
  }
  .cr_bg_img {
    background-image: url("../../assets/county-recorder.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 10% 0 5%;
    z-index: 0;
    // height: 90vh !important;
    height: 80vh !important;
  }
  .header_texts {
    padding: 0 30px;
  }
  .first-section {
    // padding: 0px !important;
    padding-top: 0rem !important;
    // min-height: 80vh;
    // max-height: 80vh;
    // max-height: min-content !important;

    .main-text {
      font-size: 30px;
      line-height: 40px;
    }
  }
  .county_block {
    position: absolute;
    width: 82%;
    transform: translate(11%, -15%);
    background: #f6f9fc;
    box-shadow: 0px 30px 70px -10px #344b5b4a;
    border-radius: 10px;
  }
  .county_block1 {
    max-width: 1560px;
    width: 82%;
    background: #f6f9fc;
    box-shadow: 0px 30px 70px -10px #344b5b4a;
    border-radius: 10px;
    margin: 0 auto;
    margin-top: -175px;
  }
  .steps_wrapper {
    margin-top: 5rem;
    padding-bottom: 2rem;
    .steps {
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      color: #0d120b;
      background: rgba(21, 190, 83, 0.3);
      width: 46px;
      height: 46px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .info_blocks {
      padding: 30px;
      border-radius: 10px;
      border: 1px solid #dee3ea;
      height: 260px;
      background: white;
    }
    .header_text {
      font-size: 18px;
    }
    .paragraph_text {
      font-size: 18px;
    }
  }

  .county_person_img {
    display: flex;
    align-items: center;
    margin: 0px;
    padding: 65px 0px 20px 20px;
    img {
      width: 300px;
      height: 380px;
    }
  }
  .county_content {
    padding: 65px 35px 40px;
    text-align: left;
  }
  .content_padding {
    padding: 65px 135px 40px;
    text-align: center;
  }
  .p-35 {
    padding-right: 35px;
  }
  .county_logo {
    width: 111px;
    height: 111px;
  }
  .county_seal {
    width: 101px;
    // height: 149px;
  }
  .county_title {
    font-size: 30px;
    font-weight: 600;
    line-height: 39px;
    letter-spacing: 0em;
  }
  .county_captions {
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    color: #4c524a;
  }
  .theft_section {
    padding-bottom: 200px;
    margin: 0;
  }
  .theft_content {
    padding: 65px;
  }
  .theft_captions {
    padding-top: 20px;
    font-size: 20px;
    font-weight: 300;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
  }
  .county_email {
    color: #0395ff;
  }
  .container_padding {
    padding: 0px 80px;
  }
  .equity_theft {
    padding: 900px 80px 0px;
    .equity_theft_title {
      font-size: 30px;
      font-weight: 600;
      line-height: 39px;
      letter-spacing: 0em;
      text-align: center;
    }
    .equity_theft_caption {
      font-size: 18px;
      font-weight: 400;
      line-height: 25px;
      letter-spacing: 0em;
      text-align: center;
      padding: 0 18%;
    }
    .info_blocks {
      padding: 23px;
      // height: 260px;
      text-align: center;
    }
    .info_blocks_title {
      font-size: 18px;
      font-weight: 600;
      line-height: 23px;
    }
    .info_blocks_subtitle {
      font-size: 18px;
      font-weight: 400;
      line-height: 25px;
      letter-spacing: 0em;
    }
  }
  .how_its_works {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .how_its_works_title {
      font-size: 30px;
      font-weight: 600;
      line-height: 39px;
      letter-spacing: 0em;
      text-align: center;
    }
    .how_its_works_caption {
      font-size: 20px;
      font-weight: 300;
      line-height: 30px;
      letter-spacing: 0em;
      text-align: center;
    }
    .property_form {
      background: white;
      margin-top: 3rem;
      padding-bottom: 3rem;
      box-shadow: 0px 30px 70px -10px #344b5b4a;
      border-radius: 10px;
      width: 75%;
      @media (max-width: 991.98px) {
        width: 85%;
      }
      @media (max-width: 768.98px) {
        width: 95%;
      }
    }
    .property_form_padding {
      padding: 3rem 0 !important;
    }
    .button_width {
      width: 100%;
    }
  }

  .button1 {
    width: 75%;
    padding: 15px 40px 15px 40px;
    border-radius: 29px;
    gap: 10px;
    background: #15be53;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.05em;
    text-align: center;
    color: #0d120b;
    &:not(.disable):hover {
      background: #15be53;
    }
  }
  .button2 {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.05em;
    text-align: center;
    padding: 15px 40px 15px 40px;
    border-radius: 29px;
    gap: 10px;
    color: #15be53;
    background: #ffffff;
    &:not(.disable):hover {
      background: #ffffff;
    }
  }

  .stay_loop_img {
    display: flex;
    align-items: center;
    margin: 0px;
    padding: 75px 0px 0px 20px;
  }
  .stay_loop_content {
    padding: 65px 15px 0px;
  }
  .county_search {
    padding-bottom: 300px;
  }
  .county_search_content {
    padding: 65px 50px 0px 65px;
  }
  .county_search_img {
    display: flex;
    align-items: center;
    margin: 0px;
    padding: 75px 20px 0px 0px;
  }
  .county_search_captions {
    font-size: 20px;
    font-weight: 300;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
  }
  .card_bottom {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    width: 75%;
  }
  .linear_bg {
    background: linear-gradient(266.55deg, #8fda4f -45.17%, #0155b7 109.5%);

    box-shadow: 0px 30px 70px -10px rgba(52, 75, 91, 0.29);
    border-radius: 10px !important;
    border: none !important;
    outline: none !important;
    color: #ffffff;
    padding: 70px 0;
  }
  .privateGuardList {
    font-weight: 600;
    font-size: 30px;
    line-height: 39px;
    margin: 19px;
  }

  .vector {
    position: absolute;
    top: 5%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .pana {
    position: absolute;
    top: 5%;
    left: 50%;
    transform: translate(-50%, -55%);
  }

  .pana2 {
    display: none;
    position: absolute;
    top: 5%;
    left: 50%;
    transform: translate(-50%, -55%);
  }
  .vector1 {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -55%);
  }
  .vector2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -55%);
  }
  .vector3 {
    position: absolute;
    top: 54%;
    left: 50%;
    transform: translate(-50%, -55%);
  }
  .vector4 {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -55%);
  }
  .vector5 {
    display: none;
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -55%);
  }
  .vector6 {
    display: none;
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -55%);
  }
  .vector7 {
    display: none;
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -55%);
  }

  @media only screen and (max-height: 768px) {
    .county_search {
      padding-bottom: 25px;
    }
    @media only screen and (min-width: 1366px) {
      .county_search {
        padding-bottom: 300px;
      }
    }
  }
  @media only screen and (min-width: 1366px) {
    @media only screen and (min-height: 768px) {
      .cr_bg_img {
        height: 70vh !important;
      }
      .equity_theft {
        padding: 830px 80px 0px;
      }
    }
    @media only screen and (min-height: 900px) {
      .cr_bg_img {
        height: 55vh !important;
      }
      .equity_theft {
        padding: 600px 80px 0px;
      }
    }
    @media only screen and (min-height: 1050px) {
      .equity_theft {
        padding: 475px 80px 0px;
      }
    }
    @media only screen and (min-height: 1200px) {
      .cr_bg_img {
        height: 50vh !important;
      }
      .equity_theft {
        padding: 375px 80px 0px;
      }
    }
    @media only screen and (min-height: 1440px) {
      .cr_bg_img {
        height: 45vh !important;
      }
      .equity_theft {
        padding: 115px 80px 0px;
      }
    }
  }

  @media only screen and (max-width: 1319px) {
    .steps_wrapper {
      .info_blocks {
        height: 350px;
      }
    }
    .county_search_content {
      padding: 65px 30px 0px 30px;
    }
    .button1 {
      width: 100% !important;
    }

    @media only screen and (min-height: 800px) {
      .cr_bg_img {
        height: 65vh !important;
      }
      .equity_theft {
        padding: 775px 80px 0px;
      }
    }
  }
  @media only screen and (max-width: 1100px) {
    .equity_theft {
      padding: 1050px 80px 0px;
      .equity_theft_caption {
        padding: 0 14%;
      }
    }
    .card_bottom {
      width: 100%;
    }
    .county_search_section {
      padding-bottom: 200px;
    }
    @media only screen and (max-height: 1441px) and (min-height: 1280px) {
      .cr_bg_img {
        height: 35vh !important;
      }
      .county_block {
        transform: translate(11%, -9%);
      }
      .alert_section {
        padding: 0;
      }
      .equity_theft {
        padding: 850px 15px 0px;
      }
      .first_section_div {
        min-height: 85vh !important;
      }
      .county_search {
        padding-bottom: 10px;
      }
    }
    @media only screen and (max-height: 768px) {
      .cr_bg_img {
        height: 75vh !important;
      }
      .equity_theft {
        padding: 975px 80px 0px;
      }
    }
    @media only screen and (max-height: 600px) {
      .cr_bg_img {
        height: 85vh !important;
      }
      .equity_theft {
        padding: 1050px 80px 0px;
      }
    }
    @media only screen and (min-height: 1365px) {
      .equity_theft {
        padding: 550px 15px 0px;
      }
      .county_search {
        padding-bottom: 0px;
      }
    }
  }

  @media only screen and (max-width: 991px) {
    .equity_theft {
      padding: 1350px 80px 0px;
      .equity_theft_caption {
        padding: 0 12%;
      }
    }
    .county_block {
      transform: translate(11%, -8%);
    }
    .county_content {
      padding: 5px 35px 40px;
      text-align: center;
    }
    .content_padding {
      padding: 40px 35px;
      text-align: center;
    }

    .container_padding {
      padding: 0px 20px;
    }
    .county_search {
      padding-bottom: 50px;
    }
    .county_search_section {
      margin-bottom: 100px;
    }
    .county_person_img {
      padding: 20px;
    }
    .theft_content {
      padding: 50px 100px 30px;
      text-align: center;
    }
    .theft_section {
      padding-bottom: 150px;
    }
    .steps_wrapper {
      .info_blocks {
        height: 260px;
      }
    }
    .stay_loop_content {
      padding: 65px 15px 0;
      text-align: center;
      .county_captions {
        padding: 0 100px;
      }
      .stay_in_form {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .county_search_content {
      text-align: center;
    }
    .county_search_captions {
      font-size: 18px;
      line-height: 28px;
      text-align: center;
    }
    @media only screen and (min-height: 800px) {
      .equity_theft {
        padding: 775px 80px 0px;
      }
    }
  }

  @media only screen and (max-width: 900px) {
    .how_its_works {
      .button_width {
        width: 80%;
      }
    }
  }

  @media only screen and (max-width: 850px) {
    .equity_theft {
      padding: 1400px 15px 0px;

      .equity_theft_caption {
        padding: 0 8%;
      }
    }

    .vector4 {
      display: none;
    }
    .privateGuardList {
      margin: 15px;
    }
    .steps_wrapper {
      .info_blocks {
        height: 300px;
      }
    }
    @media only screen and (max-height: 1281px) {
      // .county_block {
      //   transform: translate(11%, -18%);
      // }
      .equity_theft {
        padding: 900px 15px 0px;
      }
    }
    @media only screen and (min-height: 1180px) {
      .cr_bg_img {
        height: 40vh !important;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .prm-form {
      width: 90% !important;
    }
    .request-form {
      padding: 2rem !important;
    }
    // .card_content {
    //   padding-top: 50px;
    // }
    .privateGuardList {
      font-size: 20px;
    }
    // .county_block {
    //   transform: translate(11%, -5%);
    // }
    // .equity_theft {
    //   padding: 500px 15px 0px;
    // }
    .county_block1 {
      width: 90%;
      margin-top: -100px;
    }
    @media only screen and (max-height: 1367px) {
      .cr_bg_img {
        height: 40vh !important;
      }
      .equity_theft {
        padding: 900px 15px 0px;
      }
    }
    @media only screen and (max-height: 1280px) {
      .cr_bg_img {
        height: 60vh !important;
      }

      .equity_theft {
        padding: 1050px 15px 0px;
      }
    }
    @media only screen and (max-height: 720px) {
      .cr_bg_img {
        height: 75vh !important;
      }
    }
  }

  @media only screen and (max-width: 696px) {
    .equity_theft {
      padding: 1350px 15px 0px;
    }
    // .county_block {
    //   transform: translate(11%, -14%);
    // }
    .vector3 {
      display: none;
    }
    .stay_loop_content {
      .county_captions {
        padding: 0 30px;
      }
    }
    @media only screen and (max-height: 1025px) {
      .equity_theft {
        padding: 1150px 15px 0px;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .first-section {
      .main-text {
        font-size: 26px;
        line-height: 30px;
      }
    }
    .equity_theft {
      padding: 1050px 15px 0px;
    }
    .county_title {
      font-size: 28px;
      line-height: 35px;
    }
    .county_captions {
      font-size: 16px;
      line-height: 20px;
    }
    .paragraph_text {
      font-size: 18px;
      line-height: 24px;
    }
    .equity_theft {
      .equity_theft_title {
        font-size: 28px;
        line-height: 35px;
      }
      .equity_theft_caption {
        font-size: 16px;
        line-height: 23px;
        padding: 0 4%;
      }
      .info_blocks_title {
        font-size: 16px;
        line-height: 21px;
      }
      .info_blocks_subtitle {
        font-size: 16px;
        line-height: 23px;
      }
    }
    .how_its_works {
      .how_its_works_title {
        font-size: 28px;
        line-height: 35px;
      }
      .how_its_works_caption {
        font-size: 18px;
        line-height: 28px;
      }
    }
    .theft_captions {
      font-size: 18px;
      line-height: 28px;
    }
    .privateGuardList {
      margin: 10px 2px;
      line-height: 30px;
    }
    .county_content {
      padding: 5px 10px 25px;
    }
    .content_padding {
      padding: 40px 10px 25px;
    }
    .theft_content {
      padding: 10px;
    }

    .pana,
    .vector {
      display: none;
    }
    .pana2 {
      display: block;
    }
    .button2 {
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
      // text-align: left;
      padding: 12px 35px;
    }
    @media only screen and (max-height: 1025px) {
      .equity_theft {
        padding: 900px 15px 0px;
      }
    }
    @media only screen and (max-height: 800px) {
      .equity_theft {
        padding: 1000px 15px 0px;
      }
    }
    @media only screen and (max-height: 750px) {
      .equity_theft {
        padding: 1000px 15px 0px;
      }
    }
    @media only screen and (max-height: 700px) {
      .equity_theft {
        padding: 1100px 15px 0px;
      }
    }
    @media only screen and (max-height: 500px) {
      .equity_theft {
        padding: 1150px 15px 0px;
      }
    }
  }

  @media only screen and (max-width: 575px) {
    .steps_wrapper {
      .info_blocks {
        padding: 20px;
        height: 185px;
      }
    }
    @media only screen and (max-height: 896px) {
      .equity_theft {
        padding: 800px 15px 0px;
      }
    }
    @media only screen and (max-height: 800px) {
      .equity_theft {
        padding: 900px 15px 0px;
      }
    }
  }

  @media only screen and (max-width: 520px) {
    .first-section {
      .main-text {
        font-size: 24px;
        line-height: 33px;
      }
    }
    .paragraph_text {
      font-size: 14px;
      line-height: 20px;
    }
    .county_title {
      font-size: 26px;
      line-height: 33px;
    }
    .county_captions {
      font-size: 14px;
      line-height: 16px;
    }
    .equity_theft {
      .equity_theft_title {
        font-size: 26px;
        line-height: 33px;
      }
      .equity_theft_caption {
        font-size: 14px;
        line-height: 20px;
      }
      .info_blocks_title {
        font-size: 14px;
        line-height: 18px;
      }
      .info_blocks_subtitle {
        font-size: 14px;
        line-height: 20px;
      }
    }
    .how_its_works {
      .how_its_works_title {
        font-size: 26px;
        line-height: 33px;
      }
      .how_its_works_caption {
        font-size: 16px;
        line-height: 28px;
      }
    }
    .theft_captions {
      font-size: 16px;
      line-height: 28px;
    }
    .card_top {
      // margin-top: 65px;
      width: 90% !important;
    }
    .linear_bg {
      padding: 40px 0;
    }
    .stay_loop_content {
      .county_captions {
        padding: 0 10px;
      }
    }
    .county_search_captions {
      font-size: 16px;
      line-height: 26px;
    }
  }

  @media only screen and (max-width: 425px) {
    .cr_bg_img {
      height: 70vh !important;
    }
    .equity_theft {
      padding: 1050px 10px 0px;
    }
    .how_its_works {
      .button_width {
        width: 100%;
        font-size: 14px !important;
      }
    }
    .vector2 {
      top: 45%;
    }
    @media only screen and (max-height: 896px) {
      .equity_theft {
        padding: 900px 15px 0px;
      }
      @media only screen and (max-height: 812px) {
        .equity_theft {
          padding: 1000px 15px 0px;
        }
      }
    }

    @media only screen and (max-height: 700px) {
      .cr_bg_img {
        height: 65vh !important;
      }
      .equity_theft {
        padding: 1100px 15px 0px;
      }
    }
    @media only screen and (max-height: 640px) {
      .equity_theft {
        padding: 1150px 15px 0px;
      }
    }
  }
  @media only screen and (max-width: 360px) {
    @media only screen and (max-height: 800px) {
      .cr_bg_img {
        height: 65vh !important;
      }
      .equity_theft {
        padding: 1111px 15px 0px;
      }
    }
    @media only screen and (max-height: 640px) {
      .cr_bg_img {
        height: 85vh !important;
      }
      .equity_theft {
        padding: 1275px 15px 0px;
      }
    }
  }
}
@media only screen and (min-width: 1600px) {
  .main_class1 {
    margin: 0 auto;
    max-width: 1600px;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
}
