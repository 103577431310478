.properties-wrapper {
  .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: #0d120b;
  }

  .addBtn {
    background: #0395ff;
    border-radius: 20px;
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
    text-transform: uppercase;
    color: #ffffff;
    padding: 6px 20px;
    box-shadow: none;
  }

  .propertyBox_wrapper {
    .property-details {
      .document {
        position: absolute;
        top: -30px;
        right: 0;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #ff006b;
      }

      .securedTitle {
        font-weight: 600;
        font-size: 16px;
        line-height: 21px;
        letter-spacing: 0.1em;
        color: #0395ff;
      }

      .propertyName {
        font-weight: 600;
        font-size: 18px;
        line-height: 23px;
        color: #0d120b;
      }

      .subscription {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #4c524a;
      }

      .renewBtn {
        background: #15be53;
        border-radius: 20px;
        padding: 6px 20px;
        font-weight: 600;
        font-size: 12px;
        line-height: 17px;
        color: #ffffff;
        box-shadow: none;
      }

      .viewBtn {
        background: none;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #15be53;
      }
    }

    .propertyValue {
      .valutTitle {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #4c524a;
      }

      .priceValue {
        font-weight: 600;
        font-size: 40px;
        line-height: 52px;
        letter-spacing: -0.05em;
        color: #0d120b;
      }

      .priceValueDisclosure {
        font-weight: 600;
        font-size: 25px;
        line-height: 52px;
        letter-spacing: -0.05em;
        color: #0d120b;
      }

      .valueGrowth {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #ff006b;
      }
    }

    .border-left {
      position: relative;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        height: 100%;
        width: 1px;
        background-color: #efefef;
        top: 0;
        left: 24px;
        z-index: 8;
      }
    }

    .chainTitle {
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
      color: #0d120b;
    }

    .events-item {
      .yearLabel {
        background: rgba(52, 75, 91, 0.1);
        border-radius: 23px;
        font-weight: 600;
        font-size: 14px;
        line-height: 30px;
        text-align: center;
        color: #0d120b;
      }

      .eventTitle {
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #0395ff;
      }

      .eventDescription {
        font-weight: 400;
        font-size: 16px;
        line-height: 25px;
        color: #4c524a;
        margin-bottom: 10px;
      }

      .attachedDocs {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #0d120b;
        margin-bottom: 8px;
        max-width: 100vw;
      }

      .document-wrap {
        .missingDoc {
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          color: #ff006b;
          margin-bottom: 14px;
        }
      }
    }

    .horizontal-line {
      margin-left: -24px;
      margin-right: -24px;
    }

    .mainPaper {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: -8px;
        left: -2px;
        background-image: url("../assets/properties/verified.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 42px;
        height: 50px;
        z-index: 8;
      }
    }
  }
}

.requestDocBtn {
  background: #15be53 !important;
  box-shadow: none !important;
  border-radius: 20px !important;
  padding: 6px 20px !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 17px !important;
  text-transform: uppercase !important;
  color: #ffffff !important;
}

.gridpaper {
  background-color: transparent !important;
  box-shadow: none !important;
}

.pac-container {
  z-index: 99999;
}

.menuItemsList {
  li {
    .active {
      img {
        filter: invert(60%) sepia(78%) saturate(5072%) hue-rotate(183deg)
          brightness(99%) contrast(105%);
      }
      path {
        fill: #0395ff !important;
      }

      span {
        color: #0395ff !important;
      }
    }
  }
}

.custom-link-wrapper {
  text-decoration: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #0d120b;
}

.title-chain {
  .MuiPaper-root {
    width: 100%;
  }

  .MuiCollapse-wrapperInner {
    border: 1px solid #e8ecf0;
  }

  .MuiTypography-root {
    font-size: 14px;
    line-height: 18.2px;
  }

  .title-chain-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0395ff;
    color: white;
    padding: 3px;
    border-radius: 50%;
    margin-top: 15px;
    margin-bottom: 6px;
  }

  .title-chain-dates {
    color: #0395ff;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    margin-top: 0;
    margin-left: 1.2rem;
  }

  .MuiChip-label {
    color: #0d120b;
    font-weight: 600;
    font-size: 14px;
    line-height: 30px;
  }

  .label-text {
    .MuiTypography-root {
      word-break: break-word;
      font-weight: 500;
      margin-bottom: 10px;
    }
  }

  .value-text {
    .MuiTypography-root {
      word-break: break-word;
      font-weight: 400;
      margin-bottom: 10px;
    }
  }

  .accordian-title {
    flex-direction: row;
    align-items: center;
  }
}

@media only screen and (max-width: 1199.98px) {
  .border-left {
    &:before {
      display: none;
    }
  }
}

@media only screen and (max-width: 991.98px) {
  .properties-wrapper {
    .propertyBox_wrapper {
      .mainPaper {
        background-color: transparent !important;
        box-shadow: none !important;
        padding: 0 !important;

        &::before {
          top: -3px !important;
          left: -3px !important;
        }
      }

      .property-details {
        .document {
          right: auto;
          left: 0;
        }

        button {
          flex: 1 0 auto;
        }
      }

      .gridpaper {
        background-color: #ffffff !important;
        box-shadow: 0px 2px 2px rgb(52 75 91 / 6%) !important;
        padding: 20px;
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  .properties-wrapper {
    .propertyBox_wrapper {
      .property-details {
        .propertyName {
          font-size: 16px;
          line-height: 21px;
        }

        .securedTitle {
          font-size: 14px;
          line-height: 18px;
        }

        .document {
          font-size: 10px;
          line-height: 13px;
        }

        .subscription {
          font-size: 13px;
        }
      }
    }
  }

  .title-chain {
    .accordian-title {
      flex-direction: column;
      align-items: start;
    }

    .title-chain-dates {
      font-size: 13px;
      margin-top: 1rem;
      margin-left: 0;
    }
  }
}
