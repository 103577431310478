.video-wrapper {
  position: relative;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
}

.thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  filter: none;
  transition: opacity 0.3s, filter 0.3s;
  opacity: 1;

  &:hover {
    filter: brightness(30%);
  }
}

/* Styles for the play button */
.custom-play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  cursor: pointer;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0); // Semi-transparent outer ring

  display: flex;
  justify-content: center;
  align-items: center;

  // Inner circle for the play icon
  &::before {
    content: "";
    position: absolute;
    width: 60%;
    height: 60%;
    background-color: white;
    border-radius: 50%;
    z-index: 1;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
  }
  // Play icon image
  img {
    position: relative;
    width: 15px;
    height: 20px;
    z-index: 2;
  }
  pointer-events: none;
}
.captions {
  font-family: "IBM Plex Sans", sans-serif !important;
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
  text-align: center;
  color: white;
}
