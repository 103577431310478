.notify_header {
  //   color: #0395ff;
  color: #005b9e;
  font-size: 16px;
  line-height: 21px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-bottom: 25px;
}

.real_stories {
  background-color: white;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}

.articals {
  text-align: start;
  padding: 5px 25px;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;

  .text {
    flex: 1 0 auto;
    font-size: 18px;
    margin-top: 1rem;
    font-weight: 400;
    line-height: 25px;
    @media (max-width: 991.98px) {
      font-size: 16px;
      line-height: 22px;
    }
  }

  .notify_header {
    font-size: 12px;
    padding-top: 5px;
  }

  h5 {
    font-weight: 700;
  }
}
.realStoryWrapper .slick-track .slick-active {
  opacity: 1 !important;
}
.realStoryWrapper .slick-track .slick-slide {
  opacity: 0.5;
}
// .slick-active:last-child {
//   opacity: 0.5 !important;
// }
.read_more {
  color: #0155b7;
  padding-top: 40px;
  cursor: pointer;
}

.privateGuardList {
  padding: 0rem !important;
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  line-height: 25px;
  @media (max-width: 991.98px) {
    font-size: 16px;
    line-height: 23px;
  }
}

.read_more_btn {
  color: white !important;
}
.read_more_testimonial {
  color: rgba(1, 85, 183, 1) !important;
  background-color: white !important;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.05em;
}
.popover_modal {
  background-color: white;
  max-width: 500px;
  text-align: center;
  margin: 0 auto;
  color: rgba(0, 110, 190, 1);
  position: relative;
  border-radius: 10px;
  padding: 36px;
}
.popover_modal img {
  position: absolute;
  filter: brightness(0) saturate(100%) invert(41%) sepia(76%) saturate(1173%)
    hue-rotate(179deg) brightness(97%) contrast(109%);
}
.MuiPopover-paper {
  border-radius: 10px !important;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2) !important;
}
.popover_modal .QuoteStart {
  left: 10px;
  top: 10px;
}
.popover_modal .QuoteEnd {
  bottom: 10px;
  right: 10px;
}

.text-spacing {
  min-height: 175px;
}

@media only screen and (max-width: 991.98px) {
  .notify_header {
    font-size: 12px;
    line-height: 16px;
  }
}
