.paymentDone_bg_img {
  background-image: url("../assets/lennar/property_bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 70vh;
  width: 100%;
  font-family: "Reckless Neue";
}
.overlap-div-spacing {
  margin-top: -150px;
}
.lennar-wrapper {
  p {
    font-family: "Mabry Pro";
    font-size: 20px;
  }
}
.rkNeue-text {
  font-family: "Reckless Neue";
}
.mbPro-text {
  font-family: "Mabry Pro";
}

.alphabate-list {
  padding: 10px 7px !important;
}

.paymentDone_wrapper {
  font-family: "Mabry Pro";
  font-weight: 400;

  .main-container1 {
    border: 1px solid #dee3ea;
    border-radius: 10px 10px 0px 0px;
    max-width: 900px;
  }
  .main-container2 {
    border: 1px solid #dee3ea;
    border-radius: 0px 0px 10px 10px;
    max-width: 900px;
  }

  .box-container {
    padding: 50px 50px 0px 50px;
  }
  .pd-header {
    font-family: "Reckless Neue" !important;
    font-size: 38px;
    line-height: 45.59px;
  }
  .pd-description {
    font-size: 24px;
    line-height: 36px;
  }
  .pd-answers {
    font-family: "Mabry Pro";
    font-size: 20px;
    line-height: 30px;
  }
  .movefaster-heading {
    font-size: 26px;
    line-height: 39px;
  }
  .movefaster-description {
    font-size: 20px;
    line-height: 30px;
    font-weight: 300;
  }
  li {
    font-size: 20px;
    padding: 20px 7px;
  }
}

.contact-support {
  font-size: 12px !important;
  line-height: 15px !important;
  margin: 20px 80px 30px;
  text-transform: uppercase;
  line-height: 20px;
}
.logo_specing {
  width: 48px;
  height: 48px;
  // margin: 2px 0px;
}
.qrcode_img {
  border: 4px solid black;
  padding: 6px;
  border-radius: 6px;
}

@media only screen and (max-width: 1476px) {
  .overlap-div-spacing {
    margin-top: -100px;
  }
}

@media only screen and (max-width: 1024px) {
  .overlap-div-spacing {
    margin-top: -80px;
  }
}

@media only screen and (max-width: 768.98px) {
  .paymentDone_wrapper {
    margin-top: -100px !important;

    .box-container {
      padding: 50px 0px 0px 0px !important;
    }
  }
}

@media only screen and (max-width: 575.98px) {
  .paymentDone_wrapper {
    margin-top: 50px !important;
    box-shadow: 10px 10px 30px 12px rgba(52, 75, 91, 0.29);
    .pd-description {
      font-size: 20px;
      line-height: 32px;
    }
  }
  .qrimg-section {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: row;
  }
  .contact-support {
    font-size: 11px;
    line-height: 10px;
    text-transform: uppercase;
  }
}
