.create-account {
  .background_img {
    background-image: url("../assets/lennar/Mask-group.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 0 0 15%;
    z-index: 0;
    height: 80vh;
  }
  .main-text {
    font-family: IBM Plex Sans;
    font-size: 64px;
    font-weight: 400;
    line-height: 77px;
    letter-spacing: 0em;
    text-align: center;
  }
  .paragraph-text {
    font-family: Mabry Pro;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
  }
  .reviews_wrapper {
    margin-top: -400px;
    .main-container {
      max-width: 65%;
      border-radius: 10px;
      .box-container {
        padding: 50px 50px 0px 50px;
      }
    }
  }
  .help-text {
    font-family: IBM Plex Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    color: #2d2d2d;
    margin: 20px 0;
    cursor: pointer;
    text-decoration: underline;
  }
  .lennar-googleAutoComplete {
    background: #fff;
    padding: 10px;
    width: 100%;
    border: 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5) !important;
    height: 3.5rem;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
  .standard-phone-field {
    background-color: #fff;
    border: 0px !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5) !important;
    .PhoneInputInput {
      background-color: #fff;
    }
  }
  .standard-select-field {
    .MuiSelect-select {
      background-color: transparent;
    }
    // label {
    //     font-weight: 400;
    //     font-size: 14px;
    //     line-height: 20px;
    //     color: #4c524a;
    // }
  }
}
// .MuiMenu-list {
//   max-height: 250px;
// }
@media only screen and (max-width: 1024px) {
  .create-account .reviews_wrapper .main-container {
    max-width: none;
  }
}
@media only screen and (max-width: 768.98px) {
  .create-account {
    .background_img {
      height: 65vh;
    }
    .reviews_wrapper {
      margin-top: -240px !important;
      .box-container {
        padding: 50px 0px 0px 0px !important;
      }
    }
  }
}
@media only screen and (max-width: 675.98px) {
  .create-account {
    .main-text {
      font-size: 30px;
      line-height: 30px;
      margin: 10px 0;
    }
    .paragraph-text {
      font-size: 20px;
      line-height: 30px;
    }
    .reviews_wrapper {
      margin-top: -200px;
    }
  }
}
// @media only screen and (max-width: 575.98px) {
//     .create-account {
//         .reviews_wrapper {
//             box-shadow: 10px 10px 30px 12px rgba(52, 75, 91, 0.29);
//         }
//     }
// }

@media only screen and (max-height: 600px) {
  .create-account {
    .reviews_wrapper {
      margin-top: -200px !important;
    }
  }
}
@media only screen and (max-width: 424.98px) {
  .create-account {
    .main-text {
      font-size: 22px;
      line-height: 24px;
      margin: 5px 0;
    }
    .paragraph-text {
      font-size: 16px;
      line-height: 24px;
    }
  }
}
