#settings-menu {
  .setting-menu-item {
    width: 100%;
  }

  .setting-menu-box {
    display: flex;
    justify-content: center;
    align-items: center;

    .setting-menu-text {
      margin-left: 10px;
    }
  }

  .setting-menu-box:hover {
    color: #0395ff;
    stroke: #0395ff;
    stroke-width: 0.55;
  }
}

.text-label {
  align-items: center;
  background-color: #efece7;
  color: black;
  display: inline-block;
  padding: 4px 8px;
  font-weight: 500;
  border-radius: 25px;
}

.addLeads {
  button {
    flex-direction: row;
    align-items: flex-start !important;
    padding: 5px 30px !important;
    margin: 5px;
    font-weight: 600 !important;
    font-size: 0.875rem;
    line-height: 1.75;
    gap: 10px;
    border-radius: 29px !important;
    color: #ffffff !important;
    border: none !important;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.05em;
    text-transform: uppercase !important;
  }

  .addLeadsBtn {
    background: #15be53 !important;
  }
}

.MuiTableSortLabel-icon {
  color: #0395ff !important;
}

.MuiDialogContent-root {
  overflow: hidden;
}
.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  max-height: 55% !important;
}
.textField_spacing {
  padding-left: 8px !important;
  padding-right: 8px !important;
  padding-bottom: 24px !important;
}

.preview-file {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px !important;
  // width: 80%;

  .preview-url {
    color: blue;
  }

  span {
    cursor: pointer;
  }
}

.preview-url {
  color: blue;
}

.column-date-range {
  input {
    align-items: center;
    font-size: 16px;
    height: 52px;
    width: 225px;
  }
}

#pagination-container {
  display: flex;
  justify-content: center;

  .pagination-first-page,
  .pagination-last-page {
    min-width: 0px;
    width: 40px;
    border: 1px solid transparent;
    background-color: rgba(255, 255, 255, 1) !important;
    color: rgba(21, 190, 83, 1);
  }

  .pagination-first-page:disabled,
  .pagination-last-page:disabled {
    color: rgba(188, 197, 204, 1);
    border: 1px solid transparent;
  }
}

.custom-pagination {
  .Mui-selected {
    border: 1px solid rgba(21, 190, 83, 1);
    background-color: rgba(255, 255, 255, 1) !important;
    color: rgba(21, 190, 83, 1);
  }

  .MuiPaginationItem-previousNext {
    border: 1px solid transparent;
    background-color: rgba(255, 255, 255, 1) !important;
    color: rgba(21, 190, 83, 1);
  }

  .Mui-disabled {
    color: rgba(188, 197, 204, 1);
  }
}

.accountBoxWrapper {
  background-color: white;
}

.collapsible-row {
  .content-heading {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0rem;
    text-align: left;
    color: #4c524a;
  }

  .content-text {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #0395ff;
    max-width: 200px;
  }

  .mw-380 {
    max-width: 380px;
  }

  .content-black-text {
    color: #0d120b;
  }

  .view-edit-user-btn {
    padding: 6px 20px;
    font-size: 12px;
    line-height: 17px;
    color: #fff;
    background-color: #0395ff;
    border-radius: 20px;
    margin-top: 26px;
  }
}

.view-edit-page {
  font-family: IBM Plex Sans;
  font-style: normal;

  .back-to-user-list {
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    cursor: pointer;
    color: #0395ff;
  }

  .user-name {
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: #0d120b;
  }

  .user-information-tab-box {
    .content-card {
      padding: 30px;
      position: relative;
      min-height: 250px;

      .card-heading {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #0395ff;
      }

      .card-sub-text {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #4c524a;
        max-width: 455px;
      }

      .text-black {
        color: #0d120b !important;
      }

      .subscription-text {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #4c524a;
      }

      .renew-btn {
        font-weight: 600;
        font-size: 12px;
        line-height: 17px;
        text-transform: uppercase;
        color: #ffffff;
        background-color: #15be53;
        border-radius: 20px;
        padding: 6px 20px;
      }

      .view-details-btn {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        text-transform: uppercase;
        color: #15be53;
      }

      .edit-btn {
        position: absolute;
        top: 15px;
        right: 20px;
        background: #ffffff;
        border: 1px solid #15be53;
        border-radius: 4px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #15be53;
        padding: 11px;
      }

      .history-list {
        font-weight: 400;
        font-size: 14px;
        line-height: 28px;
        color: #0395ff;
      }

      .note-list {
        background-color: rgb(156, 184, 166, 0.1);
        border-radius: 4px;
        padding: 20px;
      }

      .note-text {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #4c524a;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: pre-wrap;
      }
    }
  }
}

.css-10adeim-MuiButtonBase-root-MuiButton-root {
  color: inherit !important;
  font-size: 0.875rem !important;
  line-height: 1.75 !important;
  text-transform: uppercase;
  min-width: 64px;
  padding: 5px 15px !important;
  border-radius: 4px !important;
  color: #15be53 !important;
}

@media only screen and (max-width: 400px) {
  .text-label {
    font-size: 14px;
    padding: 2px 4px;
    line-height: 1;
    border-radius: 20px;
  }
}
