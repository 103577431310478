.report-results-investor {
  font-family: "IBM Plex Sans" !important;
  background-color: #f6f9fc;
  .top_section {
    margin-top: 150px;
    margin-bottom: 50px;
    .PRA__block {
      width: 50%;
      margin: 0 auto;
      @media (max-width: 1490.98px) {
        width: 60%;
      }
      @media (max-width: 1230.98px) {
        width: 70%;
      }
      @media (max-width: 868.98px) {
        width: 85%;
      }
      @media (max-width: 670.98px) {
        width: 95%;
      }
      .PRA__header-text {
        font-size: 48px;
        font-weight: 600;
        line-height: 57.6px;
        letter-spacing: -0.03em;
        text-align: center;
        text-decoration-skip-ink: none;
        @media (max-width: 670.98px) {
          font-size: 35px;
          line-height: 45px;
        }
      }
      .PRA__para-text {
        margin-top: 15px;
        font-size: 20px;
        font-weight: 400;
        line-height: 25px;
        text-align: center;
        text-decoration-skip-ink: none;
        @media (max-width: 670.98px) {
          font-size: 18px;
          line-height: 27px;
        }
      }
    }
  }
  .main_section {
    .container-fluid {
      // background: white;
      width: 86%;

      @media (max-width: 1560.98px) {
        width: 96%;
      }

      @media (max-width: 1400.98px) {
        width: 95%;
      }
      @media (max-width: 1200.98px) {
        width: 86%;
      }
      @media (max-width: 428.98px) {
        width: 100%;
      }
    }
  }
  .section1 {
    @media (max-width: 1199.98px) {
      width: 70%;
    }
    @media (max-width: 899.98px) {
      width: 80%;
    }
    @media (max-width: 800px) {
      width: 100%;
      //@media (max-height: 1180px) {
      position: relative;
      // box-shadow: rgb(220, 228, 236) 0px 0px 0px 0px,
      //   rgb(220, 228, 236) 0px 1px 0px 0px;
      //}
    }
    @media (max-width: 515.98px) {
      padding-right: 10px;
      padding-left: 10px;
    }
  }
  .personal_info {
    font-size: 14px;
    font-weight: 500;
    line-height: 18.2px;
    text-align: left;
    color: #0395ff;
    @media (max-width: 599.98px) {
      text-align: center;
    }
  }

  .property-details {
    // display: flex;
    // justify-content: start;
    // align-items: center;
    // padding-left: 20px;
    @media (max-width: 800px) {
      padding: 10px 0;
    }
  }
  .border-left {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      height: 100%;
      width: 1px;
      background-color: #c9c9c9;
      top: 0;
      z-index: 8;
    }
  }
  .below_divider {
    padding-top: 20px;
    display: none !important;
    .MuiDivider-root {
      background: #a7a7a7;
      height: 1px;
      width: 100%;
      // @media (max-width: 1199.98px) {
      //   width: 105%;
      // }
    }
    @media (max-width: 1199.98px) {
      display: flex !important;
      justify-content: center;
    }
  }
  .value_text {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
  }
  .approx_price {
    font-size: 40px;
    font-weight: 600;
    line-height: 52px;
    letter-spacing: -0.05em;
    text-align: center;
    @media (max-width: 1535.98px) {
      font-size: 36px;
      line-height: 45px;
    }
    @media (max-width: 1330.98px) {
      font-size: 34px;
    }
    @media (max-width: 899.98px) {
      font-size: 40px;
    }
  }
  .riskometer {
    height: 240px !important;
  }
  .right-section,
  .middle-section {
    padding: 20px 0 40px;
    @media (max-width: 1199.98px) {
      padding: 20px 0px;
      .chart-section {
        justify-content: end !important;
        @media (max-width: 899.98px) {
          justify-content: center !important;
        }
      }
      #chartdiv {
        width: 80% !important;
        @media (max-width: 899.98px) {
          width: 60% !important;
        }
        @media (max-width: 767.98px) {
          width: 80% !important;
        }
        @media (max-width: 585.98px) {
          width: 100% !important;
        }
      }
    }
  }
  .middle-section {
    @media (max-width: 1199.98px) {
      padding: 0px 0px;
    }
  }
  .summary-card {
    background: white;
    // width: 85%;
    .left-section {
      padding: 20px 15px 50px 40px;
      @media (max-width: 1299.98px) {
        padding: 20px 15px 50px 30px;
      }
      @media (max-width: 1199.98px) {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 0px 10px 0px;
      }
      @media (max-width: 800.98px) {
        padding: 10px 20px;
      }
      @media (max-width: 464.98px) {
        padding: 5px;
      }
      .property-stats {
        border: 1px solid #cad9ec;
        background: #f6f9fc;
        display: flex;
        gap: 10px;
        margin-bottom: 15px;

        .property-count {
          color: #000;
          font-weight: bold;
          padding: 8px 12px;
          border-radius: 8px;
          width: max-content;
          @media (max-width: 1199.98px) {
            .border-left {
              &:before {
                display: block;
              }
            }
          }
          @media (max-width: 389.98px) {
            div {
              font-size: 12px;
              padding-right: 2px !important;
              padding-left: 2px !important;
            }
          }
        }
      }
      .investor-name {
        font-weight: bold;
        margin-bottom: 12px;
      }
      .alert-section {
        display: flex;
        gap: 8px;
        @media (max-width: 599.98px) {
          column-gap: 7px;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
          align-content: center;
        }

        .alert {
          padding: 6px 10px;
          font-weight: bold;
          color: #f44336;
          @media (max-width: 1432.98px) {
            font-size: 12px;
          }
          @media (max-width: 1200.98px) {
            font-size: 13px;
          }
          @media (max-width: 425.98px) {
            padding: 6px 10px;
            width: 60%;
          }
          @media (max-width: 370.98px) {
            width: 80%;
          }

          &.critical-alert {
            border: 1px solid #bc2970;
            background: #bc29701a;
            color: #bc2970;
          }

          &.medium-alert {
            background: #ff5c651a;
            border: 1px solid #ff5c65;
            color: #ff5c65;
          }
          &.medium-low-alert {
            background: #fd99821a;
            color: #fd9982;
            border: 1px solid #fd9982;
          }
        }
      }
    }
  }
  .investor_table_section {
    .investor_table {
      padding: 10px 0px 120px;
      @media (max-width: 1560.98px) {
        width: 95%;
      }
      @media (max-width: 1400.98px) {
        width: 94%;
      }
      @media (max-width: 1200.98px) {
        width: 84%;
      }
      @media (max-width: 991.98px) {
        padding: 10px 0px 80px;
      }
      @media (max-width: 767.98px) {
        padding: 10px 0px 60px;
      }
      @media (max-width: 428.98px) {
        width: 94%;
        padding: 10px 0px 40px;
      }
    }
  }
  .admin_buttons {
    color: white;
    @media (max-width: 678.99px) {
      font-size: 13px !important;
    }
    @media (max-width: 599.98px) {
      font-size: 12px !important;
      margin-bottom: 10px;
      padding: 10px 20px !important;
    }
    @media (max-width: 464.98px) {
      font-size: 14px !important;
      width: 100%;
    }
  }
  .title-protection-real-estate-investors {
    .fifth-section {
      padding-bottom: 80px;
      background: #f6f9fc !important;
    }
    .ep__safeguard {
      background: white !important;
      color: #000 !important;
      width: 75%;
      @media (max-width: 991.98px) {
        width: 85%;
      }
      @media (max-width: 786.98px) {
        width: 95%;
      }
      .protection__para-text {
        .link__a {
          color: #000 !important;
        }
      }
      .protection__block {
        padding: 5% 15% !important;
        @media (max-width: 1620.98px) {
          padding: 5% 8% !important;
        }
        @media (max-width: 1440.98px) {
          padding: 5% 4% !important;
        }
        @media (max-width: 1300.98px) {
          padding: 4% !important;
        }
        @media (max-width: 991.98px) {
          padding: 3% !important;
        }
      }
    }
  }
  .check_img {
    width: 20px !important;
    height: 20px !important;
    margin: 5px;
  }
  .text-description {
    padding-left: 8px;
  }
  .last-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .pp_title {
      font-size: 30px;
      font-weight: 600;
      line-height: 39px;
      text-align: center;
    }
    .pp_caption {
      font-size: 20px;
      font-weight: 300;
      line-height: 30px;
      text-align: center;
    }
    .pp_points {
      padding: 25px 100px 100px;
      @media (max-width: 600px) {
        padding: 25px 50px 100px;
      }
      @media (max-width: 575.98px) {
        text-align: center !important;
      }
      @media (max-width: 350px) {
        padding: 25px 20px 100px;
      }
    }
    .protection_title {
      font-size: 18px;
      font-weight: 600;
      line-height: 23.4px;
      text-align: center;

      @media (max-width: 575.98px) {
        font-size: 16px;
        line-height: 21px;
      }
    }
    .protection_desc {
      font-size: 18px;
      font-weight: 400;
      line-height: 25px;
      text-align: center;
      @media (max-width: 1050px) {
        margin-bottom: 45px;
      }
    }
    @media (max-width: 600px) {
      .pp_title {
        font-size: 28px;
        line-height: 37px;
      }
      .pp_caption {
        font-size: 18px;
        line-height: 28px;
      }
      .protection_desc {
        font-size: 16px;
        line-height: 23px;
      }
    }
  }
  .private_guard_bg {
    width: 75%;
    margin-top: 150px;
    margin-bottom: 80px;
    padding: 70px 0 35px;
    @media (max-width: 1150.98px) {
      width: 80%;
      // padding: 70px 0 50px;
    }
    @media (max-width: 991.98px) {
      width: 90%;
      // padding: 70px 0 50px;
    }
    @media (max-width: 899.98px) {
      width: 95%;
      // padding: 70px 0 50px;
    }
    @media (max-width: 815.98px) {
      width: 100%;
      padding: 70px 0 30px;
    }
    .card-body {
      width: 60%;
      margin: 0 auto;
      @media (max-width: 1150.98px) {
        width: 75%;
      }
      @media (max-width: 991.98px) {
        width: 85%;
      }
      @media (max-width: 787.98px) {
        width: 95%;
      }
    }
    .private_guard_title {
      @media (max-width: 840.98px) {
        font-size: 26px;
        margin-bottom: 10px;
      }
    }
    // img {
    //   @media (max-width: 815.98px) {
    //     max-width: none;
    //     width: 756px !important;
    //   }
    // }
    .vector_4 {
      position: absolute;
      top: 24%;
      left: 50%;
      transform: translate(-50%, -55%);
    }
    .vector_3 {
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -55%);
      @media (max-width: 404.98px) {
        max-width: none;
        width: 450px !important;
      }
    }
    .vector_2 {
      position: absolute;
      top: 55%;
      left: 50%;
      transform: translate(-50%, -55%);
      @media (max-width: 535.98px) {
        max-width: none;
        width: 640px !important;
      }
    }
    .vector_1 {
      position: absolute;
      top: 55%;
      left: 50%;
      transform: translate(-50%, -55%);
      @media (max-width: 815.98px) {
        // display: none;
        max-width: none;
        width: 756px !important;
      }
      @media (max-width: 400.98px) {
        width: 840px !important;
      }
    }
    .vector,
    .pana2,
    .pana {
      @media (max-width: 815.98px) {
        max-width: 100% !important;
        width: auto !important;
      }
    }
    .button2 {
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0.05em;
      text-align: center;
      padding: 15px 40px 15px 40px;
      border-radius: 29px;
      gap: 10px;
      color: #15be53;
      background: #ffffff;
      &:not(.disable):hover {
        background: #ffffff;
      }
      @media (max-width: 600.98px) {
        padding: 10px 20px;
        font-size: 13px;
        line-height: 18px;
      }
    }
  }
  .title-protection-real-estate-investors {
    .fourth-section {
      background: #ebf3fc;
      .row {
        @media (max-width: 991.98px) {
          padding-bottom: 0;
          margin-bottom: 3rem;
        }
      }
      .img-container img {
        padding-left: 80px;
      }
    }
  }
}
