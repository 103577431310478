.background {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 10% 0 0;
  z-index: 0;
}
.add-user-details {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0 30px;
  @media (max-width: 900.98px) {
    margin: 20px 30px 30px;
  }
  @media (max-width: 500.98px) {
    margin: 20px 20px 30px;
  }
  .add_user_block {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px 0;
    margin: 20px 0;
    background: white;
    border-radius: 10px;
    box-shadow: 0px 30px 70px -10px rgba(52, 75, 91, 0.29);
  }
  .header_text {
    font-size: 34px;
    line-height: 43px;
    padding: 20px 0 30px;
    @media (max-width: 500.98px) {
      font-size: 30px;
    }
    @media (max-width: 360.98px) {
      font-size: 26px;
    }
  }
  .beneficiary-form {
    padding-left: 30px;
    @media (max-width: 500.98px) {
      padding-left: 0px;
    }
  }
  .popup_Btn {
    padding: 18px 35px !important;
    @media (max-width: 500.98px) {
      padding: 12px 35px !important;
    }
  }
  .alert_message {
    border-radius: 10px;
    box-shadow: 0px 30px 70px -10px rgba(52, 75, 91, 0.29);
  }
}
