.learn_ep_section {
  padding-top: 100px;
  @media (max-width: 991.98px) {
    padding-top: 70px;
  }
  @media (max-width: 786.98px) {
    padding-top: 50px;
  }
  @media (max-width: 480.98px) {
    padding-top: 40px;
  }

  .card_top {
    background: white !important ;
    max-width: 1075px;
    width: 85%;
    //   margin-top: -420px !important;
    border-radius: 10px;
    overflow: hidden;
    .title {
      font-size: 30px;
      font-weight: 600;
      line-height: 39px;
      text-align: left;
      @media (max-width: 575.98px) {
        font-size: 20px;
        line-height: 26px;
      }
    }
    .content {
      font-size: 18px;
      font-weight: 400;
      line-height: 25px;
      text-align: left;
      background: #4c524a;
    }
    @media (max-width: 1180px) {
      width: 85%;
      .content {
        font-size: 17px;
        line-height: 23px;
      }
      .button {
        width: 80%;
      }
    }
  }
  .markgroup_img {
    display: flex;
    align-items: center;
    height: 100% !important;
    margin: 0px;
    img {
      display: flex;
      align-items: stretch;
      object-fit: cover;
    }
  }
  .box_shadow {
    box-shadow: 0px 30px 70px -10px rgba(52, 75, 91, 0.29);
    @media (max-width: 991.98px) {
      margin-top: 36px;
    }
  }
  .card_content {
    text-align: start;
    padding: 65px 0px 20px 70px;
    @media (max-width: 1180px) {
      text-align: center;
      padding: 65px 0px 20px 20px;
    }
  }
  .button {
    padding: 15px 40px 15px 40px;
    border-radius: 29px;
    gap: 10px;
    background: #15be53;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.05em;
    text-align: center;
    color: #0d120b;
  }

  .bottom_seperator {
    display: flex;
    justify-content: center;
    margin-top: 65px;
    hr {
      border: 1px solid #344b5b;
      width: 73%;
      opacity: 0.05;
      @media (max-width: 1280px) {
        width: 85%;
      }
    }
  }
  .bottom_button {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 50px;

    button {
      font-family: "IBM Plex Sans" !important;
      text-transform: none;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #0155b7;
      border: 1px solid #0155b7;
      padding: 10px 20px;
    }
  }
}
