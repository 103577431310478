.alert_card {
  padding: 90px 100px;
  box-shadow: 0px 30px 70px -10px rgba(52, 75, 91, 0.29);
  border-radius: 10px;
  @media (max-width: 992.98px) {
    padding: 30px !important;
  }

  .steps_wrapper {
    .steps {
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      color: #0d120b;
      background: rgba(21, 190, 83, 0.3);
      width: 46px;
      height: 46px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 575.98px) {
        text-align: center;
      }
    }
    .number_alignment {
      @media (max-width: 575.98px) {
        text-align: center;
        padding: 0 45%;
      }
    }
    h5 {
      font-size: 18px;
      line-height: 25px;
      color: #0d120b;
      margin-bottom: 15px;
    }

    p,
    h3 {
      font-size: 18px;
      line-height: 25px;
      color: #4c524a;
      @media (max-width: 575.98px) {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  .last_section {
    // background-color: rgb(197 236 205);
    background: #b9ebcb4d;
    border: 1px solid #b9ebcb;
    border-radius: 5px;
    padding: 30px;
    margin: 0 auto;
    width: 97%;
    @media (max-width: 575.98px) {
      padding: 10px;
    }
  }
}
