// .plans_wrapper .subTitle,
// .theft_protection .subTitle,
// .plans_wrapper .subTitle,
// .faq_wrapper .subTitle {
//     font-weight: 300;
//     font-size: 20px;
//     line-height: 30px;
//     text-align: center;
//     color: #4c524a;
// }

.stand_out_wrapper {
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 50%;
    background: linear-gradient(0deg, #0395ff, #0395ff),
      linear-gradient(266.55deg, #8fda4f -45.17%, #0395ff 109.5%);
    bottom: 0;
    z-index: -1;
  }

  .theft_protection {
    box-shadow: 0px 30px 70px -10px rgba(52, 75, 91, 0.29);
    border-radius: 10px;
    // background-image: url("../assets/home/theft_pro_vector.svg");
    background-position: top center;
    background-repeat: no-repeat;
    padding: 100px;
  }
}

.theft_protection {
  .protection_title {
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: #0d120b;
    margin-bottom: 20px;
  }

  .protection_desc {
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: #4c524a;
  }
}

.check_icon {
  background: #15be53;
  opacity: 0.5;
  border-radius: 15px;
  padding: 3px;
}

.highlighted {
  border-color: #15be53;
  border: 2px solid #15be53;
  box-shadow: 0px 30px 70px -10px rgba(52, 75, 91, 0.29);
}

.not-highlighted {
  border-color: #262a27;
  border: 2px solid #262a27;
  box-shadow: 0px 30px 70px -10px rgba(52, 75, 91, 0.29);
}
.plans-block {
  .MuiTypography-body1 {
    width: 100%;
  }
}

@media only screen and (max-width: 991.98px) {
  .stand_out_wrapper {
    .theft_protection {
      padding: 10px;
    }
  }
}

@media only screen and (max-width: 767.98px) {
  .stand_out_wrapper {
    .theft_protection {
      padding: 10px;
    }
  }

  .plans_wrapper .subTitle,
  .theft_protection .subTitle,
  .plans_wrapper .subTitle,
  .faq_wrapper .subTitle {
    font-size: 18px;
    line-height: 28px;
  }
}
