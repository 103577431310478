.investor-list {
  background-color: rgba(250, 250, 250, 1);
  min-height: 100vh;
  height: 100%;

  .nav_link {
    font-weight: 600;
    font-size: 14px;
    line-height: 16.9px;
    color: #0d120b;
    text-transform: uppercase;
  }

  .header_content {
    padding: 10px 10px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid #ebeef2;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
    @media (max-width: 767.98px) {
      padding: 10px 50px;
    }
    @media (max-width: 425.98px) {
      padding: 10px;
    }
    .EP_logo {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: white;
      width: 220px;
      height: 75px;
      // margin-left: 80px;
      @media (max-width: 1199.98px) {
        margin-left: 10px;
      }
      @media (max-width: 500.98px) {
        img {
          width: 95%;
          height: 95%;
        }
      }
      @media (max-width: 425.98px) {
        left: 20px;
      }
      .flex-logos {
        display: flex;
        gap: 20px;
      }
    }
    .contact_section {
      padding: 20px;
      color: black !important;
      z-index: 9999;
      @media (max-width: 1065.98px) {
        padding: 10px 20px;
      }
      @media (max-width: 767.98px) {
        padding: 20px;
      }
      .help_texts {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: end;
      }
      .phone_number {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0%;
        text-align: center;
        color: #0395ff;
      }
      .contact_texts {
        //  color: #f97316;
        font-size: 17px;
        line-height: 25px;
        text-align: right;
        a {
          text-decoration: none !important;
        }
      }
      .call_btn {
        background: #0155b7;
        border-radius: 20px;
        width: 40px;
        height: 40px;
        padding: 0;
        top: -5px;
        min-width: unset;
      }
    }
    .by-text {
      color: black;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      text-align: center;
      text-decoration: none;
    }
    .saperator {
      width: 1px;
      background-color: black;
    }
  }

  .container-spacing {
    padding-left: 30px;
    padding-right: 30px;

    @media (min-width: 1800.98px) {
      padding-left: 150px;
      padding-right: 150px;
    }
  }
  .save {
    display: flex;
    justify-content: end;
    margin-top: 20px;
    @media (max-width: 575.98px) {
      margin-top: 0px;
    }

    .save-btn {
      background: #15be53;
      padding: 20px;
      margin-bottom: 50px;
      border-radius: 5px;
      color: black;
      font-family: Inter;
      font-size: 14px;
      font-weight: 700;
      line-height: 16px;
      text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }
    .mobile-btn {
      padding: 15px !important;
      margin-bottom: 0px !important;
    }
  }
  .heading_text {
    display: flex;
    flex-direction: column;
    justify-content: start;
  }
  .heading {
    font-family: Inter;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 10px;
    line-height: 40px;
  }
  .sub_heading {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 10px;
    line-height: 24px;
  }
  .color-box {
    width: 15px;
    height: 15px;
    border-radius: 4px;
    display: inline-block;
    margin-right: 3px;
    box-sizing: border-box;
    background: #ebeef2;
  }
  .main_section {
    background: white;
    border-bottom: 1px solid #ebeef2;
    padding-top: 100px;
    .middle-section {
      padding: 60px 0 40px;
    }
  }
  .section_2 {
    background: #ebf3fc;
    padding: 35px 60px;
    @media (max-width: 737.98px) {
      padding: 35px 30px;
    }
    @media (max-width: 537.98px) {
      padding: 35px 5px;
    }
    @media (max-width: 620.99px) {
      padding: 35px 0 0px;
    }
    .title-monitoring-section {
      @media (max-width: 1199.98px) {
        flex-direction: column-reverse;
      }
    }
    .grid1 {
      div {
        // margin-top: 50px;
        width: 80%;
        background: #ebf3fc;
        z-index: 11;
        @media (max-width: 1199.99px) {
          padding: 30px 15% 0;
          width: 100%;
          position: relative;
          text-align: center;
        }
        @media (max-width: 899.98px) {
          padding: 30px 5% 0;
        }
        .monitoring_essential {
          font-weight: 600;
          font-size: 30px;
          line-height: 39px;
          @media (max-width: 599.98px) {
            font-size: 27px;
            line-height: 36px;
          }
        }
        .card-text {
          font-weight: 400;
          font-size: 16px;
          line-height: 25px;
          @media (max-width: 599.98px) {
            font-size: 14.5px;
            line-height: 20px;
          }
        }
      }
    }
    .grid2 {
      margin: 40px 0;
      @media (max-width: 1199.98px) {
        margin: 80px 0 110px;
      }
      @media (max-width: 799.99px) {
        margin: 55px 0 110px;
      }
      @media (max-width: 620.99px) {
        margin: 0px;
      }
      .orbit-wrap {
        height: 380px;
        list-style: none;
        font-size: 1.3em;

        > li {
          position: absolute;
          left: 27%;
          bottom: 50%;
          transform: translate(-50%, 50%);

          @media (max-width: 1199.98px) {
            left: 50% !important;
            bottom: 33% !important;
          }
          @media (max-width: 799.99px) {
            bottom: 30% !important;
          }
          @media (max-width: 620.99px) {
            bottom: 15% !important;
          }

          &:hover {
            ul {
              border-width: 2px;
              border-color: #fff;
            }
            ~ li ul {
              border-color: rgba(255, 255, 255, 0.2);
              li {
                opacity: 0.4;
              }
            }
          }
        }
        @media (min-width: 1199.98px) {
          height: 450px;
        }
        @media (max-width: 420px) {
          height: 400px !important;
        }
      }
    }
  }
  .top_section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 30px;
    @media (max-width: 575.98px) {
      flex-direction: column;
    }
  }
  .filter-options-carddiv {
    background-color: transparent !important;
    box-shadow: none !important;
    .filter-options-box {
      //border: 1px solid #eaeaea !important;
      div {
        //  background-color: #ffffff !important;
        font-family: Inter;
        font-size: 15px;
        font-weight: 400;
        text-align: left;
      }
    }
  }

  // Individual color classes
  .color-low {
    background: #6cce81;
  }

  .color-medium-low {
    background: #f8c268;
  }

  .color-medium {
    background: #fd9982;
  }

  .color-medium-high {
    background: #ff5c65;
  }

  .color-high {
    background: #bc2970;
  }

  // Container to align color boxes
  .color-box-container {
    display: flex;
    align-items: center;
  }

  .investor-pagination {
    #custom-pagination {
      box-shadow: 1px 2px 30px 5px rgba(0, 0, 0, 0.1);
    }
    .Mui-selected {
      border: 1px solid rgba(249, 115, 22, 1) !important;
      background-color: rgba(255, 255, 255, 1) !important;
      color: rgba(249, 115, 22, 1) !important;
    }

    .MuiPaginationItem-previousNext,
    .MuiPaginationItem-firstLast {
      border: 1px solid transparent;
      background-color: rgba(255, 255, 255, 1) !important;
      color: rgba(249, 115, 22, 1) !important;
    }
    ul {
      padding: 10px;
    }
  }
}
