
@media only screen and (max-width: 768px) {
  .column-date-range {
    input {
      width: 100% !important;
    }
  }
}

@media only screen and (max-width: 575px) {
  .subscription {
    h5 {
      font-size: 16px;
    }

    .MuiChip-label {
      font-size: 8px;
    }

    h6 {
      font-size: 12px;
    }
  }
}

@media only screen and (max-width: 425px) {
  .subscription {
    h5 {
      font-size: 14px;
    }

    .MuiChip-label {
      font-size: 6px;
    }

    h6 {
      font-size: 10px;
    }
  }
}

@media only screen and (max-width: 375) {
  .subscription {
    h5 {
      font-size: 10px;
    }

    .MuiChip-label {
      font-size: 4px;
    }

    h6 {
      font-size: 6px;
    }
  }
}