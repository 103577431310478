.state_block {
  padding: 20px;
  padding-bottom: 0px;
  font-family: IBM Plex Sans !important;
  .state_cards {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    flex-direction: row;
    text-decoration: none;
    border-radius: 4px 0px 0px 0px;
    height: 150px;
    position: relative;

    @media (max-width: 1199.98px) {
      padding: 20px 10px;
      text-align: center;
      flex-direction: column-reverse;
    }
    .MuiCardActions-root {
      padding: 0 !important;
    }
    .MuiCardContent-root {
      padding-left: 0px !important;
      padding-right: 0px !important;
      width: 200px;
      @media (max-width: 1400px) {
        width: 200px;
      }
    }
  }
  .more_info_section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-top: 10px;
    position: absolute; /* Positioned at the bottom */
    bottom: 0px; /* Adjust this for your card's height */
    left: 0;
    padding: 2px;
    background-color: rgba(
      0,
      0,
      0,
      0.05
    ); /* Slight background for separation */

    .more_info_text {
      padding-left: 10px;
      font-weight: bold;
      font-size: 14px;
      svg {
        width: 27px;
        height: 20px;
        color: rgb(0 0 0 / 0.58);
      }
    }

    .arrow_icon {
      color: #fff;
    }
  }

  .numbers_fonts {
    font-size: 32px;
    font-weight: 600;
    line-height: 41.6px;
    letter-spacing: -0.05em;
    text-align: left;
  }
  .number_percentage {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    padding-left: 10px;
    span {
      padding-left: 15px;
    }
  }
  .revenue_numbers {
    color: #15be53;
  }
  .refund_numbers {
    color: #ff006b;
  }
  .caption_fonts {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    @media (max-width: 1400px) {
      font-size: 11px;
      line-height: 18px;
    }
  }
  .more_info_button {
    margin-top: 10px;
    color: white;
  }
  .icons {
    font-size: 50px;
    //opacity: 0.3;
    padding-bottom: 20px;
  }
  .revenue-cards {
    overflow: hidden;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid #e8ecf0;
    box-shadow: 0px 2px 2px 0px #344b5b0f;
  }

  .accodion_cards {
    box-shadow: 0px 2px 2px 0px #344b5b0f;
    border: 1px solid #e8ecf0;
    margin-left: 20px !important;
    background: #ffffff;
    .MuiSvgIcon-root {
      color: #0395ff;
    }
  }
  .accodion_summary {
    border: 1px solid #e8ecf0;
    padding-top: 10px;
    padding-bottom: 10px;
    .Mui-expanded {
      padding-bottom: 0px;
      margin: 0;
    }
  }
  .total-revenue-bgclr {
    background: linear-gradient(135deg, #28a745, #218838);
  }
  .refund-bgclr {
    background: linear-gradient(135deg, #17a2b8, #3f7880);
  }
  .expired-subscription-bgclr {
    background: #fcae7c4d;
    border: 1px solid #fcae7c;
  }
  .pending-subscription-bgclr {
    background: #d6f6ff4d;
  }
  .cancelled-subscription-bgclr {
    background: #e83e8c26;
    border: 1px solid #e83e8c;
  }
  .renewals-subscription-bgclr {
    background: #ffe6994d;
    border: 1px solid #ffe699;
  }
  .reminder-subscription-bgclr {
    background: #f9ffb54d;
    border: 1px solid #ffe699;
  }
  .active-users-bgclr {
    background: #b3f5bc4d;
    border: 1px solid #b3f5bc;
  }
  .new-users-bgclr {
    background: #d1bdff4d;
    border: 1px solid #d1bdff;
  }
  .inactive-users-bgclr {
    background: #e83e8c26;
    border: 1px solid #e83e8c;
  }
  .total-properties-bgclr {
    background: #0395ff66;
    border: 1px solid #0395ff;
  }
  .properties-review-bgclr {
    background: #0395ff33;
    border: 1px solid #0395ff;
  }
  .active-properties-bgclr {
    background: #0395ff0d;
    border: 1px solid #0395ff;
  }
  .inactive-properties-bgclr {
    background: #0395ff4d;
    border: 1px solid #0395ff;
  }
  .new-properties-bgclr {
    background: #0395ff1a;
    border: 1px solid #0395ff;
  }
  .category_header {
    font-size: 16px;
    font-weight: 500;
    line-height: 20.8px;
    text-align: left;
    color: #0395ff;
  }
}
.date_filter_card {
  border: 1px solid #e8ecf0;
  box-shadow: 0px 10px 10px 0px rgb(32 38 42 / 33%);
  border-radius: 8px;
}
.asOnDate {
  color: #868686;
}
