.background_img {
  // background-image: url("../assets/image\ 1.WebP");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 10% 0 5%;
  z-index: 0;
}
.container_spacing {
  padding: 40px;
}

.button_clr1 {
  // background: #15be53 !important;
  background: #0b652c !important;
}

.signin_button {
  flex-direction: row;
  align-items: flex-start;
  padding: 10px 40px;
  font-weight: 600;
  border-radius: 29px;
  color: #ffffff;
  border: none;
  letter-spacing: 0.05em;
}

.risk_button {
  background: #ffffff !important;
  color: #000000 !important;
  border: 1px solid #2ac465;
}

.sub_header {
  font-size: 30px;
  line-height: 39px;
  font-weight: 600;
  color: #0d120b;
}

.header_text_underline {
  text-underline-offset: 8px;
  text-decoration-line: underline;
  text-transform: uppercase;
}

.subTitle {
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #4c524a;
}

.notify_header {
  //   color: #0395ff;
  color: #005b9e;
  font-size: 16px;
  line-height: 21px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-bottom: 25px;
}

.alert_section {
  .description_text {
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
    color: #4c524a;
  }
}

.alert_section .protected_btn,
.alert_section .protected_btn:hover,
.alert_section .protected_btn:focus,
.alert_section .protected_btn:visited,
.alert_section .protected_btn:active,
.alert_section .protected_btn:active:hover,
.alert_section .protected_btn:active:focus {
  //   background: #15be53;
  background: #0b652c;
  border-radius: 29px;
  padding: 15px 40px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.05em;
  border: none;
  outline: none;
  box-shadow: none;
}

.bottom_spacing {
  padding: 70px 0;
}

.linear_bg {
  background: linear-gradient(266.55deg, #8fda4f -45.17%, #0395ff 109.5%);
  box-shadow: 0px 30px 70px -10px rgba(52, 75, 91, 0.29);
  border-radius: 10px !important;
  border: none !important;
  outline: none !important;
  color: #ffffff;
  padding: 70px 0;
}

.propertyFraud_section {
  .privateGuardList {
    &.card-title {
      font-size: 30px;
      line-height: 39px;
    }
  }

  p {
    &.card-text {
      font-size: 18px;
      line-height: 25px;
    }

    &.label {
      font-size: 18px;
      line-height: 25px;
    }
  }

  h3 {
    &.value {
      font-size: 40px;
      line-height: 52px;
    }
  }
}

.list {
  li {
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: #4c524a;
  }
}

.protection_list {
  li {
    &:before {
      content: "";
      width: 20px;
      height: 20px;
      border-radius: 50px;
      background: url("../assets/icons/close.svg") rgba(255, 0, 107, 0.5);
      background-position: center;
      background-repeat: no-repeat;
      background-size: 12px;
      position: absolute;
      left: -30px;
    }
  }
}

.reasons_list {
  li {
    &:before {
      content: "";
      width: 20px;
      height: 20px;
      border-radius: 50px;
      background: url("../assets/icons/check.svg") rgba(21, 190, 83, 0.5);
      background-position: center;
      background-repeat: no-repeat;
      background-size: 12px;
      position: absolute;
      left: -30px;
    }
  }
}

.para_text {
  text-align: start;
  margin-left: 120px;
}

.homepage_logos {
  // padding-top: 50px;
  max-width: 640px;
  // width: 15%;
  // aspect-ratio: 3/2;
  object-fit: contain;
  opacity: 50%;
}
.as_seen_on {
  img {
    opacity: 50%;
  }
  span {
    opacity: 80%;
  }
}

@media only screen and (max-width: 991.98px) {
  .notify_header {
    font-size: 12px;
    line-height: 16px;
  }

  .content-spacing {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .as_seen_on {
    justify-content: center;
  }
}
