.signIn-box {
  background-color: #f6f9fc;
  padding: 50px;

  h6 {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #ffffff;
  }
}
.border_clasess {
  border: 1px solid #15be53;
  border-radius: 6px 6px 10px 10px;
}
.formHeader {
  background-color: #15be53;
  font-size: 12px !important;
  color: white !important;
}

.accountBoxWrapper {
  padding: 40px 50px;

  h3,
  .title_texts {
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: #0d120b;
    margin-bottom: 15px;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #4c524a;
    max-width: 51%;
    margin: 0 auto 40px;

    &.Mui-error {
      font-size: 12px;
      line-height: 18px;
      max-width: 100%;
      margin: 0;
    }
  }

  &.findPropertyBoxWrapper p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    // color: #4c524a;
    max-width: 100% !important;
    margin: 0 auto 40px;

    &.Mui-error {
      font-size: 12px;
      line-height: 18px;
      max-width: 100%;
      margin: 0;
    }
  }

  .para {
    max-width: 100% !important;
  }

  .createAcBtn {
    button {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #ffffff;
      padding: 15px 40px;
    }
  }

  .tokenCodeText {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #4c524a;
  }

  .otpBoxWrapper {
    display: flex;
    justify-content: center;
    flex-flow: column;
    align-items: center;

    .otpBox {
      .MuiInputBase-formControl {
        height: 100%;

        input {
          padding: 5px;
          text-align: center;
          font-weight: 600;
          font-size: 20px;
          line-height: 55px;
          color: #0d120b;
        }
      }
    }
  }
}

.stepBtn {
  button {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #ffffff;
    padding: 15px 40px;
  }
}

.sign-textField {
  .MuiInputBase-root {
    background: #f6f9fc;
  }

  width: 100%;

  fieldset {
    border: 1px solid rgba(52, 75, 91, 0.1) !important;
  }

  .Mui-error {
    fieldset {
      border: 1px solid #d32f2f !important;
    }
  }

  label {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #858788;
  }

  label {
    &.Mui-error {
      color: #d32f2f !important;
    }
  }
}

.phone-number-input {
  background-color: #f6f9fc;
  display: flex;
  flex-direction: row;
  align-items: center !important;
  height: 3.3rem;
  padding: 0 10px !important;
  border: 1px solid rgba(52, 75, 91, 0.1) !important;
  border-radius: 3px;

  .MuiTypography-root {
    display: flex;
    align-items: center;
    width: 35px;
    height: 35px;
    font-size: 17px !important;
  }

  .PhoneInput {
    width: 100%;
    height: 100%;

    // .PhoneInputCountry {
    //   display: none;
    // }

    .PhoneInputInput {
      height: 100%;
      background-color: #f6f9fc;
      border: none;
      outline: none;
    }
  }

  .show-disabled-text {
    .PhoneInputInput {
      color: rgba(0, 0, 0, 0.38);
    }
  }

  .PhoneInputCountryIcon--border,
  .PhoneInputCountryIcon--border:focus {
    box-shadow: none !important;
    background-color: transparent !important;
  }
}

.phone-error {
  border: 1px solid red !important;
}

.text-area-field {
  textarea {
    border-radius: 5px;
    background-color: #f6f9fc;
    outline: none;
    border: 1px solid #e1e1e1;
    padding: 10px 15px;
  }

  // .MuiInputBase-root {
  //   background: #f6f9fc;
  // }

  // width: 100%;

  // fieldset {
  //   border: 1px solid rgba(52, 75, 91, 0.1) !important;
  // }

  // .Mui-error {
  //   fieldset {
  //     border: 1px solid #d32f2f !important;
  //   }
  // }

  // label {
  //   font-weight: 400;
  //   font-size: 14px;
  //   line-height: 20px;
  //   color: #4c524a;
  // }

  // label {
  //   &.Mui-error {
  //     color: #d32f2f !important;
  //   }
  // }
}

.MuiFormLabel-root {
  &.Mui-focused {
    color: #121212 !important;
  }
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
  &.Mui-completed {
    color: #15be53 !important;
  }
}

.propertyList {
  padding: 0 0 16px 0;
  border-bottom: 1px solid rgba(199, 199, 199, 0.25);

  .btn-primary {
    &.selectPropertyBtn {
      width: 100%;
      color: #fff;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0.05em;
      line-height: 20px;
      text-transform: uppercase;
      background: #15be53 !important;
      border: none !important;
      box-shadow: none !important;
      border-radius: 30px !important;
      padding: 10px 30px;
    }
  }

  &:last-of-type {
    border-bottom: none;
  }
}

.next_button {
  flex-direction: row;
  align-items: flex-start !important;
  padding: 15px 40px !important;
  font-weight: 600 !important;
  gap: 10px;
  border-radius: 29px !important;
  color: #ffffff !important;
  border: none !important;
  background: #15be53 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  letter-spacing: 0.05em;
  text-transform: uppercase !important;
}

.stepWrapper {
  .stepWrapperItem {
    .stepWrapperLabel {
      .MuiStepLabel-iconContainer {
        svg {
          fill: transparent;
          border: 2px solid #0395ff;
          border-radius: 50px;
          width: 30px;
          height: 30px;
          border-radius: 50px;

          text {
            fill: #0395ff;
            stroke: none;
            font-family: "IBM Plex Sans", sans-serif !important;
            font-weight: 600;
            font-size: 16px;
            line-height: 30px;
            color: #ffffff;
          }
        }

        &.Mui-active {
          svg {
            fill: #0395ff;
            border: none;
            width: 30px;
            height: 30px;
            border-radius: 50px;

            text {
              fill: #ffffff;
            }
          }
        }

        &.Mui-completed {
          svg {
            fill: #15be53;
          }
        }
      }

      .MuiStepLabel-label {
        &.Mui-active {
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          text-align: center;
          text-transform: uppercase;
          color: #4c524a;
          opacity: 1;
        }

        opacity: 0.5;
      }
    }

    .Mui-active {
      .MuiStepConnector-line {
        border: 1px solid #0395ff !important;
      }
    }

    .Mui-completed {
      .MuiStepConnector-line {
        border: 1px solid #15be53 !important;
      }
    }
  }
}

.mobileApp_Wrapper p {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #4c524a;
}

.otp {
  input {
    outline: none !important;

    &:focus-visible {
      border: 1px solid #0395ff !important;
      box-shadow: 0px 0px 4px rgba(3, 149, 255, 0.35) !important;
      background-color: #ffffff !important;
    }
  }
}

.cropContainer {
  position: relative;
  width: 100%;
  height: 220px;
}

.cropImg_center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.infoForm_Wrapper {
  padding: 40px 24px;

  .formSeparator {
    // margin: 40px -24px;
    border-top: 1px solid #e8ecf0;
  }

  .infoTitle {
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    color: #0d120b;
  }

  .infoLabel {
    max-width: 100%;
    font-size: 15px;
    margin: 0;
    font-weight: 500;
  }
}

.sign-container {
  width: 100%;
  height: 100%;
  padding: 15px;

  // top: 15px;
  .sigBlock {
    width: 100%;
    height: 100%;
    background-color: #fff;

    .sigPad {
      width: 100%;
      height: 100%;
    }
  }

  .refreshSign {
    background: #0395ff !important;
    box-shadow: 0px 3px 6px rgba(3, 149, 255, 0.26);
    min-width: 40px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
}

.MuiButton-textPrimary {
  &.floatButton {
    position: absolute;
    top: -80px;
    left: 50px;
    transform: translate(-50%, -50%);
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #0395ff;
  }

  &.skipBtn {
    position: absolute;
    bottom: 0;
    left: 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #0395ff;
    transform: translate(0%, 8%);
  }
  &.admin_skip_btn {
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #001ef4;
    transform: translate(0%, 8%);
  }

  &.bimetricSkipBtn {
    position: absolute;
    bottom: 0;
    left: 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #0395ff;
    transform: translate(0%, -54%);
  }

  &.propertyListFloatButton {
    position: absolute;
    bottom: 40px;
    left: 25px;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #0395ff;
  }
}

.FinishBtn {
  position: relative;
  top: -40px;
  left: -50px;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.MuiButton-textPrimary {
  &.backButton {
    position: relative;
    left: 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #0395ff;
  }
}

.googleAutoComplete {
  background: #f6f9fc;
  padding: 10px;
  width: 100%;
  border: 1px solid rgba(52, 75, 91, 0.1) !important;
  height: 3.5rem;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.prefixInput {
  p {
    margin: 0;
    font-family: IBM Plex Sans, sans-serif;
    font-size: 1rem;
    line-height: 1.5;
  }
}

.verifiedOwnerbadge {
  position: absolute;
  top: -20px;
  left: -20px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 2em;
    height: 2em;
    color: #15be53;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  appearance: initial;
  -moz-appearance: textfield;
}

.beneficiary-form {
  max-width: 90%;

  .action-btn {
    display: flex;
    justify-content: flex-end;
  }

  .stepBtn,
  .stepBtn:hover,
  .stepBtn:active {
    margin: 5px;
    background-color: #15be53;
    color: #ffffff;
  }
}

.monthly_Card {
  margin: 0 0px 125px;
}

.dicsounted_monthly_Card {
  margin: 0 0px 100px;
}

.MuiButton-textPrimary.floatButton {
  top: -63px;
}

.MuiButton-textPrimary.bimetricSkipBtn {
  top: -63px;
}

.MuiButton-textPrimary.skipBtn {
  top: -63px;
}

.servicer-signup-form p {
  max-width: 80%;
}

@media only screen and (min-width: 901px) and (max-width: 980px) {
  .monthly_Card {
    margin: 0 0px 125px;
  }
}

@media only screen and (max-width: 767px) {
  .signIn-box {
    padding: 15px !important;

    .accountBoxWrapper {
      padding: 15px;

      .createAcBtn button {
        font-size: 14px;
        padding: 10px 30px;
      }
    }
  }

  .MuiButton-textPrimary {
    &.floatButton {
      top: -40px;
    }
  }
}

@media only screen and (max-width: 599.98px) {
  .full_width_btn {
    width: 80%;
    margin: 10px 0 !important;
  }
}
@media only screen and (min-width: 567px) and (max-width: 596px) {
  .text-fix {
    max-width: 80% !important;
  }
}
@media only screen and (max-width: 567px) {
  .accountBoxWrapper {
    padding: 14px !important;

    h3 {
      font-size: 18px;
    }

    p {
      font-size: 12px;
      max-width: 100%;
      margin: 0px;
      padding: 10px 10px !important;
    }

    .button {
      padding: 10px 20px !important;
    }
  }

  .stepWrapper {
    .stepWrapperItem {
      .stepWrapperLabel {
        .MuiStepLabel-labelContainer {
          display: none;
        }

        .MuiStepLabel-iconContainer {
          svg {
            width: 27px !important;
            height: 27px !important;
          }
        }
      }
    }
  }

  .next_button {
    padding: 10px 20px !important;
    font-size: 14px !important;
    line-height: 15px !important;
  }

  .MuiButton-textPrimary {
    &.floatButton {
      top: -30px;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    // &.skipBtn {
    //   bottom: -60px;
    //   left: 50%;
    //   // transform: translate(-50%, -50%);
    // }

    &.backButton {
      top: 34px;
      left: -15%;
      transform: translate(-50%, -50%);
    }
  }
}

@media only screen and (max-width: 410px) {
  .MuiButton-textPrimary {
    &.bimetricSkipBtn {
      bottom: -10px;
      left: 10%;
      transform: translate(-54%, -54%);
    }
  }
}

/* Phone number Input  */
.filter {
  .phone-number-input {
    background-color: #ffffff;
  }

  border: 1px solid rgb(0 0 0 / 25%) !important;
}

.filter {
  .PhoneInput .PhoneInputInput {
    background-color: #ffffff;
  }
}

@media (max-width: 768px) {
  // Tablet and mobile screens
  .zendesk-spacing {
    margin-bottom: 100px !important;
  }
}
