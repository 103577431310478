.navbar {
  font-family: "IBM Plex Sans", sans-serif;
  color: white;
  font-weight: 600;
}

.footer_body {
  padding: 80px 0px 0px;
  background-color: #ffffff;

  .separator {
    width: 100%;
    opacity: 0.1;
    border: 1px solid #344b5b;
    margin: 30px;
  }
}

.footer_nav {
  padding: 35px 0 30px;
  display: flex;
  justify-content: center;

  a {
    font-weight: 600;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #4c524a;
    text-decoration: none;
  }
}

.coyright_bar {
  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #4c524a;
  }
}

.footer_text {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #4c524a;
}

.divider_block {
  text-align: start;
  padding: 25px 125px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
}
