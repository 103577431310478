#settings-menu {
  .setting-menu-item {
    width: 100%;
  }

  .setting-menu-box {
    display: flex;
    justify-content: center;
    align-items: center;

    .setting-menu-text {
      margin-left: 10px;
    }
  }

  .setting-menu-box:hover {
    color: #0395ff;
    stroke: #0395ff;
    stroke-width: 0.55;
  }

  .setting-menu-item-wrap {
    width: 200px;
  }

  .setting-menu-box-wrap {
    display: flex;
    justify-content: center;

    .setting-menu-text-wrap {
      margin-left: 10px;
    }
  }

  .setting-menu-box-wrap:hover {
    color: #0395ff;
    stroke: #0395ff;
    stroke-width: 0.55;
  }
}

#pagination-container {
  display: flex;
  justify-content: center;
}

#custom-pagination {
  .Mui-selected {
    border: 1px solid rgba(21, 190, 83, 1);
    background-color: rgba(255, 255, 255, 1) !important;
    color: rgba(21, 190, 83, 1);
  }

  .MuiPaginationItem-previousNext,
  .MuiPaginationItem-firstLast {
    border: 1px solid transparent;
    background-color: rgba(255, 255, 255, 1) !important;
    color: rgba(21, 190, 83, 1);
  }

  .Mui-disabled {
    color: rgba(188, 197, 204, 1);
  }
}

.doc-status {
  cursor: pointer;
}

.doc-status:hover {
  text-decoration: underline;
}

.preview-file {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px !important;
  width: 100%;
  border-radius: "6px";

  .preview-url {
    color: blue;
  }

  span {
    cursor: pointer;
  }
}

.dropzone-items {
  max-height: 260px;
  overflow: hidden;
  overflow-y: auto;
}

.dropzone-items::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.ucc-form {
  .Mui-error {
    word-break: break-word;
  }
}

.label-customer {

  background-color: #5cb85c;
  border-radius: 26px;
  text-transform: capitalize !important;
  color: white;
  margin-top: 22px;
  word-break: keep-all !important;
  // marign-bottom: 16px;
  padding: 4px 8px;
  // display: inline-block !important;
}


@media only screen and (max-width: 400px) {
  .upload-req-dialog-action {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;

    .popup_Btn {
      padding: 8px 16px !important;
      font-size: 13px !important;
    }
  }
}