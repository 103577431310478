.product_comaprison_table {
  font-family: "IBM Plex Sans" !important;
  .mabrypro {
    font-family: "Mabry Pro" !important;
  }
  .bottom_table_text {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #0d120b;
    margin-top: 40px;
    margin-left: 20px;
  }

  .ep_logo_mobile {
    width: 92px !important;
    height: 25px !important;
    max-width: none !important;
    // padding: 0 8px;
    // margin-right: 35px;
  }
  .check_img {
    width: 20px !important;
    height: 20px !important;
    margin-top: 0 !important;
    max-width: none !important;
  }
  .dash_img,
  .dash_img_ast {
    padding: 0 30px;
  }
  .dash_img {
    padding: 0 30px;
  }

  .ep_logo_table {
    width: 120px !important;
    height: 35px !important;
    max-width: none !important;
  }

  .column_headers {
    font-size: 14px !important;
    font-weight: 600 !important;
    text-align: center !important;
    padding: 35px 18px !important;
    line-height: 16.8px !important;
  }
  .row_texts {
    font-size: 15px;
    font-weight: 400;
    line-height: 22.5px;
    padding: 30px 20px !important;
    color: #0d120b;
  }
  .include_text {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #4c524a;
    padding: 0 10px;
  }
  .spacing1 {
    padding: 0 15px !important;
  }
  .table_border {
    border-left: 1px solid #d1d1d1 !important;
    border-right: 1px solid #d1d1d1 !important;
  }
  .table_bottom_border {
    border-bottom: 1px solid #d1d1d1 !important;
  }
  .table_top_border {
    border-top: 1px solid #0000 !important;
  }
  .divider_line {
    background: #959595;
    height: 50% !important;
    width: 2px !important;
    margin: 10px;
  }
  .header_line {
    text-transform: uppercase;
    border-right: 2px solid #d8dde1;
    flex: 1;
    display: flex;
    justify-content: center;
    padding: 0px 10px;
  }
  .header_line:last-child {
    border-right: none !important;
  }
  .info_text {
    font-size: 12px !important;
    line-height: 18px !important;
    // padding: 15px 0px;
    padding: 0px 10px;
    margin: 0;
    // text-align: center;
    flex: 1;
    display: flex;
    justify-content: center;
    border-right: 2px solid #d8dde1;
  }
  .info_text:last-child {
    border: none !important;
  }
  .mobile_rows {
    background: white !important;
    padding: 10px;
  }
  .mobile_headers {
    background: white;
    font-weight: 600;
    font-size: 12px;
    text-align: center;
    line-height: 14.4px;
    // padding: 10px 10px 0px;
    z-index: 1;
  }
  .mg_img {
    width: 100% !important;
  }
  @media only screen and (max-width: 767px) {
    .check_img {
      width: 16px !important;
      height: 16px !important;
    }
  }

  @media only screen and (max-width: 455px) {
    // .ep_logo_mobile {
    //   margin-right: 25px;
    // }
    .dash_img_ast {
      padding: 0 15px;
    }
    .dash_img {
      padding: 0 30px;
    }
  }

  @media only screen and (max-width: 420px) {
    .include_text {
      font-size: 10px;
      padding: 0 5px;
    }
    .spacing1 {
      padding: 0 2px !important;
    }
    .info_text {
      padding: 10px;
    }
    // .ep_logo_mobile {
    //   margin-right: 0px;
    // }
    .dash_img_ast {
      padding: 0 15px;
    }
  }

  @media only screen and (max-width: 375px) {
    // .mobile_headers {
    //   font-size: 9px !important;
    //   line-height: 12px !important;
    //   padding: 5px !important;
    // }
    .divider_line {
      margin: 10px 0px;
    }
    .dash_img {
      padding: 0 20px;
    }
    .dash_img_ast {
      padding: 0 10px;
    }
  }
}
