.lennar-logo {
  // width: "auto";
  height: 36px;
  margin: 20px 10px !important;
  // aspect-ratio: 8 / 2;
  object-fit: contain;
}
.property_bg_img1 {
  background-image: url("../assets/lennar/property_bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 60vh;
  width: 100%;
}
.lennar-wrapper {
  p {
    font-family: "Mabry Pro";
    font-size: 20px;
    color: #2d2d2d;
  }
}

.property_heading {
  font-family: "Reckless Neue" !important;
  font-size: 64px !important;
  line-height: 76.78px;
}

.paragraph_texts {
  font-size: 20px;
  line-height: 30px;
  padding: 0px 10px;
  margin: 0px 15px;
  max-width: 690px;
  color: #ffffff !important;
}
.choose_us_block {
  padding-right: 20px !important;
  padding-left: 40px !important;
}
.choose_us_header {
  font-family: "Reckless Neue" !important;
  font-size: 38px !important;
  line-height: 46px;
  padding: 28px 0px 10px !important;
  margin-bottom: 0;
  font-weight: 400;
}
.choose_us_text {
  font-size: 22px !important;
  line-height: 36px;
  font-weight: 400;
  padding: 10px 80px;
}
.pricing_brerakdown_txt {
  font-family: " Reckless Neue" !important;
  font-weight: 400;
  font-size: 38px !important;
  line-height: 46px;
  text-transform: none !important;
  margin: "35px 20px 15px";
}
.pricing_spaceing {
  padding-right: 116px !important;
}
.continue_payment_btn {
  font-family: "Mabry Pro" !important;
  font-weight: 400;
  // flex-direction: row;
  padding: 20px 100px !important;
  color: #ffffff !important;
  gap: 10px;
  border-radius: 29px !important;
  border: none !important;
  font-size: 20px !important;
  line-height: 20px !important;
  text-transform: none !important;
  background-color: #2d2d2d !important;
}
.copy_right_texts {
  font-family: "Mabry Pro" !important;
  padding: 5px 0px;
  font-size: 14px !important;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  color: #767676 !important;
}
.bottom_border {
  position: relative;
  box-shadow: 0px 1px 0px 0px #dce4ec, 0px -1px 0px 0px #dce4ec;
}
.stand_out_section {
  .mabrypro {
    font-family: "Mabry Pro" !important;
  }
  .notify_header {
    color: #005b9e;
    font-size: 16px;
    line-height: 21px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    margin-bottom: 25px;
  }
  .subTitle {
    font-family: "IBM Plex Sans", sans-serif !important;
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #4c524a;
  }
}

@media only screen and (max-width: 768px) {
  .property_heading {
    font-family: "Reckless Neue" !important;
    font-size: 40px !important;
    line-height: 40px;
  }
  .paragraph_texts {
    font-size: 16px !important;
    line-height: 20px;
  }
  .choose_us_header {
    text-align: center;
  }
}

@media only screen and (max-width: 1600px) {
  .choose_us_text {
    padding: 10px 50px;
  }
}

@media only screen and (max-width: 1300px) {
  .pricing_brerakdown_txt {
    font-size: 34px !important;
  }
  .choose_us_header {
    padding: 28px 0px 10px;
    font-size: 34px !important;
  }
  .choose_us_text {
    font-size: 20px !important;
    padding: 5px 30px;
    line-height: 34px !important;
  }
}

@media only screen and (max-width: 1044px) {
  .property_bg_img1 {
    height: 35vh;
  }
  .pricing_brerakdown_txt {
    font-size: 32px !important;
  }
  .choose_us_header {
    font-size: 32px !important;
  }
  .choose_us_text {
    font-size: 18px !important;
    padding: 5px 10px;
    line-height: 32px !important;
  }
  .pricing_spaceing {
    padding-left: 3rem !important;
    padding-right: 101px !important;
  }
}
@media only screen and (max-width: 991.98px) {
  .stand_out_section {
    .sub_header {
      font-size: 20px;
      line-height: 26px;
      margin-bottom: 15px;
    }
  }
}
@media only screen and (max-width: 900px) {
  .pricing_brerakdown_txt {
    font-size: 38px !important;
    line-height: 46px;
  }
  .choose_us_header {
    font-size: 38px !important;
    line-height: 46px;
    padding: 28px 0px 10px;
  }
  .choose_us_text {
    font-size: 22px !important;
    line-height: 36px !important;
    padding: 10px 30px;
  }
}

@media only screen and (min-width: 768px) {
  // .property_bg_img1 {
  //   height: 60vh;
  // }
  .choose_us_header {
    text-align: center;
  }
}

@media only screen and (max-width: 768px) {
  .pricing_brerakdown_txt {
    font-size: 36px !important;
    line-height: 42px;
  }
  .choose_us_header {
    text-align: center;
    font-size: 36px !important;
    line-height: 42px;
    padding: 35px 0px 10px;
  }
  .choose_us_text {
    line-height: 33px !important;
    padding: 10px;
  }
}

@media only screen and (max-width: 600px) {
  .property_bg_img1 {
    height: 50vh;
  }
}
@media only screen and (max-width: 582px) {
  .lennar-wrapper {
    .pricing {
      font-size: 18px !important;
    }
  }
  .choose_us_header {
    padding: 28px 0px 10px;
    font-size: 34px !important;
  }
  .choose_us_text {
    font-size: 20px !important;
    line-height: 26px !important;
  }
  .copy_right_texts {
    font-size: 12px !important;
  }
}
@media only screen and (max-width: 482px) {
  .lennar-logo {
    // width: "auto";
    height: 30px;
    margin: 20px 10px !important;
    // aspect-ratio: 8 / 2;
    object-fit: contain;
  }
  .property_heading {
    font-family: "Reckless Neue" !important;
    font-size: 34px !important;
    line-height: 34px;
    max-width: 100%;
  }
  .paragraph_texts {
    font-size: 16px !important;
    line-height: 20px !important;
    max-width: 100%;
  }

  .continue_payment_btn {
    padding: 20px 50px !important;
    font-size: 18px !important;
    line-height: 18px !important;
  }
}
@media only screen and (max-width: 415px) {
  .choose_us_block {
    padding-right: 20px !important;
    padding-left: 40px !important;
  }
  .pricing_brerakdown_txt {
    font-size: 32px !important;
  }
  .choose_us_header {
    padding: 28px 0px 10px;
    font-size: 32px !important;
  }
  .choose_us_text {
    font-size: 18px !important;
    line-height: 24px !important;
  }
}
@media only screen and (max-width: 360px) {
  .property_heading {
    font-size: 30px !important;
  }
  .pricing_brerakdown_txt {
    font-size: 30px !important;
  }
  .paragraph_texts {
    font-size: 18px;
    line-height: 30px;
    padding: 0px 10px;
    margin: 0px 15px;
  }
}
