.blogs-content {
  font-family: "IBM Plex Sans" !important;
  padding-bottom: 50px;
  p {
    color: #4c524a;
  }
  .first_section_div {
    padding-bottom: 95px;
    @media (max-width: 767.98px) {
      padding-bottom: 50px;
    }
  }
  .slick-active:nth-child(4n) {
    opacity: 1 !important;
  }
  .first-section {
    background: #3c4c58;
    padding-top: 10rem !important;
    height: 75vh;
    @media (max-width: 1900.98px) {
      @media (max-height: 600.99px) {
        height: 80vh;
      }
    }
    @media (max-width: 1650.98px) {
      @media (max-height: 599.99px) {
        height: 90vh;
      }
      @media (min-height: 1200.99px) {
        height: 45vh;
      }
    }
    @media (min-width: 1650.99px) {
      @media (min-height: 599.98px) {
        height: 55vh;
      }
      @media (min-height: 1200px) {
        height: 45vh;
      }
      @media (max-height: 900.99px) {
        height: 75vh;
      }
    }
    @media (max-width: 820.98px) {
      @media (max-height: 1200.98px) {
        height: 50vh;
      }
      @media (max-height: 900.98px) {
        height: 65vh;
      }
      @media (max-height: 600.98px) {
        height: 90vh;
      }
    }
    @media (max-width: 786.98px) {
      height: 60vh;
      @media (min-height: 1200.98px) {
        height: 45vh;
      }
      @media (max-height: 600.98px) {
        height: 90vh;
      }
      @media (max-height: 400.98px) {
        height: 100vh;
        padding-top: 8rem !important;
      }
    }
    @media (max-width: 480.98px) {
      @media (max-height: 900.98px) {
        height: 40vh;
        padding-top: 8rem !important;
      }
      @media (max-height: 786.98px) {
        height: 55vh;
      }
      @media (max-height: 600.98px) {
        height: 60vh;
      }
      @media (min-height: 799.98px) {
        height: 50vh;
      }
    }
    @media (max-width: 480.98px) {
      @media (max-height: 932.98px) {
        height: 60vh;
      }
      @media (max-width: 420.98px) {
        @media (min-height: 799.98px) {
          height: 45vh;
        }
        @media (min-height: 899.98px) {
          height: 55vh;
        }
      }
    }
    .blog_header {
      font-size: 12px;
      font-weight: 600;
      line-height: 15.6px;
      letter-spacing: 0.1em;
      text-align: left;
      text-decoration-skip-ink: none;
      @media (max-width: 991.98px) {
        text-align: center;
      }
      .blog_seperator {
        color: white;
      }
    }
    .main-text {
      width: 857px;
      height: 104px;
      font-size: 40px;
      font-weight: 500;
      line-height: 52px;
      text-align: left;
      text-transform: capitalize;
      text-decoration-skip-ink: none;

      @media (min-width: 1680px) {
        width: auto;
      }

      @media (max-width: 991.98px) {
        width: auto;
        text-align: center;
      }
      @media (max-width: 768.98px) {
        font-size: 38px;
        line-height: 48px;
        height: auto;
      }
      @media (max-width: 576.98px) {
        font-size: 34px;
        line-height: 40px;
      }
      @media (max-width: 425.98px) {
        font-size: 30px;
      }
    }
    .header_texts {
      padding: 0 30px;
    }
  }
  .first-blog-section {
    background: #3c4c58;
    padding-top: 10rem !important;
    height: 45vh;
    @media (max-height: 599.99px) {
      height: 65vh;
    }
    @media (max-width: 1650.99px) {
      @media (min-height: 1200px) {
        height: 30vh;
      }
      @media (max-height: 900.99px) {
        height: 60vh;
      }
      @media (max-height: 599.99px) {
        height: 70vh;
      }
    }
    @media (max-width: 820.98px) {
      @media (max-height: 1200.98px) {
        height: 45vh;
      }
      @media (max-height: 900.98px) {
        height: 55vh;
      }
      @media (max-height: 599.99px) {
        padding-top: 7rem !important;
        height: 80vh;
      }
    }
    @media (max-width: 480.98px) {
      @media (max-height: 900.98px) {
        height: 40vh;
        padding-top: 8rem !important;
      }
      @media (max-height: 786.98px) {
        height: 45vh;
      }
      @media (max-height: 600.98px) {
        height: 60vh;
      }
    }
  }
  .blog_title {
    margin: 0 auto;
    background-color: transparent !important;
    margin-top: -230px;
    @media (max-width: 786.98px) {
      margin-top: -250px;
      @media (min-height: 1000.98px) {
        margin-top: -210px;
      }
      @media (max-height: 815.98px) {
        margin-top: -180px;
      }
      @media (max-height: 599.98px) {
        margin-top: -210px;
      }
      @media (max-height: 400.98px) {
        margin-top: -70px;
      }
      @media (max-height: 360.98px) {
        margin-top: -170px;
      }
    }
    @media (max-width: 636.98px) {
      margin-top: -200px;
      @media (max-height: 599.98px) {
        margin-top: -190px;
      }
    }
    @media (max-width: 540.98px) {
      @media (max-height: 900.98px) {
        margin-top: -170px;
      }
    }
    .slick-dots {
      position: relative !important;
      margin: 20px 0;
      bottom: 0 !important;
      @media (max-width: 768.99px) {
        margin: 0 auto !important;
      }
    }
    .blog_dots {
      .slick-dots li {
        margin: 0 25px !important;
        @media (max-width: 699.98px) {
          margin: 0 15px !important;
        }
        @media (max-width: 467.98px) {
          margin: 0 8px !important;
        }
      }
      /* Change slick-dots to dashes */
      .slick-dots li button:before {
        content: "——"; /* Use dash instead of dot */
        font-size: 20px; /* Adjust the size of the dash */
        color: gray; /* Default color */
        opacity: 0.5; /* Default opacity */
        @media (max-width: 699.98px) {
          font-size: 15px;
        }
        @media (max-width: 467.98px) {
          font-size: 10px;
        }
      }
    }
    /* Style for active dash */
    .slick-dots li.slick-active button:before {
      color: blue !important; /* Change color for active dash */
      opacity: 1; /* Fully visible for active */
    }

    .card_top {
      background-color: transparent !important;
      a {
        text-decoration: none;
      }
    }
    .new_card_top {
      max-width: 1400px !important;
      // width: 90%;
      .markgroup_img {
        margin-left: 15px !important;
        @media (max-width: 991.98px) {
          margin: 0 15px !important;
        }
        img {
          object-fit: fill !important;
        }
      }

      .slick-dots li button:before {
        // font-size: 9px !important; /* Adjust the size of the dash */
        color: gray; /* Default color */
        opacity: 0.6; /* Default opacity */
        @media (max-width: 699.98px) {
          font-size: 9px !important;
        }
        @media (max-width: 496.98px) {
          font-size: 7px !important;
        }
      }
      .slick-dots li {
        @media (max-width: 536.98px) {
          margin: 0 5px !important;
        }
        @media (max-width: 496.98px) {
          margin: 0 3px !important;
        }
        @media (max-width: 424.98px) {
          margin: 0 !important;
        }
      }
    }
    .blog_card {
      border-radius: 30px 10px 10px 30px;
      overflow: hidden;
      background: white !important;
      display: flex !important;
      height: 55vh;
      @media (max-height: 599.99px) {
        height: auto !important;
      }
      @media (max-width: 1650.98px) {
        @media (min-height: 1200.98px) {
          height: 30vh;
        }
      }
      @media (max-width: 820.98px) {
        @media (max-height: 1200.98px) {
          height: 40vh;
        }
        @media (max-height: 600.98px) {
          height: 60vh;
        }
      }
      @media (max-width: 768.99px) {
        height: auto !important;
        div {
          padding: 0px;
        }
      }
      .markgroup_img {
        @media (max-width: 768.99px) {
          height: 45vh !important;
          @media (min-height: 1000.98px) {
            height: 100% !important;
          }
          @media (max-height: 360.98px) {
            height: 100% !important;
          }
        }
        @media (max-width: 550.98px) {
          height: 30vh !important;
        }
      }
    }
    .news_card {
      border-radius: 30px 10px 10px 30px;
      overflow: hidden;
      background: white !important;
      display: flex !important;
      height: 60vh;
      @media (max-height: 599.99px) {
        height: auto !important;
      }
      @media (min-width: 1360.98px) {
        @media (min-height: 760.98px) {
          height: 55vh;
        }
        @media (min-height: 890.98px) {
          height: 45vh;
        }
        @media (min-height: 1040.98px) {
          height: 40vh;
        }
      }
      @media (max-width: 1024.98px) {
        @media (min-height: 1300.98px) {
          height: 42vh;
        }
        @media (min-height: 1300.98px) {
          height: 35vh;
        }
      }
      @media (max-width: 930.98px) {
        @media (min-height: 1300.98px) {
          height: auto;
        }
      }
      @media (max-width: 820.98px) {
        // @media (max-height: 1200.98px) {
        //   height: 45vh;
        // }
        @media (max-height: 600.98px) {
          height: 60vh;
        }
      }
      @media (max-width: 768.99px) {
        height: auto !important;
        div {
          padding: 0px;
        }
      }
      .markgroup_img {
        @media (max-width: 768.99px) {
          height: 45vh !important;
          @media (min-height: 1000.98px) {
            height: 100% !important;
          }
          @media (max-height: 360.98px) {
            height: 100% !important;
          }
        }
        @media (max-width: 550.98px) {
          height: 30vh !important;
        }
      }
    }
    .blog_content {
      text-align: start;
      padding: 65px 20px 70px;
      @media (max-width: 1180px) {
        padding: 65px 20px 20px !important;
      }
      @media (max-height: 599.99px) {
        // padding: 65px 20px 0px !important;
      }
      @media (max-width: 1048px) {
        padding: 35px 10px 0px !important;
        .card_top {
          font-size: 28px;
          line-height: 37px;
        }
      }
      @media (max-width: 768.99px) {
        @media (max-height: 1024.99px) {
          padding: 20px !important;
        }
      }
      @media (max-width: 767.98px) {
        padding: 45px 55px 20px !important;
      }
      @media (max-width: 576.99px) {
        padding: 45px 35px 10px !important;
      }
      @media (max-width: 360.99px) {
        padding: 45px 25px 5px !important;
      }
    }
    .news_content {
      text-align: start;
      padding: 40px 30px 70px;
      @media (max-width: 1180px) {
        padding: 40px 30px 20px !important;
      }
      @media (max-height: 599.99px) {
        // padding: 65px 20px 0px !important;
      }
      @media (max-width: 1048px) {
        padding: 35px 30px 0px !important;
        .card_top {
          font-size: 28px;
          line-height: 37px;
        }
      }
      @media (max-width: 768.99px) {
        @media (max-height: 1024.99px) {
          padding: 20px 30px !important;
        }
      }
      @media (max-width: 767.98px) {
        padding: 45px 55px 20px !important;
      }
      @media (max-width: 576.99px) {
        padding: 45px 35px 10px !important;
      }
      @media (max-width: 360.99px) {
        padding: 45px 25px 5px !important;
      }
    }
    .news_title_texts {
      font-size: 28px !important;
      line-height: 36px !important;
      @media (max-width: 575.98px) {
        font-size: 20px !important;
        line-height: 26px !important;
      }
    }
    .news_para_texts {
      font-size: 20px !important;
      line-height: 26px !important;
    }
    .para_texts {
      font-size: 24px;
      font-weight: 300;
      line-height: 36px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      padding: 10px 0;
      @media (max-width: 1048.98px) {
        font-size: 22px;
        line-height: 34px;
      }
      @media (max-width: 991.98px) {
        font-size: 20px;
        line-height: 30px;
        padding: 8px 0;
      }
      @media (max-width: 576.98px) {
        font-size: 18px !important;
        line-height: 28px;
        padding: 8px 0;
      }
      @media (max-width: 425.98px) {
        font-size: 17px !important;
      }
    }
  }
  .img_div {
    max-width: 1560px;
    box-shadow: 0px 30px 70px -10px #344b5b4a;
    border-radius: 10px;
    margin: 0 auto;
    margin-top: -230px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 10% 0 5%;
    width: 85%;
    height: 70vh;
    @media (max-width: 1650.98px) {
      @media (min-height: 1200.98px) {
        height: 40vh;
      }
    }
    @media (max-width: 820.98px) {
      @media (max-height: 1200.98px) {
        height: 40vh;
      }
      @media (max-height: 600.98px) {
        height: 60vh;
      }
    }
    @media (max-width: 786.98px) {
      width: 90%;
      margin-top: -250px;
      @media (max-height: 815.98px) {
        margin-top: -180px;
      }
      @media (max-height: 599.98px) {
        margin-top: -210px;
      }
      @media (min-height: 1000.98px) {
        height: 40vh;
      }
      @media (max-height: 400.98px) {
        margin-top: -70px;
      }
    }
    @media (max-width: 636.98px) {
      width: 94%;
      height: 50vh;
      margin-top: -200px;
      @media (max-height: 599.98px) {
        height: 60vh;
        margin-top: -190px;
      }
    }
    @media (max-width: 540.98px) {
      @media (max-height: 900.98px) {
        height: 45vh;
        margin-top: -70px;
      }
    }
  }
  .realEstateImg {
    background-image: url("../../assets/SEO//RealEstateScam.webp");
    @media (max-width: 636.98px) {
      background-image: url("../../assets/SEO//RealEstateScamResponsive.webp");
    }
  }
  .titleLockImg {
    background-image: url("../../assets/SEO/TitleInsurance.webp");
    @media (max-width: 636.98px) {
      background-image: url("../../assets/SEO/TitleInsuranceResponsive.webp");
    }
  }
  .mortgageImg {
    background-image: url("../../assets/SEO/Mortgage.webp");
    @media (max-width: 636.98px) {
      background-image: url("../../assets/SEO/MortgageResponsive.webp");
    }
  }
  .quitClaimImg {
    background-image: url("../../assets/SEO/QuitClaim.webp");
    @media (max-width: 636.98px) {
      background-image: url("../../assets/SEO/QuitClaimResponsive.webp");
    }
  }
  .helocFraudImg {
    background-image: url("../../assets/SEO/HELOC_Fraud.webp");
    @media (max-width: 636.98px) {
      background-image: url("../../assets/SEO/HELOC_FraudResponsive.webp");
    }
  }
  .ProactiveMonitorImg {
    background-image: url("../../assets/SEO/ProactiveMonitorImg.webp");
    @media (max-width: 636.98px) {
      background-image: url("../../assets/SEO/ProactiveMonitorImgResponsive.webp");
    }
  }
  .NewHomeowners {
    background-image: url("../../assets/SEO/NewHomeowners.webp");
    @media (max-width: 636.98px) {
      background-image: url("../../assets/SEO/NewHomeownersResponsive.webp");
    }
  }
  .LoseWallet {
    background-image: url("../../assets/SEO/LoseWallet.webp");
    @media (max-width: 636.98px) {
      background-image: url("../../assets/SEO/LoseWalletResponsive.webp");
    }
  }
  .ForgedDeed {
    background-image: url("../../assets/SEO/ForgedDeed.webp");
    @media (max-width: 636.98px) {
      background-image: url("../../assets/SEO/ForgedDeedResponsive.webp");
    }
  }
  .second_section {
    .content_container {
      width: 58%;
      @media (min-width: 2000.98px) {
        width: 40%;
      }
      @media (max-width: 1024.98px) {
        width: 75%;
      }
      @media (max-width: 768.98px) {
        width: 90%;
      }
    }
    .content_img {
      margin: 50px 0 60px;
      border-radius: 15px;
      width: -webkit-fill-available;
      height: 500px;
      box-shadow: 0px 30px 70px -10px #344b5b4d;

      @media (max-width: 768.98px) {
        height: auto;
      }
      @media (max-width: 532.98px) {
        height: 250px;
      }
    }
    .title_tag_2 {
      font-size: 30px;
      font-weight: 600;
      line-height: 42px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      padding: 10px 0;
      @media (max-width: 576.98px) {
        font-size: 28px;
        line-height: 40px;
        padding: 8px 0;
      }
      @media (max-width: 425.98px) {
        font-size: 26px;
      }
    }
    .title_tag_3 {
      font-size: 18px;
      font-weight: 600;
      line-height: 23.4px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      padding: 10px 0;
      @media (max-width: 576.98px) {
        font-size: 16px;
        line-height: 20px;
        padding: 8px 0;
      }
    }
    .para_texts {
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      padding: 10px 0;
      @media (max-width: 576.98px) {
        font-size: 18px;
        line-height: 28px;
        padding: 8px 0;
      }
      @media (max-width: 425.98px) {
        font-size: 17px;
      }
    }
    .caption_texts,
    .list_content > li {
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      padding: 10px 0;
      @media (max-width: 576.98px) {
        font-size: 18px;
        line-height: 28px;
        padding: 8px 0;
      }
      @media (max-width: 425.98px) {
        font-size: 17px;
      }
    }
    .list_content {
      padding-left: 10px;
      li {
        padding: 1px 0;
        color: #4c524a;
      }
    }
  }
  .story_section {
    margin: 0 auto !important;
    width: 80%;
    @media (max-width: 1442.98px) {
      width: 90%;
      @media (max-width: 1278.98px) {
        width: 100%;
      }
    }
    @media (max-width: 991.98px) {
      padding-top: 0px;
      width: 85%;
    }
    @media (max-width: 786.98px) {
      // padding-top: 70px;
    }
    @media (max-width: 480.98px) {
      // padding-top: 60px;
    }
    .articals {
      height: 45vh;
      @media (min-width: 1600.98px) {
        height: 35vh;
      }
      @media (min-width: 1900.99px) {
        @media (min-width: 1080.99px) {
          height: 35vh;
        }
      }
      @media (min-width: 2000.99px) {
        height: 18vh;
      }
      @media (max-width: 1540.99px) {
        @media (max-height: 850.99px) {
          height: 60vh;
        }
      }
      @media (max-width: 1366.98px) {
        height: 52vh;
        @media (max-height: 599.99px) {
          height: 70vh;
        }
      }
      @media (max-width: 1278.98px) {
        height: 55vh;
        @media (max-height: 1440.99px) {
          height: 30vh;
        }
        @media (max-height: 1366.99px) {
          height: 35vh;
        }
        @media (max-height: 599.99px) {
          height: 80vh;
        }
      }
      @media (max-width: 991.98px) {
        height: 45vh;
        @media (max-height: 1366.99px) {
          height: 25vh;
        }
        @media (max-height: 1200.99px) {
          height: 30vh;
        }
        @media (max-height: 599.99px) {
          height: 60vh;
        }
      }
      @media (max-width: 768.98px) {
        height: auto !important;
        @media (max-height: 1366.99px) {
          height: 27vh !important;
        }
        @media (max-height: 1024.99px) {
          height: 37vh !important;
        }
        @media (max-height: 360.99px) {
          height: 100vh !important;
        }
      }
      @media (max-width: 600.98px) {
        height: auto !important;
      }
    }
    .read_more {
      padding-top: 0 !important;
      @media (max-width: 768.98px) {
        padding-top: 30px !important;
      }
    }
  }
  .read_more_blog {
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #0155b7 !important;
    padding: 10px 0;
    @media (max-width: 576.98px) {
      font-size: 16px;
      line-height: 28px;
      padding: 8px 0;
    }
    a {
      text-decoration: none;
    }
  }
  .news_subTitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #0155b7 !important;
    padding: 8px 0;
    @media (max-width: 576.98px) {
      font-size: 14px;
      line-height: 28px;
      padding: 8px 0;
    }
  }
}
