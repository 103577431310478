#notification-wrapper {
  ul {
    padding: 0;

    li {
      &.notification {
        background-color: #f9f9f9;
        font-size: 13px;
        line-height: 20px;
        border-bottom: 1px solid #ffffff;

        .notification-date {
          font-family: Mabry Pro;
          font-size: 12px;
          font-weight: 400;
          line-height: 15px;
          letter-spacing: 0em;
          text-align: end;
          color: #767676;
          margin-top: 0.3rem;
        }
      }

      &.notification:hover {
        background-color: #2b8d4f !important;
        color: #fff;

        .notification-date {
          color: #fff;
        }

        .notification-link {
          color: #fff;
        }
      }
    }
  }

  .notification-link {
    cursor: pointer;
    color: blue;
  }

  .notification-link:hover {
    text-decoration: underline;
  }

  .mark-all-read {
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 9;
  }

  .notification-message {
    font-size: 13px;
    line-height: 20px;
  }

  .mark-all-read-text {
    cursor: pointer;
    color: #2b8d4f;
    font-size: 15px;
  }

  .mark-all-read-text:hover {
    transition: ease-in-out 250ms;
    transform: scale(1.05);
  }
}

.account-dropdown {
  .userName {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #0d120b;
  }

  .companyName {
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
    text-transform: uppercase;
    color: #15be53;
  }
}

#account-menu {
  ul {
    li {
      &.profile-items {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #0d120b;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .account-dropdown.mobile {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -40px;
    background: #f6f9fc;

    button {
      width: 100%;
      padding: 10px 0;

      .MuiBox-root {
        width: 100%;
        justify-content: flex-end;
        flex-flow: row wrap;
      }
    }
  }
}

.icon_width {
  width: 20px;
}
.active_icon {
  filter: invert(48%) sepia(13%) saturate(3207%) hue-rotate(130deg)
    brightness(95%) contrast(80%);
}
