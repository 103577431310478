.lead-page-content {
  .background_img {
    background-image: url("../assets/image-1.WebP");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 10% 0 5%;
    z-index: 0;
  }

  .first-section {
    padding: 0px !important;
    padding-top: 7rem !important;
    // min-height: 80vh;
    // max-height: 80vh;
    max-height: min-content !important;

    .main-text {
      font-size: 30px;
      line-height: 35px;
    }
  }

  .paragraph_text {
    font-size: 20px;
    line-height: 25px;
    text-align: left !important;
  }

  .how-it-works-btn {
    background-color: #02a8e2;
  }

  .how-it-works-btn:hover {
    background-color: #02a8e2;
  }
  .lead_table {
    max-width: none !important;
  }

  .subTitle {
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #4c524a;
  }

  .box-container {
    // box-shadow: 0px 30px 70px -10px rgba(52, 75, 91, 0.29);
    // border-radius: 10px;
    background-color: rgba(3, 149, 255, 0.7);
    padding: 0px 50px 0px 50px;

    .request-call-label {
      color: #fff;
      font-weight: 600;
      font-size: 20px;
      line-height: 25px;
      text-align: center;
      padding-top: 15px;
      padding-bottom: 5px;
    }

    .request-call-description {
      font-weight: 300;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      color: #fff;
      padding-top: 0px;
      padding-bottom: 10px;
    }

    .request-form {
      padding-top: 0px;
      padding-bottom: 10px;
    }

    .schedule-call-section {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .request-call-footer-description {
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      color: #fff;
    }
  }

  .logos {
    padding-top: 50px;
    max-width: 640px;
  }

  .second-section {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    padding: 0px 150px;

    .first-col {
      border-right: 1px solid #000;
    }

    .second-col {
      border-right: 1px solid #000;
    }

    .section-2-title {
      padding-top: 10px;
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
      color: #02a8e2;
      margin-bottom: 0;
    }

    .section-2-description {
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;
      text-align: center;
      color: #4c524a;
      padding-top: 10px;
    }
  }

  .third-section {
    padding: 115px 150px;

    .sub-text {
      font-weight: 600;
      font-size: 16px;
      line-height: 21px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #02a8e2;
      margin-bottom: 25px;
    }

    .primary-text {
      font-weight: 700;
      font-size: 30px;
      line-height: 50px;
      color: #0d120b;
      margin-bottom: 20px;
    }

    .description {
      font-weight: 300;
      font-size: 20px;
      line-height: 30px;
      color: #4c524a;
      margin-bottom: 40px;
      max-width: 420px;
    }

    .family-img-bedge {
      position: absolute;
      bottom: -30px;
      left: -20px;
    }
  }

  .fourth-section {
    padding-top: 120px;
    padding-bottom: 120px;

    .left-section-footer {
      margin-top: 25px;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: #4c524a;
      // max-width: 430px;
    }

    .listing-title {
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
      color: #0d120b;
      margin-bottom: 15px;
      margin-top: 20px;
      text-align: left;
    }

    .listing-description {
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;
      color: #0d120b;
      text-align: left;
    }
  }

  .fifth-section {
    .background_img {
      background-image: url("../assets/leadpage/fifth_section_img.WebP");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      padding: 10% 0 5%;
      z-index: 0;
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 870px;
    }

    .title {
      font-weight: 600;
      font-size: 16px;
      line-height: 21px;
      text-align: center;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #ffffff;
      margin-bottom: 25px;
    }

    .heading {
      font-weight: 700;
      font-size: 30px;
      line-height: 50px;
      text-align: center;
      color: #ffffff;
      margin-bottom: 20px;
    }

    .description {
      font-weight: 300;
      font-size: 20px;
      line-height: 30px;
      text-align: center;
      color: #ffffff;
      margin-bottom: 50px;
    }
  }

  .sixth-section {
    padding: 120px 150px;

    .sub-text {
      font-weight: 600;
      font-size: 16px;
      line-height: 21px;
      text-align: center;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #02a8e2;
      margin-bottom: 25px;
    }

    .primary-text {
      font-weight: 700;
      font-size: 30px;
      line-height: 50px;
      text-align: center;
      color: #0d120b;
      margin-bottom: 50px;
    }
  }

  .seventh-section {
    padding: 50px 150px 0px 150px;

    .sub-text {
      font-weight: 600;
      font-size: 16px;
      line-height: 21px;
      text-align: center;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #02a8e2;
      margin-bottom: 25px;
    }

    .primary-text {
      font-weight: 700;
      font-size: 30px;
      line-height: 50px;
      text-align: center;
      color: #0d120b;
      margin-bottom: 50px;
    }

    .safety-primary-text {
      font-weight: 700;
      font-size: 26px;
      line-height: 50px;
      color: #0d120b;
    }

    .safety-description {
      font-weight: 300;
      font-size: 20px;
      line-height: 30px;
      color: #4c524a;
      max-width: 421px;
    }

    .footer {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .footer-text {
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        color: #4c524a;
        max-width: 857px;
        margin-bottom: 40px;
      }
    }
  }

  .eigth-section {
    padding: 0 150px 120px 150px;

    .card-body {
      display: flex;
      justify-content: center;
      align-items: center;

      .card-title {
        max-width: 850px;
      }
    }
  }

  .ninth-section {
    padding: 0 150px 120px 150px;
  }

  .tenth-section {
    padding: 0 150px 120px 150px;

    .content-outer-box {
      box-shadow: 0px 30px 70px -10px rgba(52, 75, 91, 0.29);
      border-radius: 10px;

      .content-inner-box {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 70px 20px;
        flex-direction: column;

        .content {
          font-weight: 600;
          font-size: 30px;
          line-height: 39px;
          text-align: center;
          color: #0d120b;
          max-width: 857px;
          margin-bottom: 50px;
        }
      }
    }
  }

  .eleventh-section {
    padding: 3rem 200px 3rem 200px;
    background-color: #02a8e2;

    .heading {
      font-weight: 700;
      font-size: 30px;
      line-height: 35px;
      color: #fff;
      margin-bottom: 30px;
    }

    .column {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }

    .title {
      padding-top: 20px;
      font-weight: 600;
      font-size: 22px;
      line-height: 23px;
      color: #fff;
      margin-bottom: 0;
    }

    .description {
      font-weight: 400;
      font-size: 16px;
      line-height: 25px;
      color: #fff;
    }

    .divider {
      color: #fff;
      opacity: 1;
      margin: 15px 0;
      width: 20%;
    }
  }

  .risk_button {
    background: #ffffff !important;
    color: #000000 !important;
    border: 1px solid #2ac465;
  }

  .twelveth-section {
    padding: 0px 0px;
    height: max-content;

    background-image: url("../assets/leadpage/eleventh_section_img.WebP") !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    // padding: 10% 0 5%;
    z-index: 0;

    .featured-explain {
      padding: 90px 150px;
      background-color: rgba(0, 0, 0, 0.6);
      text-align: center;

      .heading {
        font-weight: 700;
        font-size: 30px;
        line-height: 35px;
        color: #fff;
        margin-bottom: 30px;

        span {
          color: #02a8e2;
        }
      }

      .listing-description {
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        color: #0d120b;
      }

      .box {
        padding: 30px;
        border-radius: 15px;
        height: 100%;
      }
    }
  }

  .lead-footer {
    background-color: #000;

    img {
      width: 250px;
      padding: 1.5rem;
    }
  }

  .bg-black {
    background-color: #000;
  }
}

// @media only screen and (min-width: 1440px) {
//   .lead-page-content {
//     .first-section {
//       max-height: 86vh !important;
//     }
//   }
// }

// @media only screen and (max-width: 1440px) {
//   .lead-page-content {
//     .first-section {
//       max-height: 86vh !important;
//     }
//   }
// }

// @media only screen and (max-width: 1024px) {
//   .lead-page-content {
//     .first-section {
//       max-height: 86vh !important;
//     }
//   }
// }

@media only screen and (max-width: 991.98px) {
  .lead-page-content {
    .second-section {
      .first-col {
        border-right: 0px;
      }

      .second-col {
        border-right: 0px;
      }
    }

    .third-section {
      .description {
        max-width: 100%;
      }
    }

    .fourth-section {
      .left-section-footer {
        max-width: 100%;
      }
    }

    .seventh-section {
      .safety-description {
        max-width: 100%;
      }
    }
  }
}

// @media only screen and (max-width: 915px) {
//   .lead-page-content {
//     .first-section {
//       max-height: min-content !important;
//     }
//   }
// }

@media only screen and (max-width: 901px) {
  .seventh-section {
    .sub-text {
      margin-bottom: 35px !important;
    }
  }
}

@media only screen and (max-width: 768.98px) {
  .lead-page-content {
    .first-column {
      margin-bottom: 30px;
    }

    .second-column {
      margin-bottom: 30px;
    }

    .lead-footer {
      img {
        width: 195px;
      }
    }
  }
}

@media only screen and (max-width: 575.98px) {
  .lead-page-content {
    .third-section {
      padding-top: 50px;
      padding-bottom: 70px;
    }

    .tenth-section {
      .content-outer-box {
        .content-inner-box {
          .content {
            text-align: left;
            font-size: 22px;
            line-height: 25px;
          }
        }
      }
    }

    .sixth-section {
      padding-top: 50px;
      padding-bottom: 0px;
    }

    .seventh-section {
      padding-bottom: 0px;

      .safety-primary-text {
        line-height: 30px;
        margin-bottom: 10px;
      }
    }

    .twelveth-section {
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }
}

@media only screen and (max-width: 525.98px) {
  .lead-page-content {
    .box-container {
      padding: 0px 20px 0px 20px;
    }

    .third-section {
      margin-top: 50px;

      .family-img-bedge {
        width: 200px;
        height: 70px;
      }
    }

    .therteenth-section {
      .heading {
        font-size: 20px;
        line-height: 25px;
      }
    }
  }
}
