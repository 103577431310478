.as_seen_on_section {
  background: #f6f9fc;
  // background: black;
  padding: 0 30px;
  @media (max-width: 480.98px) {
    padding: 0 5px;
  }
  .container-as-seen-on{
    max-width: 2100px;
    margin: 0 auto;
  }
  .as_seen_on_logos {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    padding: 20px 0;
    max-width: 100%;
    @media (max-width: 1024.98px) {
      flex-wrap: wrap;
      justify-content: center;
    }
    @media (max-width: 991.98px) {
      justify-content: center;
    }
  }
  .as_seen_on {
    .as_seen_on_img {
      filter: brightness(0) saturate(100%) invert(0%) sepia(98%) saturate(7490%)
        hue-rotate(30deg) brightness(97%) contrast(98%);
    }
    @media (max-width: 934.98px) {
      width: 100%;
      flex: 1 1 auto !important;
    }
  }

  .logo-container {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    @media (max-width: 934.98px) {
      flex: 1 1 27%;
    }
  }

  .logo-container img {
    max-height: 50px;
    width: auto;
    margin: 0 10px;
    // filter: grayscale(100%);
    opacity: 0.7;
    // transition: all 0.3s ease-in-out;
    filter: brightness(0) saturate(100%) invert(73%) sepia(3%) saturate(756%)
      hue-rotate(163deg) brightness(60%) contrast(93%);

    @media (max-width: 1024.98px) {
      max-height: 40px;
    }
    @media (max-width: 480.98px) {
      max-height: 30px;
    }
  }

  .logo-container img:hover {
    // filter: none;
    opacity: 1;
  }
}
