.menuItemsList {
  max-height: calc(100% - 306px);
  overflow: hidden;
  overflow-y: auto;

  li {
    .menu-item {
      span {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #0d120b;
      }
    }
  }
}
.admin_sidebar {
  max-height: calc(100% - 160px) !important;
  flex: 1 0 auto;
}

.call {
  background: #0395ff;
  box-shadow: none !important;
  border-radius: 20px !important;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  img {
    max-width: 20px;
  }
}
.header_height {
  min-height: 40px !important;
}

@media only screen and (max-width: 767.98px) {
  .menuItemsList {
    max-height: calc(100% - 130px);
    overflow: hidden;
    overflow-y: auto;
  }
}
