.broker-create-account {
  .background_img {
    background-image: url("../../assets/investor/inv_signup.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 0 0 15%;
    z-index: 0;
    height: 80vh;
    @media (max-width: 1024px) {
      @media (max-height: 600px) {
        height: 85vh;
      }
      @media (min-height: 768px) {
        height: 75vh;
      }
      @media (min-height: 900px) {
        height: 45vh;
      }
    }
    @media (min-width: 1280px) {
      @media (max-height: 650px) {
        height: 85vh;
      }
      @media (min-height: 800px) {
        height: 70vh;
      }
      @media (min-height: 950px) {
        height: 60vh;
      }
      @media (min-height: 1150px) {
        height: 45vh;
      }
    }
  }
  .agent_logo {
    max-height: 75px;
  }
  .login_button {
    width: 35%;
  }
  .login-container {
    border-radius: 10px;
  }
  .servicer-signup-form {
    p {
      font-family: IBM Plex Sans, sans-serif;
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 1.66;
      text-align: left !important;
      margin-top: 3px;
    }
  }
  .resetPwd {
    span {
      font-family: IBM Plex Sans;
      font-weight: 500;
      font-size: 12px;
      line-height: 25px;
      text-align: center;
      vertical-align: middle;
      text-decoration: underline;
      text-decoration-style: solid;
      color: #0395ff;
      text-transform: none;
    }
  }
  .agent-properties {
    @media (max-width: 1024px) {
      @media (max-height: 600px) {
        height: 90vh;
      }
    }
  }
  .broker_navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 100px 20px 150px;
    @media (max-width: 1200px) {
      padding: 30px 70px 20px;
    }
  }
  .investor-list {
    min-height: auto !important;
    height: auto;
    margin-bottom: 20px;
  }
  .call_us_btn {
    font-family: IBM Plex Sans;
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
    text-transform: uppercase;
    color: #ffffff;
    text-decoration: none;
    border-radius: 20px;
    padding: 8px 20px;
    margin: 0 3px;
    background: #0155b7;
  }
  .proeprty_status {
    color: #ffffff;
    border-radius: 21px;
    padding: 6px 20px;
    gap: 10px;
    font-family: IBM Plex Sans;
    font-weight: 500;
    font-size: 12px;
    line-height: 10px;
    text-transform: uppercase;
  }
  .sent {
    background: #f7b23b;
  }
  .accepted {
    background: #15be53;
  }
  .agents_buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    button {
      border-radius: 29px;
      padding: 15px 40px;
      gap: 10px;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
    }
    .save-agents-btn {
      display: flex;
      align-items: center;
      background: #0cc04e;
      letter-spacing: 5%;
      border: none;
      text-transform: uppercase;
      color: #0d120b;
    }
    .add-property-btn {
      border-width: 1px;
      border: 1px solid #04152126;
      background: #ffffff;
      color: #0155b7;
    }
  }

  .main-text {
    font-family: IBM Plex Sans;
    font-weight: 600;
    font-size: 48px;
    line-height: 100%;
    letter-spacing: -3%;
    text-align: center;
  }
  .paragraph_texts {
    @media (min-width: 1200px) {
      max-width: fit-content;
    }
  }
  .create_acc_section {
    padding-top: 80px;
    .agent_button {
      display: flex;
      justify-content: end;
      align-items: center;
      position: relative;
      bottom: 0;
      top: 12px;
      button {
        display: flex;
        justify-content: end;
        align-items: center;
        height: 40px;
        width: auto;
        border-radius: 20px;
        padding: 5px 20px;
        gap: 5px;
        background: #f9f9f9;
        border: none;
        font-family: IBM Plex Sans;
        font-weight: 600;
        font-size: 12px;
        line-height: 17px;
        text-transform: uppercase;
        color: #0d120b;
      }
    }
  }
  .agent_button {
    display: flex;
    justify-content: end;
    align-items: center;
    width: 95%;
    padding-bottom: 20px;
    button {
      display: flex;
      justify-content: end;
      align-items: center;
      height: 40px;
      width: auto;
      border-radius: 20px;
      padding: 5px 20px;
      gap: 5px;
      background: #f9f9f9;
      border: none;
      font-family: IBM Plex Sans;
      font-weight: 600;
      font-size: 12px;
      line-height: 17px;
      text-transform: uppercase;
      color: #0d120b;
    }
  }
  .paragraph-text {
    font-family: IBM Plex Sans;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    letter-spacing: 0%;
    text-align: center;
  }
  .create_acc_form_wrapper {
    position: relative;
    margin-top: -250px;
    .login-container {
      max-width: 65%;
      border-radius: 10px;
    }
    .main-container {
      max-width: 80%;
      border-radius: 10px;
    }

    .properties-main-container {
      max-width: 90%;
      border-radius: 10px;
      padding: 50px;
    }
    .box-container {
      padding: 50px 0px 50px 20px;
    }
    .agents_form {
      .MuiInputBase-root {
        height: 46px;
        input {
          height: 13px;
        }
      }
      .phone-number-input,
      .googleAutoComplete {
        height: 46px;
        padding-right: 0px !important;
      }
      .error_border {
        border: 1px solid red !important;
      }

      .dropzone {
        width: 145px;
        height: 145px;
        border-width: 1px;
        border-radius: 8px;
        border: 1px dashed #0155b7;
        background: #f6f9fc;
      }
      .files_texts {
        font-family: IBM Plex Sans;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        padding-top: 20px;
        padding-right: 50px;
      }
      .terms_texts {
        font-family: IBM Plex Sans;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #4c524a !important;
        u {
          color: #4c524a !important;
          font-family: IBM Plex Sans;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          text-decoration: underline;
          text-decoration-style: solid;
        }
      }
      .next_button {
        color: #0d120b !important;
      }
    }
    .MuiInputLabel-root {
      font-family: IBM Plex Sans;
      font-weight: 400;
      font-size: 17px;
      line-height: 20px;
      color: #0d120b !important;
      text-align: left;
    }
    .reset_password {
      .accountBoxWrapper {
        padding: 50px 50px 50px 75px !important;
      }
    }
    .create_password {
      .accountBoxWrapper {
        padding: 20px 0 !important;
      }
    }
  }
  .help-text {
    font-family: IBM Plex Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    color: #2d2d2d;
    margin: 20px 0;
    cursor: pointer;
    text-decoration: underline;
  }
  .standard-phone-field {
    background-color: #fff;
    border: 0px !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5) !important;
    .PhoneInputInput {
      background-color: #fff;
    }
  }
  .standard-select-field {
    .MuiSelect-select {
      background-color: transparent;
    }
  }
  .investor-list {
    background-color: transparent !important;
    th {
      background-color: #f3f4f5 !important;
    }
  }
  .Form_Wrapper {
    label {
      text-align: start;
    }
  }
  .owner_form {
    padding-right: 150px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .content-heading {
      font-family: IBM Plex Sans;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      text-align: end;
      padding-right: 10px;
    }
    .property-textfields {
      width: 45%;
      padding: 0 5px;
      height: 90px;
      .sign-textField {
        margin-top: 15px;
      }
      .phone-number-input {
        margin-top: 15px;
        padding-right: 0 !important;
        border: 1px solid;
        background-color: white;
        .PhoneInputInput {
          padding-left: 5px;
        }
      }
      input {
        background: #ffffff;
        border: 1px solid #344b5b1a;
        color: #0d120b;
        font-family: IBM Plex Sans;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }
    }
    .popup_btn {
      padding: 8px 25px;
      margin-left: 15px;
      margin-top: -5px;
    }
  }
}
.footer_texts {
  display: flex;
  justify-content: center;
  p {
    font-family: IBM Plex Sans;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    width: 88%;
    text-align: center;
  }
}

.approval_title {
  font-family: IBM Plex Sans;
  font-weight: 600;
  font-size: 22px;
  line-height: 100%;
  text-align: center;
  color: #0d120b;
}
.approval_texts {
  font-family: IBM Plex Sans;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #4c524a;
  padding: 5px 9%;
  margin-bottom: 0;
}
.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  max-height: 250px !important;
}
