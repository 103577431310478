.national_host_content {
  font-family: "IBM Plex Sans" !important;
  .light_bg {
    background-color: #f6f9fc !important;
  }
  .top_section_spacing {
    margin-top: -75px;
    @media (max-width: 991.98px) {
      margin-top: 0px;
      padding: 0 30px;
      text-align: center;
    }
  }
  .lead_form {
    @media (max-width: 991.98px) {
      margin-bottom: -500px !important;
    }
  }
  .first-section {
    padding-top: 3rem !important;
    position: relative;
    @media (max-width: 1024px) {
      padding-top: 6rem !important;
    }
    @media (max-width: 991.98px) {
      padding-top: 10rem !important;
      // padding-bottom: 0 !important;
      text-align: center !important;
    }

    .main-text {
      font-size: 40px;
      line-height: 52px;
      font-weight: 500;
      @media (max-width: 687.98px) {
        font-size: 35px !important;
        line-height: 45px !important;
      }
      @media (max-width: 467.98px) {
        font-size: 30px !important;
        line-height: 39px !important;
      }
      @media (max-width: 375.98px) {
        font-size: 27px !important;
        line-height: 37px !important;
        margin: 5px 0;
      }
    }
    .paragraph_text {
      font-size: 24px;
      font-weight: 300;
      line-height: 36px;
      text-align: left;
      @media (max-width: 991.98px) {
        text-align: center;
      }
      @media (max-width: 687.98px) {
        font-size: 22px !important;
        line-height: 33px !important;
      }
      @media (max-width: 350px) {
        font-size: 20px !important;
        line-height: 28px !important;
      }
    }
  }
  .bgblack {
    background: #10151d;
  }
  .header_content {
    padding: 10px 100px;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
    @media (max-width: 767.98px) {
      padding: 10px 50px;
    }
    @media (max-width: 425.98px) {
      padding: 10px;
    }
    .EP_logo {
      width: 220px;
      height: 75px;
      // margin-left: 80px;
      @media (max-width: 1199.98px) {
        margin-left: 10px;
      }
      @media (max-width: 500.98px) {
        img {
          width: 95%;
          height: 95%;
        }
      }
      @media (max-width: 425.98px) {
        left: 20px;
      }
    }
    .contact_section {
      padding: 20px;
      color: white;
      // @media (max-width: 767.98px) {
      //   padding: 0px;
      // }
      .help_texts {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: end;
      }
      .contact_texts {
        font-size: 17px;
        font-weight: 600;
        line-height: 25px;
        text-align: right;
        a {
          text-decoration: none !important;
        }
      }
      .call_btn {
        background: #0155b7;
        border-radius: 20px;
        width: 40px;
        height: 40px;
        padding: 0;
        top: -5px;
        min-width: unset;
      }
    }
  }
  .natioal_host_bg_img {
    background: radial-gradient(
      86.49% 86.49% at 50% 44.95%,
      #171f2c 0%,
      #0c0f15 100%
    );
    height: 840px;
    overflow: hidden;
    @media (max-width: 1600px) {
      height: 770px;
    }

    @media (max-width: 1280px) {
      height: 657px;
    }
    @media (max-width: 991.98px) {
      height: 100%;
    }
  }

  .ep__img-block {
    width: 100%;
    height: 912px;
    position: relative;
    margin-left: 92px;
    @media (max-width: 1600px) {
      margin-left: 60px;
    }
    @media (max-width: 1335px) {
      margin-left: 35px;
    }
    @media (max-width: 1199px) {
      margin-left: -25px;
    }
    @media (max-width: 1280px) {
      height: 715px;
    }
    @media (max-width: 1024px) {
      height: 585px;
      // margin-left: 20px;
    }
    @media (max-width: 991.98px) {
      height: 535px;
      margin-left: 0;
    }
    @media (max-width: 875px) {
      height: 480px;
      margin-left: 0;
      margin-top: 50px;
    }
    @media (max-width: 767px) {
      height: 470px;
      img {
        object-fit: contain !important;
      }
    }
    @media (max-width: 660px) {
      height: 500px;
      margin-top: 0;
    }
    @media (max-width: 591.98px) {
      height: 460px;
    }
    @media (max-width: 477.98px) {
      height: 415px;
    }
    @media (max-width: 390.98px) {
      height: 351px;
    }
    .radio_img {
      // width: 100%;
      height: 100%;
      object-fit: contain;
      position: absolute;
      left: 0;
      right: 20;
      top: 0;
      z-index: 9;
      @media (max-width: 991.98px) {
        width: 100%;
      }
      @media (max-width: 767px) {
        object-fit: cover;
      }
    }
  }
  .ep__bg-logo {
    position: absolute;
    top: 0;
    bottom: auto;
    left: auto;
    // right: -52px;
    @media (max-width: 1710px) {
      right: -90px;
    }
    @media (max-width: 1500px) {
      right: -130px;
    }
    @media (max-width: 1280px) {
      right: -250px;
      // img {
      //   width: 75%;
      // }
    }
    @media (max-width: 991.98px) {
      top: auto;
      bottom: 0;
      left: 0;
      max-width: 60%;
      margin: 0 auto;
      right: -20px;
      img {
        width: 90%;
      }
    }
    @media (max-width: 899.98px) {
      max-width: 65%;
    }
    @media (max-width: 767px) {
      max-width: 75%;
    }
    @media (max-width: 660px) {
      max-width: 85%;
    }
    @media (max-width: 540.98px) {
      max-width: 95%;
    }
    @media (max-width: 457.98px) {
      max-width: 100%;
      right: 0px;
    }
  }
  .buttons_section {
    display: flex;
    margin-top: 40px;
    @media (max-width: 991.98px) {
      justify-content: center;
    }
    .risk_button {
      padding: 15px 25px;
      margin-right: 12px;
      border-radius: 29px;
      gap: 10px;
      background: #15be53;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0.05em;
      text-align: center;
      color: #0d120b;
      @media (max-width: 1362px) {
        font-size: 14px;
      }
      @media (max-width: 1277px) {
        font-size: 12px;
        line-height: 18px;
        padding: 15px 20px;
      }
      @media (max-width: 991.98px) {
        font-size: 16px;
        line-height: 20px;
        padding: 15px 27px;
      }
      @media (max-width: 600.99px) {
        margin-top: 10px;
      }
      @media (max-width: 480.99px) {
        width: 85%;
        font-size: 14px;
        line-height: 18px;
        margin-left: 20px;
      }
    }
    .discount_button {
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0.05em;
      text-align: center;
      padding: 15px 25px;
      border-radius: 29px;
      gap: 10px;
      color: black;
      background: #ffffff;
      &:not(.disable):hover {
        background: #ffffff;
      }
      @media (max-width: 1362px) {
        font-size: 14px;
      }
      @media (max-width: 1259px) {
        font-size: 12px;
        line-height: 18px;
      }
      @media (max-width: 1188.98px) {
        padding: 15px 20px;
      }
      @media (max-width: 991.98px) {
        font-size: 16px;
        line-height: 20px;
        padding: 15px 27px;
      }

      @media (max-width: 600.99px) {
        padding: 15px;
        margin-top: 10px;
      }
      @media (max-width: 480.99px) {
        width: 100%;
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
  .risk_eveluate_section {
    .risk_points {
      padding: 25px 0px 50px 75px;
      .col-xl-5 {
        @media (max-width: 1404.98px) {
          width: 45% !important;
        }
        @media (max-width: 1199.98px) {
          width: 100% !important;
        }
      }
      @media (max-width: 1199.98px) {
        padding: 25px 50px 50px;
      }
      @media (max-width: 899.98px) {
        text-align: center;
      }
      @media (max-width: 600px) {
        padding: 25px 10px 50px;
      }
      @media (max-width: 350px) {
        padding: 25px 5px 50px;
      }
    }
    .protection_title {
      font-size: 18px;
      font-weight: 600;
      line-height: 23.4px;
      text-align: left;

      @media (max-width: 899.98px) {
        text-align: center !important;
      }
      @media (max-width: 575.98px) {
        font-size: 16px;
        line-height: 21px;
      }
    }
    .protection_desc {
      font-size: 18px;
      font-weight: 400;
      line-height: 25px;
      text-align: left;
      @media (max-width: 1050px) {
        margin-bottom: 45px;
      }
      @media (max-width: 899.98px) {
        text-align: center !important;
      }
    }
    .property_form {
      //   box-shadow: 0px 30px 70px -10px #344b5b4a;
      @media (max-width: 1199.99px) {
        padding: 30px 0px;
      }
      @media (max-width: 480.99px) {
        padding-left: 5px !important;
        padding-right: 5px !important;
      }
    }
  }
  .pp_title {
    font-size: 30px;
    font-weight: 600;
    line-height: 39px;
    text-align: center;
    @media (max-width: 600px) {
      font-size: 28px;
      line-height: 37px;
    }
  }
  .testimonials_section {
    text-align: center;
    padding-top: 80px;
    padding-bottom: 60px;
  }
  .host_last_section {
    padding: 0 150px;

    @media (max-width: 1150.98px) {
      padding: 0 100px;
    }
    // @media (max-width: 1066.98px) {
    //   padding: 0 60px;
    // }
    @media (max-width: 1050.98px) {
      padding: 0 50px;
    }
    @media (max-width: 880.98px) {
      padding: 0 50px;
    }
    @media (max-width: 700.98px) {
      padding: 0px;
    }

    .caption_sections {
      @media (max-width: 1199.98px) {
        .buttons_section {
          justify-content: center;
        }
      }
    }
    .captions {
      font-size: 24px;
      font-weight: 300;
      line-height: 36px;
      text-align: left;
      @media (max-width: 1425px) {
        font-size: 22px !important;
        line-height: 36px !important;
      }
      @media (max-width: 1362px) {
        font-size: 20px !important;
        line-height: 36px !important;
      }
      @media (max-width: 1259px) {
        line-height: 31px !important;
      }
      @media (max-width: 991.98px) {
        font-size: 22px !important;
        line-height: 36px !important;
        text-align: center;
      }
      @media (max-width: 350px) {
        font-size: 20px !important;
        line-height: 28px !important;
      }
    }
    .img_section {
      // right: 60px;
      display: flex;
      justify-content: center;
    }
    .ep__promocode-sec {
      margin: 0 auto;
      background: #171e2b;
      display: flex;
      flex-wrap: wrap;
      background-color: #1a1a1a;
      border-radius: 10px;
      color: #fff;
      width: 100%;
      box-shadow: 0px 30px 70px -10px rgba(52, 75, 91, 0.29);
      height: 496px;
      position: relative;
      align-items: center;
      background-image: url("../../assets/icons/EP_bg_logo.webp");
      background-repeat: no-repeat;
      background-size: contain;
      width: 100%;
      background-position: right;
    }
    .ep__promocode-desc {
      padding: 0 90px;
      box-sizing: border-box;
    }
    .ep__promocode-wrap {
      max-width: 65%;
    }
    .ep__promocode-sec .img_section {
      position: absolute;
      right: 60px;
      // right: 0
      top: -74px;
      height: 570px;
      max-width: 35%;
    }
    .ep__promocode-sec .img_section img {
      max-width: 100%;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    @media (min-width: 2199.98px) {
      .ep__promocode-sec .img_section img {
        object-fit: contain;
        position: absolute;
        right: -115px;
      }
    }
    @media (max-width: 2199.98px) {
      .ep__promocode-sec .img_section img {
        object-fit: contain;
        position: absolute;
        right: -130px;
      }
    }
    @media (max-width: 1950.98px) {
      .ep__promocode-sec .img_section img {
        object-fit: contain;
        position: absolute;
        right: -80px;
      }
    }
    @media (max-width: 1650.98px) {
      .ep__promocode-sec .img_section img {
        object-fit: contain;
        position: absolute;
        right: 0px;
      }
    }
    @media (max-width: 1440.98px) {
      .ep__promocode-sec .img_section {
        right: 65px;
      }
      .ep__promocode-desc {
        padding: 0 40px;
      }
      .ep__promocode-sec .img_section img {
        position: static;
        object-fit: cover;
      }
    }
    @media (max-width: 991.98px) {
      .img_section img {
        // max-width: 70%;
        height: 70%;
        width: 100%;
        object-fit: cover;
      }
      .ep__promocode-wrap {
        max-width: 100%;
        padding: 50px 0 0;
      }
      .ep__promocode-sec .img_section {
        position: static;
        max-width: 100%;
        height: 450px;
        margin: 0 auto;
      }
      .ep__promocode-sec {
        height: 100%;
        background-position: bottom center;
        background-size: 50%;
      }
      .ep__promocode-sec .img_section img {
        object-fit: contain;
      }
    }
    @media (max-width: 840.98px) {
      .ep__promocode-sec {
        background-size: 60%;
      }
    }
    @media (max-width: 767.98px) {
      .ep__promocode-sec {
        background-size: 70%;
      }
    }
    @media (max-width: 550.98px) {
      .ep__promocode-sec {
        background-size: 80%;
      }
    }
    @media (max-width: 450.98px) {
      .ep__promocode-sec {
        background-size: 90%;
      }
      .ep__promocode-sec .img_section {
        height: 400px;
      }
    }
  }
  .call_on {
    font-size: 17px;
    font-weight: 600;
    line-height: 25px;
    text-align: left;
    margin-top: 25px;
    @media (max-width: 991.99px) {
      text-align: center;
    }
    a {
      text-decoration-line: underline;
      text-decoration-style: solid;
      text-decoration-skip-ink: auto;
      color: #ffffff;
    }
  }
}
